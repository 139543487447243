import favoritesStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { FolderTreeLoadStateEnum } from 'owa-folders';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setLoadingStateForFavorites = mutatorAction(
    'setLoadingStateForFavorites',
    (accountKey: string, loadingState: FolderTreeLoadStateEnum) => {
        const favoriteTreeDataLoadingState = favoritesStore.favoriteTreeData.get(accountKey);
        if (favoriteTreeDataLoadingState) {
            favoriteTreeDataLoadingState.loadingState = loadingState;
        }
    }
);
