import type { DraftCopilotPluginViewState } from '../types/DraftCopilotPluginViewState';

export function createDraftCopilotPluginViewState(
    init?: Partial<DraftCopilotPluginViewState>
): DraftCopilotPluginViewState {
    return {
        openWithEditPrompt: init?.openWithEditPrompt,
        initialPrompt: init?.initialPrompt,
        entryPoint: init?.entryPoint,
        suggestedDraftsShown: init?.suggestedDraftsShown,
        suggestedDraftPillInfo: init?.suggestedDraftPillInfo,
        cachedCoachResponse: init?.cachedCoachResponse,
        isShown: init?.isShown || false,
        targetElement: init?.targetElement || undefined,
        horizontalDisplacement: init?.horizontalDisplacement || undefined,
        precedingText: init?.precedingText || undefined,
        followingText: init?.followingText || undefined,
        skittlePosition: init?.skittlePosition || undefined,
        openMode: init?.openMode || 'Unknown',
        skittleTarget: init?.skittleTarget || undefined,
        isSelectionCollapsed: init?.isSelectionCollapsed || false,
        hideGhostText: init?.hideGhostText || false,
        direction: init?.direction || 'ltr',
        isInitialized: false,
    };
}
