import type { IComputedValue } from 'mobx';
import { computed } from 'mobx';
import type { HotkeyCommand } from 'owa-hotkeys';
import { getCommands } from 'owa-mail-hotkeys/lib/utils/MailModuleHotKeys';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { HoverTooltipOverrides } from 'owa-command-ribbon';
import { createHoverTooltipFn } from 'owa-command-ribbon';
import Desc from './hoverTooltipDesc.locstring.json';
import type { ResourceId } from 'owa-localize';

// Maps each control to its corresponding description string
// computed adds mobx reactivity to re-compute Map when observables change
const controlDescMap = computed(() =>
    new Map<RibbonControlId, ResourceId>()
        .set(529, Desc.getAddinsButtonDesc)
        .set(7022, Desc.getM365AppsButtonDesc)
        .set(7016, Desc.getMessageExtensionsButtonDesc)
        .set(564, Desc.sensitivityButtonDesc)
        .set(565, Desc.sensitivityLearnMoreButtonDesc)
        .set(505, Desc.archiveButtonDesc)
        .set(616, Desc.assignPolicyButtonDesc)
        .set(507, Desc.blockButtonDesc)
        .set(658, Desc.blockButtonDesc)
        .set(508, Desc.browseGroupsButtonDesc)
        .set(509, Desc.categorizeButtonDesc)
        .set(644, Desc.conversationsDesc)
        .set(696, Desc.copilotReplyWithMeetingButtonDesc)
        .set(698, Desc.copilotReplyWithMeetingButtonDesc)
        .set(516, Desc.createRuleButtonDesc)
        .set(519, Desc.deleteButtonDesc)
        .set(649, Desc.densityDesc)
        .set(593, Desc.discardButtonDesc)
        .set(525, Desc.encryptButtonDesc)
        .set(687, Desc.expandCollapseConversationDesc)
        .set(604, Desc.flagClearFlagButtonDesc)
        .set(704, Desc.flagCustomButtonDesc)
        .set(601, Desc.flagNextWeekButtonDesc)
        .set(602, Desc.flagNoDateButtonDesc)
        .set(600, Desc.flagThisWeekButtonDesc)
        .set(598, Desc.flagTodayButtonDesc)
        .set(599, Desc.flagTomorrowButtonDesc)
        .set(603, Desc.flagMarkCompleteButtonDesc)
        .set(527, Desc.flagButtonDesc)
        .set(647, Desc.folderPaneDesc)
        .set(531, Desc.ignoreButtonDesc)
        .set(532, Desc.immersiveReaderButtonDesc)
        .set(535, Desc.junkButtonDesc)
        .set(646, Desc.layoutDesc)
        .set(652, Desc.manageQuickStepsDesc)
        .set(537, Desc.manageRulesButtonDesc)
        .set(645, Desc.messagePreviewDesc)
        .set(686, Desc.messageRecallDesc)
        .set(654, Desc.messagesDesc)
        .set(539, Desc.moreRetentionPoliciesButtonDesc)
        .set(540, Desc.moveButtonDesc)
        .set(706, Desc.myDayButtonDesc)
        .set(659, Desc.neverBlockButtonDesc)
        .set(656, Desc.newFolderPostButtonDesc)
        .set(587, Desc.newMessageButtonDesc)
        .set(588, Desc.newMessageButtonDesc)
        .set(641, Desc.changeNoteColorDesc)
        .set(640, Desc.deleteNoteDesc)
        .set(642, Desc.viewEmailFromNotesDesc)
        .set(547, Desc.phishingButtonDesc)
        .set(548, Desc.pinButtonDesc)
        .set(549, Desc.printEmailDesc)
        .set(550, Desc.setupQuickStepsDesc)
        .set(552, Desc.readUnreadButtonDesc)
        .set(648, Desc.readingPaneDesc)
        .set(690, Desc.remindersWindowDesc)
        .set(553, Desc.replyWithMeetingButtonDesc)
        .set(657, Desc.phishingButtonDesc)
        .set(699, Desc.copilotReplyWithMeetingButtonDesc)
        .set(680, Desc.forwardButtonDesc)
        .set(679, Desc.replyButtonDesc)
        .set(681, Desc.replyAllButtonDesc)
        .set(700, Desc.replyWithMeetingButtonDesc)
        .set(555, Desc.forwardButtonDesc)
        .set(651, Desc.forwardEmailAsAttachmentDesc)
        .set(556, Desc.replyButtonDesc)
        .set(557, Desc.replyAllButtonDesc)
        .set(712, Desc.resendDesc)
        .set(683, Desc.restoreDesc)
        .set(684, Desc.restoreAllDesc)
        .set(558, Desc.customizeButtonDesc)
        .set(691, Desc.ribbonModeDesc)
        .set(559, Desc.rulesButtonDesc)
        .set(594, Desc.sendDraftButtonDesc)
        .set(571, Desc.snoozeButtonDesc)
        .set(572, Desc.sweepButtonDesc)
        .set(701, Desc.syncMailboxDesc)
        .set(702, Desc.chatAroundEmail)
        .set(703, Desc.chatAroundEmailAll)
        .set(709, Desc.shareToTeamsChat)
        .set(697, Desc.trackReadReceiptsDesc)
        .set(580, Desc.undoButtonDesc)
        .set(663, Desc.viewGroupsPanelButtonDesc)
        .set(643, Desc.viewSettingsDesc)
        .set(688, Desc.zoomDesc)
        .set(719, Desc.chatwithThirdPartyIM)
        .set(720, Desc.lightRPDesc)
);
// Maps each control to its corresponding hotkey command

/**
 * OWA/Monarch does not localize shortcut keys currently. However, there
 * are four (soon to be five) sets: Outlook.com, Google, Yahoo!, Outlook,
 * and win32. If/when those are supported, we'll have to have a more
 * complicated mapping.
 */

const controlHotkeyMap: IComputedValue<Map<RibbonControlId, HotkeyCommand>> = computed(() => {
    const commands = getCommands();

    // Added here because we don't want this to clash with the other zoom hotkey commands
    // And Ribbon is the only place where all the keys (zoom in, zoom out and reset zoom)
    // have to be displayed together for Zoom control tooltip in View tab
    const zoomHotKeyCommands: HotkeyCommand = {
        category: 'emailActions',
        hotmail: ['ctrl++', 'ctrl+-', 'ctrl+0'],
        gmail: ['ctrl++', 'ctrl+-', 'ctrl+0'],
        owa: ['ctrl++', 'ctrl+-', 'ctrl+0'],
        outlook: ['ctrl++', 'ctrl+-', 'ctrl+0'],
    };

    /**
     * There IS a hotkey for toggling the flag (command.toggleFlag), but it swaps between
     * Flagged and Completed Flag, not Flagged and Unflagged.  We will leave it out of
     * the tooltip, as win32, so as not to cause confusion.
     */
    const controlMap = new Map<RibbonControlId, HotkeyCommand>()
        .set(505, commands.archiveMail)
        .set(509, commands.categorize)
        .set(519, commands.deleteMail)
        .set(593, commands.discardDraft)
        .set(531, commands.ignore)
        .set(535, commands.markAsJunk)
        .set(540, commands.moveToFolder)
        .set(588, commands.newMail)
        .set(552, commands.markAsReadUnread)
        .set(555, commands.forward)
        .set(680, commands.forward)
        .set(556, commands.reply)
        .set(679, commands.reply)
        .set(557, commands.replyAll)
        .set(681, commands.replyAll)
        .set(560, commands.saveDraft)
        .set(594, commands.sendMail)
        .set(624, commands.insertHyperlink)
        .set(571, commands.snooze)
        .set(580, commands.undoAction)
        .set(701, commands.syncMailbox)
        .set(688, zoomHotKeyCommands);

    return controlMap;
});

// Creates a function that returns the hover tooltip for a given combination of controlId and controlName
export const getHoverTooltip: (
    controlId: RibbonControlId | undefined,
    controlName: string,
    overrides?: HoverTooltipOverrides
) => string | undefined = createHoverTooltipFn(controlDescMap, controlHotkeyMap);
