import type { DatapointOptions, CustomData, InternalDatapointOptions } from 'owa-analytics-types';
import { lazyLogUsage } from '../lazyFunctions';
import { addStackTraceToEvent } from 'owa-analytics-utils/lib/addStackTraceToEvent';

const GREY_ERROR_TABLE = 'client_grey_error';

// TODO: __LEGACY: Remove everything except lazyLogUsage function call
export function logUsage(
    eventName: string,
    customData?: CustomData,
    options?: DatapointOptions
): Promise<void> {
    const tableOverride = (options as InternalDatapointOptions)?.isGreyError
        ? GREY_ERROR_TABLE
        : undefined;
    // Add original stack trace
    const datapointOptions = options ?? {};
    addStackTraceToEvent(datapointOptions);

    return lazyLogUsage.importAndExecute(eventName, customData, datapointOptions, tableOverride);
}
