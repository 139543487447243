/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'owa-local-storage' import is restricted from being used. localStorage is not available in the analytics worker [analytics-worker-lint] */
import { getItem, removeItem, setItem } from 'owa-local-storage';

export type AuthTimings = {
    msalis?: number; // msal instance creation start
    msalie?: number; // msal instance creation end
    fgatmsals?: number; // start of first getAuthToken call in session
    fgatmsalsc?: number; // first getAuthToken call in session success
    fgatmsale?: number; // first getAuthToken call in session error
    inlog?: number; // interactive login
    reds?: number; // redirect success
    rede?: number; // redirect error
    silogs?: number; // silent login start
    silogsc?: number; // silent login success
    silogse?: number; // silent login error
    silinlog?: number; // interactive login after silent login
    notoken?: number; // no token was aquired
    bootred?: number; // boot flow redirect
};

const AUTH_TIMINGS = 'authTimings';
let cachedAuthTimings: AuthTimings | undefined;

function readAuthTimings(): AuthTimings {
    const timingsString = getItem(self, AUTH_TIMINGS);

    if (timingsString) {
        try {
            return JSON.parse(timingsString);
        } catch {
            return {};
        }
    }

    return {};
}

export function setAuthTiming(key: keyof AuthTimings) {
    const timings = cachedAuthTimings || readAuthTimings();
    timings[key] = performance.now();
    cachedAuthTimings = timings;
    setItem(self, AUTH_TIMINGS, JSON.stringify(timings));
}

export function getAuthTimings(): AuthTimings {
    const timings = cachedAuthTimings || readAuthTimings();
    removeItem(self, AUTH_TIMINGS);
    return timings;
}
