import { observer } from 'owa-mobx-react';
import { useManagedMutation } from 'owa-apollo-hooks';
import type { MailboxInfo } from 'owa-client-types';
import { lazyCreateNewFolder } from 'owa-folder-createnew';
import { lazyRenameFolder } from 'owa-folder-rename';
import { RenameFolderDocument } from 'owa-folder-rename/lib/graphql/__generated__/RenameFolderMutation.interface';
import { type CreateFolderInvoker, type CreateFolderDepth } from 'owa-folders-constants';
import type { FolderForestTreeType } from 'owa-graph-schema';
import loc, { format } from 'owa-localize';
import { lazyDismissMailFolderTextField } from 'owa-mail-folder-store';
import { initiateMailFolderTextField } from 'owa-mail-folder-store/lib/actions/folderTextFieldActions';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import {
    newFolderAction,
    newFolderAnnouncementText,
    renameFolderAnnouncementText,
} from './FolderOperation.locstring.json';
import FolderTextField from './FolderTextField';
import { shouldRenderNodeInEditMode } from './utils/shouldRenderNodeInEditMode';
import { logUsage } from 'owa-analytics';
import { getFolderTable } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { ResourceId } from 'owa-localize';

export interface FolderOperationNodeProps {
    folderId: string;
    treeType: FolderForestTreeType;
    nestDepth: number;
    operationType: string;
    originalValue?: string;
    mailboxInfo: MailboxInfo;
    ariaAnnouncementLabel?: ResourceId;
    onNewFolderTextFieldEntry?: (value: string) => void;
    onRenameFolderTextFieldEntry?: (value: string) => void;
    isNewFolderOperationDisabled?: boolean;
}

export default observer(function FolderOperationNode(props: FolderOperationNodeProps) {
    const [folderUpdateText, setFolderUpdateText] = React.useState('');
    const [renameFolderMutation] = useManagedMutation(RenameFolderDocument);

    const onNewFolderTextFieldEntry = React.useCallback(
        (value: string) => {
            lazyDismissMailFolderTextField.importAndExecute();

            const parentId = props.folderId;
            lazyCreateNewFolder.importAndExecute(
                {
                    parentId,
                    displayName: value,
                    mailboxInfo: props.mailboxInfo,
                },
                {
                    invoker: 'operationnode',
                    depth: props.nestDepth > 0 ? 'subfolder' : 'parent',
                }
            );
            setFolderUpdateText(format(loc(newFolderAnnouncementText), value));
        },
        [setFolderUpdateText, props.folderId, props.nestDepth]
    );

    const onRenameFolderTextFieldEntry = React.useCallback(
        (value: string, folderId?: string) => {
            lazyDismissMailFolderTextField.importAndExecute();

            const oldFolderName = props.originalValue || '';
            const newFolderName = (value || '').trim(); // remove trailing whitespace

            if (!folderId || !newFolderName || newFolderName === oldFolderName) {
                // Folder name didn't change. So nothing more
                return;
            }

            lazyRenameFolder.importAndExecute(
                renameFolderMutation,
                {
                    id: folderId,
                    newDisplayName: newFolderName,
                    mailboxInfo: props.mailboxInfo,
                },
                oldFolderName
            );

            const announcementText = props.ariaAnnouncementLabel || renameFolderAnnouncementText;
            setFolderUpdateText(format(loc(announcementText), value));
        },
        [setFolderUpdateText]
    );

    // remove when tri-folderPane-folderPaneStylesUpdate is enabled for all users
    const onNewFolderNodeClicked = React.useCallback(
        (evt: React.MouseEvent<unknown> | React.KeyboardEvent) => {
            evt.stopPropagation();
            evt.preventDefault();
            initiateMailFolderTextField(
                props.folderId,
                'new',
                props.treeType,
                props.mailboxInfo.mailboxSmtpAddress
            );
            logUsage('TnS_CreateFolder_ListOperation');
        },
        [props.folderId, props.treeType]
    );

    let nodeToReturn;
    const folderTextInputStyles = {
        fieldGroup: {
            border: 0,
            '&:after': {
                borderRadius: '4px',
            },
        },
    };

    if (
        props.operationType == 'new' ||
        (props.operationType == 'newNode' &&
            shouldRenderNodeInEditMode(
                props.folderId,
                'new',
                props.mailboxInfo.mailboxSmtpAddress,
                props.treeType
            ))
    ) {
        nodeToReturn = (
            <FolderTextField
                nestDepth={props.nestDepth}
                onEntry={props.onNewFolderTextFieldEntry ?? onNewFolderTextFieldEntry}
                styles={folderTextInputStyles}
            />
        );
    } else if (props.operationType == 'rename') {
        nodeToReturn = (
            <FolderTextField
                nestDepth={props.nestDepth}
                onEntry={props.onRenameFolderTextFieldEntry ?? onRenameFolderTextFieldEntry}
                defaultValue={props.originalValue}
                folderId={props.folderId}
                styles={folderTextInputStyles}
            />
        );
    } else if (
        !isFeatureEnabled('fp-remove-links') &&
        props.operationType == 'newNode' &&
        getFolderTable().get(props.folderId)?.EffectiveRights?.CreateHierarchy
    ) {
        nodeToReturn = (
            <TreeNode
                displayName={loc(newFolderAction)}
                isCustomActionNode={true}
                isRootNode={false}
                key={'newFolder'}
                onClick={onNewFolderNodeClicked}
                isDisabled={props.isNewFolderOperationDisabled}
                depth={props.nestDepth}
            />
        );
    }

    if (nodeToReturn) {
        return (
            <>
                {nodeToReturn}
                <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                    {folderUpdateText}
                </span>
            </>
        );
    }

    return null;
}, 'FolderOperationNode');
