import type { RibbonId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import {
    type CalendarFormsRibbonTabId,
    type CalendarFormsRibbonControlId,
} from 'owa-ribbon-ids/lib/calendarFormsRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import {
    type MailRibbonControlId,
    type MailRibbonGroupId,
    type MailRibbonTabId,
} from 'owa-ribbon-ids/lib/mailRibbonId';
import {
    type PeopleHubRibbonControlId,
    type PeopleHubRibbonTabId,
} from 'owa-ribbon-ids/lib/peopleHubRibbonId';
import {
    type PeopleAppRibbonControlId,
    type PeopleAppRibbonTabId,
} from 'owa-ribbon-ids/lib/peopleAppRibbonId';
import { type HelpRibbonTabId, type HelpRibbonControlId } from 'owa-ribbon-ids/lib/helpControlId';
import { type NewsletterRibbonTabId, type NewsletterRibbonControlId } from 'owa-ribbon-ids';
import { errorThatWillCauseAlert } from 'owa-trace';
import {
    type CalendarRibbonTabId,
    type CalendarRibbonControlId,
} from 'owa-ribbon-ids/lib/calendarRibbonId';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';

/**
 * This maps a RibbonId to its specific keytip string content, notably *not* its entire keytip sequence.
 * Note that this does *not* return IKeytipProps, which is the type that eventually Ribbon consumes.
 * To construct a IKeytipProps, please @see {constructKeytip} from @see {keytipUtils.ts}.
 */
const ribbonIdKeytipStringMap: Map<RibbonId, string> = new Map<RibbonId, string>([
    /***************  action controls ***************/
    [639, 'E'],
    [7012, 'PM'],
    [506, 'AS'],
    [620, 'AF'],
    [621, 'B'],
    [622, 'C'],
    [7017, 'OD'],
    [623, 'U'],
    [529, 'AN'],
    [7022, 'M365'],
    [7016, 'ME'],
    [638, 'PC'],
    [637, 'T'],
    [564, 'AY'],
    [565, 'L'],
    [7006, 'L1'],
    [7005, 'H'],
    [7004, 'AC'],
    [639, 'EX'],
    [7001, 'SU'],
    [7002, 'ST'],
    [7011, 'ML'],
    [7000, 'LP'],
    [560, 'SD'],
    [7035, 'SDA'],
    [633, 'S'],
    [7009, 'F'],
    [7019, 'C'],
    [7020, 'B'],
    [7023, 'CD'],
    [7027, 'CE'],
    [7028, 'CC'],
    [7033, 'HR'],
    [7003, 'DB'],
    [7010, 'MO'],
    [624, 'LK'],
    [7024, 'UL'],
    [7037, 'L'],
    [7025, 'LU'],
    [7013, 'SL'],
    [7014, 'Q'],
    [7015, 'U'],
    [7021, 'LS'],
    [7026, 'RV'],
    [7029, 'DI'],
    [7030, 'II'],
    [7031, 'DR'],
    [7032, 'BO'],
    [7034, 'R'],
    [7036, 'P'],
    [7038, 'A'],
    /*************** format controls ***************/
    [6018, 'AC'],
    [6017, 'AL'],
    [6019, 'AR'],
    [6110, 'AJ'],
    [6071, 'AT'],
    [6001, '1'],
    [6005, 'U'],
    [6035, 'C'],
    [6024, '7'],
    [6010, 'C'],
    [6009, 'X'],
    [6026, 'I'],
    [6025, 'FC'],
    [6027, 'FF'],
    [6028, 'FS'],
    [6029, 'FP'],
    [6007, 'AI'],
    [6002, '2'],
    [6020, 'LT'],
    [6034, 'L'],
    [6031, 'M'],
    [6032, 'N'],
    [6006, 'N'],
    [6008, 'AO'],
    [6011, 'V'],
    [6012, 'V'],
    [6016, 'QT'],
    [6015, 'R'],
    [6030, 'EF'],
    [6021, 'RL'],
    [6036, 'S'],
    [6004, 'G'],
    [6022, 'SB'],
    [6023, 'SP'],
    [6003, '3'],
    [6014, 'U'],
    [6013, 'AZ'],
    [6033, 'U'],
    [6087, 'TA'],
    [6088, 'TL'],
    [6089, 'TC'],
    [6090, 'TR'],
    [6091, 'CL'],
    [6092, 'CC'],
    [6093, 'CR'],
    [6094, 'BL'],
    [6095, 'BC'],
    [6096, 'BR'],
    [6055, 'PH'],
    [6056, 'PV'],
    [6057, 'I'],
    [6058, 'A'],
    [6059, 'BE'],
    [6060, 'L'],
    [6061, 'R'],
    [6049, 'M'],
    [6054, 'P'],
    [6037, 'TS'],
    [6097, 'BC'],
    [6098, 'BW'],
    [6099, 'BS'],
    [6100, 'TB'],
    [6101, 'T'],
    [6102, 'B'],
    [6103, 'L'],
    [6104, 'R'],
    [6105, 'O'],
    [6106, 'I'],
    [6107, 'N'],
    [6108, 'A'],
    [6042, 'D'],
    [6045, 'T'],
    [6043, 'C'],
    [6044, 'R'],
    [6038, 'A'],
    [6039, 'R'],
    [6040, 'M'],
    [6046, 'S'],
    [6047, 'H'],
    [6041, 'U'],
    [6050, 'MA'],
    [6051, 'MB'],
    [6052, 'ML'],
    [6053, 'MR'],
    [6072, 'AF'],
    [6073, 'LY'],
    [6075, 'K'],
    [6076, 'A'],
    [6077, 'B'],
    [6079, 'B'],
    [6085, 'E'],
    [6081, 'S'],
    [6083, 'V'],
    [6082, 'AY'],
    [6080, 'K'],
    [6084, 'CP'],
    [6111, 'M'],
    [6109, 'T'],
    [6112, 'K'],
    [6086, 'U'],
    [6113, 'X'],
    /*************** mail ribbon controls ***************/
    [605, 'AF'],
    [606, 'AO'],
    [505, 'O'],
    [616, 'AP'],
    [617, 'AU'],
    [618, 'U'],
    [507, 'B'],
    [658, 'BL'],
    [508, 'B'],
    [509, 'G'],
    [613, 'A'],
    [614, 'C'],
    [615, 'M'],
    [612, 'N'],
    [626, 'S'],
    [510, 'AC'],
    [644, 'C'],
    [514, 'C'],
    [516, 'CR'],
    [518, 'T'],
    [519, 'D'],
    [649, 'D'],
    [593, 'D'],
    [525, 'PM'],
    [647, 'F'],
    [714, 'O'],
    [715, 'A'],
    [716, 'S'],
    [604, 'E'],
    [601, 'N'],
    [602, 'A'],
    [600, 'W'],
    [598, 'T'],
    [599, 'O'],
    [603, 'M'],
    [527, 'U'],
    [704, 'C'],
    [528, 'FP'],
    /*************** mail ribbon groups ****************/
    [108, 'ZN'],
    [111, 'ZC'],
    [113, 'ZA'],
    [145, 'ZE'],
    [128, 'ZT'],
    [172, 'PF'],
    [124, 'SD'],
    [176, 'K'],
    [167, 'TG'],
    [123, 'S'],
    [162, 'O'],
    [174, 'F'],
    [175, 'SF'],
    [179, 'L'],
    [199, 'P'],
    /*************** draw groups ***************/
    [181, 'DU'],
    [182, 'DS'],
    [185, 'DI'],
    [183, 'DT'],
    [184, 'DTH'],
    /***************  mail ribbon controls ***************/
    [531, 'X'],
    [532, 'L'],
    [535, 'J'],
    [646, 'A'],
    [537, 'M'],
    [645, 'MP'],
    [654, 'M'],
    [539, 'M'],
    [540, 'MV'],
    [611, 'NF'],
    [608, 'D'],
    [609, 'F'],
    [610, 'O'],
    [659, 'S'],
    [584, 'E'],
    [656, 'P'],
    [585, 'U'],
    [587, 'M'],
    [588, 'N'],
    [660, 'W'],
    [661, 'X'],
    [662, 'P'],
    [589, 'N'],
    [592, 'Y'],
    [665, 'V'],
    [682, 'NA'],
    [640, 'D'],
    [641, 'C'],
    [642, 'V'],
    [546, 'V'],
    [547, 'P'],
    [548, 'Y'],
    [549, 'P'],
    [550, 'Q'],
    [648, 'RP'],
    [552, 'W'],
    [553, 'E'],
    [696, 'CR'],
    [698, 'CRF'],
    [699, 'RFCM'],
    [700, 'RFRM'],
    [657, 'RE'],
    [678, 'RR'],
    [555, 'FW'],
    [680, 'FW'],
    [651, 'FA'],
    [556, 'RP'],
    [679, 'RP'],
    [557, 'RA'],
    [681, 'RA'],
    [683, 'RS'],
    [684, 'RX'],
    [558, 'CZ'],
    [559, 'RL'],
    [594, 'SE'],
    [569, 'F'],
    [570, 'O'],
    [571, 'SZ'],
    [632, 'C'],
    [627, 'T'],
    [631, 'N'],
    [628, 'E'],
    [629, 'O'],
    [630, 'W'],
    [572, 'SP'],
    [573, 'T'],
    [580, 'Z'],
    [581, 'US'],
    [663, 'VG'],
    [643, 'V'],
    [666, 'C'],
    [667, 'M'],
    [668, 'F'],
    [669, 'S'],
    [670, 'C'],
    [671, 'R'],
    [672, 'B'],
    [673, 'H'],
    [674, 'S'],
    [675, 'H'],
    [710, 'O'],
    [711, 'C'],
    [695, 'F'],
    [694, 'G'],
    [717, 'P'],
    [718, 'U'],
    [676, 'S'],
    [677, 'H'],
    [690, 'RW'],
    [687, 'E'],
    [688, 'Z'],
    [720, 'RPL'],
    [686, 'MR'],
    [691, 'RB'],
    [692, 'C'],
    [693, 'S'],
    [697, 'TR'],
    [701, 'S'],
    [702, 'CAE'],
    [703, 'CAA'],
    [709, 'STT'],
    [705, 'CT'],
    [706, 'MD'],
    [707, 'S'],
    [708, 'H'],
    [712, 'RS'],
    [719, 'TPC'],
    /*************** draw controls ***************/
    [12001, 'U'],
    [12002, 'R'],
    [12003, 'AS'],
    [12004, 'L'],
    [12005, 'E'],
    [12006, 'P1'],
    [12007, 'P2'],
    [12008, 'P3'],
    [12009, 'H1'],
    [12010, 'W'],
    [12011, 'D'],
    [12012, 'PT'],
    /*************** mail ribbon tabs ***************/
    [4, 'O'],
    [11, 'P'],
    [1, 'H'],
    [5, 'N'],
    [10, 'M'],
    [3, 'V'],
    [6, 'N'],
    [7, 'JT'],
    [8, ''],
    [12, 'DT'],
    [13, 'JP'],
    /*************** calendar ribbon tabs ***************/
    [4000, 'V'],
    [4001, 'X'],
    /***************  calendar forms ribbon controls ***************/
    [4540, 'C'],
    [4509, 'F'],
    [4513, 'B'],
    [4564, 'BR'],
    [4502, 'CA'],
    [4520, 'G'],
    [4523, 'M'],
    [4521, 'N'],
    [4563, 'CAC'],
    [4542, 'N1'],
    [4501, 'D'],
    [4545, 'E'],
    [4546, 'F'],
    [4547, 'S'],
    [4512, 'U'],
    [4566, 'L'],
    [4554, 'N2'],
    [4558, 'FO'],
    [4535, 'FW'],
    [4536, 'E'],
    [4537, 'S'],
    [4510, 'H'],
    [4506, 'TJ'],
    [4555, 'OM'],
    [4519, 'P'],
    [4549, 'RE'],
    [4539, 'R'],
    [4524, 'RA'],
    [4525, 'RM'],
    [4508, 'T'],
    [4507, 'RO'],
    [4557, 'X'],
    [4543, 'E'],
    [4544, 'S'],
    [4518, 'A'],
    [4517, 'B'],
    [4514, 'F'],
    [4516, 'T'],
    [4515, 'W'],
    [4541, 'J'],
    [4511, 'TR'],
    [4565, 'WF'],
    /*************** calendar surface tabs **************/
    [2000, 'H'],
    [2001, 'V'],
    [2003, 'N'],
    /*************** calendar surface controls **************/
    [2532, 'N'],
    [2510, 'M'],
    [2501, 'E'],
    [2604, 'MN'],
    [2605, 'JI'],
    [2602, 'A'],
    [2526, 'L'],
    [2531, 'B'],
    [2606, 'VA'],
    [2603, 'C'],
    [2595, 'NW'],
    [2596, 'NT'],
    [2597, 'NV'],
    [2598, 'NM'],
    [2599, 'NC'],
    [2600, 'NL'],
    [2504, 'D'],
    [2520, 'K'],
    [2519, 'W'],
    [2505, 'T'],
    [2523, 'F'],
    [2527, 'M'],
    [2528, 'G'],
    [2529, 'B'],
    [2553, 'R'],
    [2556, 'P'],
    [2516, 'SC'],
    [2513, 'P'],
    [2517, 'SV'],
    [2515, 'SA'],
    [2525, 'SC'],
    [2565, 'C'],
    [2502, 'C'],
    [2522, 'S'],
    [2533, '1'],
    [2534, '2'],
    [2535, '3'],
    [2536, '4'],
    [2537, '5'],
    [2538, '6'],
    [2539, '7'],
    [2514, 'S'],
    [2540, '0'],
    [2541, '3'],
    [2542, '1'],
    [2543, 'M'],
    [2544, '6'],
    [2545, '5'],
    [2546, 'AL'],
    [2547, 'AP'],
    [2548, 'M'],
    [2549, 'O'],
    [2550, 'A'],
    [2552, 'C'],
    [2551, 'D'],
    [2560, 'F'],
    [2561, 'B'],
    [2562, 'T'],
    [2586, 'MF'],
    [2563, 'A'],
    [2564, 'W'],
    [2554, 'S'],
    [2555, 'R'],
    [2558, 'P'],
    [2557, 'N'],
    [2559, 'DE'],
    [2588, 'IP'],
    [2589, 'IPR'],
    [2590, 'IPN'],
    [2521, 'A'],
    [2566, 'RW'],
    [2568, 'CF'],
    [2569, 'UAM'],
    [2570, 'UAC'],
    [2573, 'UAS'],
    [2574, 'UAR'],
    [2575, 'SAM'],
    [2576, 'SAC'],
    [2579, 'SAS'],
    [2580, 'SAR'],
    [2582, 'AA'],
    [2583, 'NR'],
    [2601, 'FD'],
    [2609, 'DP'],
    /*************** people hub tabs **************/
    [8000, 'H'],
    [8001, 'V'],
    /*************** people hub controls ***************/
    [8503, 'C'],
    [8502, 'U'],
    [8501, 'N'],
    [8504, 'L'],
    [8520, 'CL'],
    [8518, 'D'],
    [8529, 'MV'],
    [8506, 'PS'],
    [8505, 'E'],
    [8507, 'RS'],
    [8519, 'AC'],
    [8517, 'RF'],
    [8523, 'MC'],
    [8526, 'AL'],
    [8531, 'RN'],
    [8509, 'AM'],
    [8516, 'AF'],
    [8515, 'EG'],
    [8514, 'FG'],
    [8511, 'IO'],
    [8513, 'JG'],
    [8512, 'LG'],
    [8521, 'IC'],
    [8522, 'EC'],
    /*************** help tab **************/
    [11000, 'E'],
    /*************** help controls ***************/
    [11201, 'H'],
    [11210, 'P'],
    [11202, 'U'],
    [11203, 'F'],
    [11204, 'R'],
    [11205, 'D'],
    [11208, 'G'],
    [11206, 'Q'],
    [11207, 'C'],
    [11209, 'T'],

    /*************** newsletter tabs **************/
    [14001, 'H'],
    [14002, 'HE'],
    [14003, 'I'],
    [14004, 'N'],
    [14005, 'O'],
    [14006, 'F'],
    [14007, 'T'],
    [14008, 'P'],
    [14009, 'D'],
    [14010, 'PR'],
    [14011, 'PU'],
    [14012, 'GC'],
    [14013, 'GVO'],
    [14014, 'GVR'],
    [14015, 'NO'],
    [14016, 'RO'],

    /*************** newsletter controls ***************/
    [14503, 'CG'],
    [14502, 'CN'],
    [14507, 'S'],
    [14508, 'D'],
    [14504, 'I'],
    [14509, 'IA'],

    /************** people app tabs ****************/
    [15000, 'H'],
    [15001, 'V'],
    /************** people app controls ************/
    [15200, 'C'],
    [15201, 'L'],
    [15222, 'N'],
    [15202, 'E'],
    [15203, 'D'],
    [15204, 'R'],
    [15205, 'M'],
    [15206, 'K'],
    [15207, 'J'],
    [15208, 'F'],
    [15209, 'U'],
    [15210, 'Z'],
    [15211, 'S'],
    [15212, 'CN'],
    [15215, 'I'],
    [15216, 'X'],
    [15217, 'P'],
    [15218, 'Y'],
    [15219, 'DF'],
    [15221, 'DC'],
    [15220, 'DS'],
]);

export const getKeytipString = (ribbonId: RibbonId): string => {
    // If we can't find a RibbonId but we're calling `getKeytipString` on it, chances are it was
    // an oversight and we need to add it to the map below. Throw error in this case.
    if (!ribbonIdKeytipStringMap.has(ribbonId)) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert('RibbonId: ' + ribbonId + ' not found in ribbonIdKeytipStringMap');
        return '';
    }

    return ribbonIdKeytipStringMap.get(ribbonId) ?? '';
};
