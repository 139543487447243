import { logUsage } from 'owa-analytics';
import { assertNever } from 'owa-assert';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { isOcpsStoreReady } from 'owa-ocps-get-policies';
import { isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';
import {
    getBrowserInfo,
    getOs,
    isMinimumBrowserVersion,
    type Browser,
    type OperatingSystem,
} from 'owa-user-agent';
import getAttachmentPolicy from 'owa-session-store/lib/utils/getAttachmentPolicy';
import type { FluidOwaSource } from '../enums/FluidEnums';
import { isCollabSpaceEnabledForSource } from './isCollabSpaceEnabledForSource';
import type { MailboxInfo } from 'owa-client-types';
import { getBposNavBarData } from 'owa-bpos-store';
import { isOCPSPolicyEnabledForLoopType } from './isOCPSPolicyEnabledForLoopType';
import { LoopType } from './constants';

let hasBeenChecked: boolean = false;
let isFluidEnabled: boolean | undefined = undefined;
let fluidDisabledReason: string | undefined = undefined;

export function isFluidEnabledForSource(
    source: FluidOwaSource,
    mailboxInfo: MailboxInfo,
    loopType: LoopType,
    skipCheckingUserOptions: boolean = false
): boolean {
    const hasAttachmentProvider = getAttachmentPolicy(mailboxInfo)?.AttachmentDataProviderAvailable;
    const hasBPOSNavBarData = getHasBPOSNavBarData(hasAttachmentProvider);
    const hasOneDriveForBusiness = hasAttachmentProvider || hasBPOSNavBarData;

    const checkOneDriveForBusiness =
        loopType === LoopType.Fluid || loopType === LoopType.VideoRecord;

    let disabledReasonInternal = undefined;
    // once Fluid is supported in consumer, we want to continue restricting feature access to Cloud Cache users
    // therefore replace isConsumer() with getUserConfiguration().SessionSettings?.IsShadowMailbox
    // For preload we check if fluid is enabled before options are loaded.
    if (isConsumer(undefined /* smtpAddress */, mailboxInfo)) {
        disabledReasonInternal = 'Consumer';
    } else if (!hasOneDriveForBusiness && checkOneDriveForBusiness) {
        disabledReasonInternal = 'NoOneDriveForBusiness';
    } else if (!isBrowserSupported()) {
        disabledReasonInternal = 'BrowserNotSupported';
    } else if (
        !(isOCPSPolicyEnabledForLoopType(loopType, mailboxInfo) || skipCheckingUserOptions)
    ) {
        if (isOcpsStoreReady()) {
            disabledReasonInternal = 'OcpsPolicyDisabled';
        } else {
            disabledReasonInternal = 'OcpsPolicyNotReady';
        }
    }

    if (!!disabledReasonInternal) {
        if (!getAttachmentPolicy(mailboxInfo)) {
            logUsage('FluidEnabledAttachmentPolicyUndefined', { source });
        }
        logUsage('FluidNotEnabled', {
            source,
            isAttachmentPolicyEnabled: hasAttachmentProvider,
            isBrowserSupported: isBrowserSupported(),
            browser: getBrowserInfo().browser,
            isOcpsPolicyEnabled:
                isOcpsPolicyEnabled(OcpsPolicy.LoopEnabledForOutlook, true, mailboxInfo) &&
                isOcpsPolicyEnabled(OcpsPolicy.Loop, true, mailboxInfo),
            isOcpsReady: isOcpsStoreReady(),
            isConsumer: isConsumer(undefined /* smtpAddress */, mailboxInfo),
            hasBPOSNavBarData,
            loopType,
            disabledReasonInternal,
            isPreload: skipCheckingUserOptions,
        });
        logFluidEnabledStatus(false /* isEnabled */, disabledReasonInternal, source, loopType);
        return false;
    }

    switch (source) {
        case 'MailReadingPane':
        case 'NewsletterPreview':
        case 'MailCompose':
        case 'CalendarEventBody':
        case 'ForwardEventEditor':
        case 'None':
            if (isFeatureEnabled('cmp-prague')) {
                logFluidEnabledStatus(
                    true /* isEnabled */,
                    undefined /* disabled reason */,
                    source,
                    loopType
                );
                return true;
            }
            logUsage('FluidNotEnabledFromFlightCheck', { source });
            logFluidEnabledStatus(
                false /* isEnabled */,
                'flightCheck' /* disabled reason */,
                source,
                loopType
            );
            return false;
        case 'CalendarReadingPane':
        case 'MailCalendarCard':
        case 'CalendarCompose':
            const isCollabSpaceEnabled = isCollabSpaceEnabledForSource(source, mailboxInfo);
            const disabledReason = isCollabSpaceEnabled ? undefined : 'flightCheck';
            logFluidEnabledStatus(
                isCollabSpaceEnabled /* isEnabled */,
                disabledReason,
                source,
                loopType
            );
            return isCollabSpaceEnabled;
        case 'MOS':
            const isEnabled = isFeatureEnabled('mos-hoopApp');
            const disabledReasonMOS = isEnabled ? undefined : 'flightCheck';
            logFluidEnabledStatus(isEnabled, disabledReasonMOS, source, loopType);
            return isEnabled;
        case 'NewsletterCompose':
            return isFeatureEnabled('addison-loops') ? true : false;
        default:
            return assertNever(source);
    }
}

function isBrowserSupported(): boolean {
    const browser: Browser = getBrowserInfo().browser;
    switch (browser) {
        case 'Chrome':
        case 'Edge Anaheim':
            return isMinimumBrowserVersion([80]);
        case 'Firefox':
            return isMinimumBrowserVersion([78]);
        case 'Opera':
            return true;
        case 'Safari':
            return getOs() !== 'Windows' && isMinimumBrowserVersion([13, 4]);
        default:
            return false;
    }
}

function getHasBPOSNavBarData(hasAttachmentProvider: boolean | undefined): boolean {
    const bposNavBarData = getBposNavBarData();
    const hasNavBarData = !!bposNavBarData?.SPO_RootSiteUrl;
    if (hasNavBarData !== hasAttachmentProvider) {
        logUsage('FluidEnabledCheckNavBarDataMismatch', { hasNavBarData, hasAttachmentProvider });
    }
    return hasNavBarData;
}

function logFluidEnabledStatus(
    isEnabled: boolean,
    reason: string | undefined,
    source: FluidOwaSource,
    loopType: LoopType
) {
    if (hasBeenChecked && (isFluidEnabled !== isEnabled || reason !== fluidDisabledReason)) {
        logUsage('FluidEnabledStatus', {
            isNowEnabled: isEnabled,
            wasPreviouslyEnabled: isFluidEnabled,
            newDisabledReason: reason,
            previousDisabledReason: fluidDisabledReason,
            source,
            loopType,
        });
    }
    hasBeenChecked = true;
    isFluidEnabled = isEnabled;
    fluidDisabledReason = reason;
}
