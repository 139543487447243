import type { MailboxInfo } from 'owa-client-types';
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';

export function addNetCoreAuthHeader(
    headers: Headers,
    getApplicationSettingsCallback?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup],
    mailboxInfo?: MailboxInfo
) {
    const setting = getApplicationSettingsCallback?.(
        'NetCore',
        mailboxInfo,
        true /* dontErrorIfNotInitialized */
    );
    if (setting?.authToken) {
        headers.set('Authorization', `Bearer ${setting.authToken}`);
    }
}
