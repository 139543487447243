import type { PerformanceDatapoint } from 'owa-analytics';
import {
    type DatapointVariant,
    returnTopExecutingActionDatapoint,
    logUsage,
    DatapointStatus,
} from 'owa-analytics';
import { lazyLoadDumpster } from '../lazyFunctions';
import onAfterSelectingNode from './helpers/onAfterSelectingNode';
import selectNodeInFolderForest from './selectNodeInFolderForest';
import type { GroupFolderForestNode } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import type { FolderForestTreeType } from 'owa-graph-schema';
import folderStore, { isFolderUnderMsg } from 'owa-folders';
import getFolderData from 'owa-mail-actions/lib/getFolderData';
import { onSelectFolderComplete } from 'owa-mail-shared-actions/lib/onSelectFolderComplete';
import { resetFocus } from 'owa-mail-focus-manager';
import {
    folderForestStore,
    getSelectedNode,
    getFolderIdForSelectedNode,
} from 'owa-mail-folder-forest-store';
import { addFrequentlyUsedFolder } from 'owa-mail-frequently-used-folders';
import { initTableSelectionOnLoad } from 'owa-mail-actions/lib/initTableSelectionOnLoad';
import {
    getSelectedTableView,
    getViewFilterForTable,
    getFocusedFilterForTable,
    type TableQueryType,
    getListViewTypeString,
} from 'owa-mail-list-store';
import { getListViewTypeForFolder } from 'owa-mail-folder-store';
import type ActionSource from 'owa-mail-store/lib/store/schema/ActionSource';
import { setTriageActionAnnouncement } from 'owa-mail-actions';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { addCoreDatapointConfig } from 'owa-analytics-actions';
import {
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
    PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
    ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID,
    type GroupFolderWellKnownName,
} from 'owa-folders-constants';
import { action, orchestrator, mutator } from 'satcheljs';
import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowReadingPane } from 'owa-mail-layout/lib/selectors/shouldShowReadingPane';
import { isImmersiveReadingPaneShown } from 'owa-mail-layout/lib/selectors/isImmersiveReadingPaneShown';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import loc, { format } from 'owa-localize';
import {
    resetFolderToInbox,
    windowTitleModuleUserFormatString,
    mailModuleTitle,
} from './selectFolder.locstring.json';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import deleteFolderStoreUpdate from 'owa-mail-actions/lib/triage/deleteFolderStoreUpdate';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { lazySelectFilter } from 'owa-mail-filter-actions';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { hasUserCreatedFolders } from 'owa-groups-shared-folders-store';
import selectGroup from './selectGroup';
import selectGroupFolder from './selectGroupFolder';
import { wasFolderPrefetched } from 'owa-mail-frequently-used-folders/lib/utils/wasFolderPrefetched';
import type { TraceErrorObject } from 'owa-trace';
import { errorThatWillCauseAlert } from 'owa-trace';
import { delayMobxReactionsForCurrentTask } from 'owa-configure-mobx';
import { mruTableCacheHasFolderId } from 'owa-mail-triage-local-updates/lib/actions/tableCache/mruTableIdList';
import shouldFirstRowBeSelectedOnLoad from 'owa-mail-list-store/lib/selectors/shouldFirstRowBeSelectedOnLoad';
import { alwaysInCacheTableViewCacheHasFolderId } from 'owa-mail-triage-local-updates/lib/actions/tableCache/alwaysInCacheTableIdList';
import isAttachmentsSearchFolder from 'owa-mail-store/lib/utils/isAttachmentsSearchFolder';
import { lazyHandleOnAwayFromAttachmentsSearchFolder } from 'owa-storage-store';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

let lastSwitchMailFolderDp: PerformanceDatapoint | null | undefined;

/**
 * General select a folder
 * @param folderId the folderId
 * @param treeType that the folder belongs to, i.e MailFolder or Favorites
 * @param actionSource action that initiated the switch folder action
 * @return a promise that resolves when the select folder has completed
 */
function selectFolderInternal(
    folderId: string,
    treeType: FolderForestTreeType,
    actionSource: ActionSource,
    isSearchFolder: boolean,
    folderNodeType?: FolderForestNodeType,
    sessionData?: SessionData
): Promise<void> {
    let distinguishedFolderId: string = '';

    // This is for the router code path, e.g we show /inbox instead of the real folder name in the url
    // if it's a distinguished folder
    if (folderId) {
        // The folderId is the name of a DistinguishedFolder. Grab the correct id.
        distinguishedFolderId = folderNameToId(folderId as DistinguishedFolderIdName);
    }

    const folderIdToSelect = distinguishedFolderId || folderId;
    if (!folderIdToSelect) {
        const error: TraceErrorObject = new Error('CantSelectFolderWithEmptyFolderId');
        error.additionalInfo = {
            treeType,
            ty: typeof folderId,
            actionSource,
            folderNodeType,
            fid: !!folderId,
            dty: typeof distinguishedFolderId,
            fity: typeof folderIdToSelect,
        };
        errorThatWillCauseAlert(error);
    }

    return selectNodeInFolderForest(
        {
            id: folderIdToSelect,
            type: folderNodeType != null ? folderNodeType : 0,
            treeType,
        },
        actionSource,
        sessionData,
        isSearchFolder
    );
}

/**
 * Select a folder
 * @param folderId the folderId
 * @param treeType that the folder belongs to, i.e MailFolder or Favorites
 * @param actionSource action that initiated the switch folder action
 * @param state selected folder state
 */
const selectFolder = action(
    'selectFolder',
    (
        folderId: string,
        treeType: FolderForestTreeType,
        actionSource: ActionSource,
        folderNodeType?: FolderForestNodeType,
        sessionData?: SessionData,
        eventTimestamp?: number
    ) => {
        const previousFolderId = getSelectedNode().id;
        const isNewFolderSameAsPreviousFolder = folderId == previousFolderId;
        const isInImmersiveView = isImmersiveReadingPaneShown();
        return addCoreDatapointConfig(
            {
                name: 'SwitchMailFolder',
                options: {
                    variant: 3, // Log all the instances after the first instance in every session
                    logVerbose: true, // to be able to detect the issues in waterfall logging
                    eventTimestamp,
                },
                customData: [
                    folderIdToName(folderId),
                    previousFolderId ? folderIdToName(previousFolderId) : '', // previousFolderId can be null when there is no folder selected before switching folder (for example, exiting search)
                    treeType,
                    actionSource,
                    getListViewTypeString(getListViewTypeForFolder(folderId)),
                    wasFolderPrefetched(folderId),
                    isNewFolderSameAsPreviousFolder,
                    isInImmersiveView,
                ],
                unifiedTelemetry: {
                    eventName: 'SwitchMailFolder',
                    data: {
                        FolderName: folderIdToName(previousFolderId),
                    },
                },
            },
            {
                folderId,
                treeType,
                previousFolderId,
                actionSource,
                folderNodeType,
                isNewFolderSameAsPreviousFolder,
                sessionData,
            }
        );
    }
);
export default selectFolder;

orchestrator(deleteFolderStoreUpdate, actionMessage => {
    const { folderIds, groupId } = actionMessage;
    const folderIdForSelectedNode = getFolderIdForSelectedNode();
    const selectedNode = getSelectedNode();

    for (let i = 0; i < folderIds.length; i++) {
        const folderId = folderIds[i];

        if (selectedNode.type === 6) {
            const selectedGroupId = (selectedNode as GroupFolderForestNode).groupId;
            const mailboxInfo = (selectedNode as GroupFolderForestNode).mailboxInfo;
            const userCreatedFolders = hasUserCreatedFolders(selectedGroupId, mailboxInfo);

            if (folderId === folderIdForSelectedNode && userCreatedFolders) {
                // Select Group Inbox if the group folder being deleted is the selected folder and there are user created folders left.
                selectGroupFolder(selectedGroupId, 'inbox', selectedNode.treeType, mailboxInfo);
                break;
            } else if (selectedGroupId === groupId && !userCreatedFolders) {
                // Select Group Node if the folder being deleted is in the selected group and there are no user created folders left.
                selectGroup(selectedGroupId, selectedNode.treeType, mailboxInfo);
                break;
            }
        } else if (folderId === folderIdForSelectedNode) {
            // Select Primary Mailbox Inbox if folder being deleted is the selected folder
            selectFolder(
                folderNameToId('inbox'),
                'primaryFolderTree' /* treeType */,
                'ResetInbox' /* ActionSource */
            );
            break;
        }
    }
});

orchestrator(selectFolder, async actionMessage => {
    const {
        folderId,
        treeType,
        previousFolderId,
        actionSource,
        folderNodeType,
        isNewFolderSameAsPreviousFolder,
        sessionData,
    } = actionMessage;

    // If there is an ongoing switch mail folder datapoint, log the abandonment and end current as background success
    if (lastSwitchMailFolderDp && !lastSwitchMailFolderDp?.hasEnded) {
        // end SwitchMailFolder datapoint from the previous selection
        lastSwitchMailFolderDp.end(undefined /*duration*/, DatapointStatus.BackgroundSuccess);

        const E2ETimeElapsed = lastSwitchMailFolderDp?.getCustomData('E2ETimeElapsed');
        // Log abandonment is elapsed time was greater than half a second
        if (typeof E2ETimeElapsed === 'number' && E2ETimeElapsed > 500) {
            logUsage(
                'SwitchMailFolderAbandoned',
                [
                    E2ETimeElapsed,
                    lastSwitchMailFolderDp?.getPropertyBag()?.owa_1, // Folder name
                    lastSwitchMailFolderDp?.getPropertyBag()?.owa_3, // Tree type
                    lastSwitchMailFolderDp?.getPropertyBag()?.owa_4, // Action source
                    lastSwitchMailFolderDp?.getPropertyBag()?.isCached, // isCached
                ],
                { sessionSampleRate: 10 }
            );
        }
    }

    // Cache the current switch mail folder datapoint
    lastSwitchMailFolderDp = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
        return dp.getEventName() == 'SwitchMailFolder';
    });

    const isInImmersiveView = isImmersiveReadingPaneShown();

    let folder = folderStore.folderTable.get(folderId);
    if (folderNodeType === 5) {
        folder = publicFolderFavoriteStore.folderTable.get(folderId);
        lastSwitchMailFolderDp?.addCustomProperty('isPublicFolder', true);
    }

    let isSearchFolder = false;
    if (
        !isNewFolderSameAsPreviousFolder &&
        folder &&
        typeof folder.SearchFolderTemplateId == 'number'
    ) {
        isSearchFolder = true;
        logUsage('TnS_SearchFolder_OpenFolder', {
            SearchFolderType: folder.SearchFolderTemplateId,
        });
    }

    lastSwitchMailFolderDp?.addCustomProperty('isSearchFolder', isSearchFolder);
    if (folder?.FolderClass === 'IPF.Note.OutlookHomepage') {
        lastSwitchMailFolderDp?.addCustomProperty('rss folder', true);
    }

    if (folder && folderIdToName(folderId) == 'outbox') {
        logUsage('MailComposeOfflineAction', {
            step: 'OutboxFolderSelected',
            outboxMsgCount: folder?.totalMessageCount,
            isUserOnline: window.navigator.onLine,
        });
    }

    // Delaying mobx reactions is faster when we don't already have the folder contents in memory,
    // but is slower when we do. Therefor, don't delay reactions if we know we're in the same folder
    // or if there's a table view in that cache with this folder id
    if (
        !isNewFolderSameAsPreviousFolder &&
        !mruTableCacheHasFolderId(folderId) &&
        !alwaysInCacheTableViewCacheHasFolderId(folderId)
    ) {
        delayMobxReactionsForCurrentTask('selectFolder');
    }

    let selectFolderPromise: Promise<void> = Promise.resolve();
    const tableView = getSelectedTableView();
    const isExitingSearch = tableView && tableView.tableQuery.type == 1;

    // Reset focus based on priority list when we switch
    // to a new table or select the same folder
    setTimeout(() => {
        resetFocusWrapper();

        // Queue the work to guarantee that focus has been reset before updating
        // selection in the table so that the focus is not lost.
        setTimeout(() => {
            // Reuse the same table view and just update the the properties
            // If it is the same folder and we have already loaded the first page
            if (
                isNewFolderSameAsPreviousFolder &&
                tableView &&
                tableView.loadedStartIndex === 0 &&
                !(isReadingPanePositionOff() && shouldShowReadingPane())
            ) {
                // Initialize table selection
                initTableSelectionOnLoad(tableView);
            }
        }, 0);
    }, 0);

    const viewFilter = getViewFilterForTable(tableView);
    const focusedFilter = getFocusedFilterForTable(tableView);

    /**
     * If the immersive RP is shown and we're navigating to the same folder, then
     * we can assume the RP is being dismissed and we should keep the same view filter
     * applied.
     */
    const shouldPreserveViewFilter =
        isNewFolderSameAsPreviousFolder && isInImmersiveView && viewFilter && viewFilter !== 'All';

    /**
     * We need to reload the folder if the folder being selected is different
     * than the currently selected folder OR if a filter has been applied to the
     * folder OR we haven't loaded the first page of the table view yet.
     */
    if (
        !isNewFolderSameAsPreviousFolder ||
        tableView.loadedStartIndex !== 0 ||
        (viewFilter && viewFilter !== 'All') ||
        focusedFilter === 1
    ) {
        selectFolderPromise = selectFolderInternal(
            folderId,
            treeType,
            actionSource,
            isSearchFolder,
            folderNodeType,
            sessionData
        );

        // Just fetch the folder data and other information if the folder being selected is different
        // than the currently selected folder OR if a filter has been applied to the folder
        if (
            !isNewFolderSameAsPreviousFolder ||
            (viewFilter && viewFilter !== 'All') ||
            focusedFilter === 1
        ) {
            const folderName = folderIdToName(folderId);

            // refresh single folder count and get folder permissions
            getFolderData(folderId);

            // Do not add folder to frequently used if it is inbox or dumspter or it is not in primary mailbox
            if (
                folder &&
                isFolderUnderMsg(folder) &&
                folderName !== 'inbox' &&
                folderName !== PRIMARY_DUMPSTER_DISTINGUISHED_ID
            ) {
                addFrequentlyUsedFolder(folderId);
            }

            if (folder && folderName === PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID) {
                lazyLoadDumpster.importAndExecute(
                    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
                    folder.mailboxInfo
                );
            } else if (folder && folderName === ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID) {
                lazyLoadDumpster.importAndExecute(
                    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
                    folder.mailboxInfo
                );
            }
        }
    }

    // Called after selecting same/different folder
    // #15945 - We should try to merge SelectFolder, SelectPersona, SelectGroup logic and move this method into a common place
    onAfterSelectingNode(actionSource);

    // Announce that selection is being changed to the inbox if auto-selecting
    if (actionSource === 'ResetInbox') {
        setTriageActionAnnouncement(loc(resetFolderToInbox), 'assertive');
    }

    // Raise onSelectFolder complete action
    selectFolderPromise.then(() => {
        lastSwitchMailFolderDp?.addToCustomWaterfall(4, 'SelectFolderPromiseCompleted');
        onSelectFolderComplete(
            folderId,
            previousFolderId,
            isExitingSearch,
            tableView?.id,
            MailListItemSelectionSource.Auto,
            isInImmersiveView,
            actionSource,
            !!shouldFirstRowBeSelectedOnLoad(tableView)
        );

        if (shouldPreserveViewFilter) {
            lazySelectFilter.importAndExecute(viewFilter as ViewFilter, 'Route');
        }
    });

    // Update the window title to use the current selected account
    updateWindowTitle(folderId, previousFolderId);

    if (!isNewFolderSameAsPreviousFolder && isAttachmentsSearchFolder(previousFolderId)) {
        const handleOnAwayFromAttachmentsSearchFolder =
            await lazyHandleOnAwayFromAttachmentsSearchFolder.import();
        handleOnAwayFromAttachmentsSearchFolder();
    }

    return selectFolderPromise;
});

mutator(selectFolder, actionMessage => {
    if (actionMessage.isNewFolderSameAsPreviousFolder) {
        // Do not perform the rest of selecting folder logic when
        // user navigates to the node with the same id
        folderForestStore.selectedNode.treeType = actionMessage.treeType;
    }
});

function resetFocusWrapper() {
    resetFocus('selectFolder');
}

function updateWindowTitle(folderId: string, previousFolderId: string) {
    const currentMailboxInfo = folderStore.folderTable.get(folderId)?.mailboxInfo;
    const previousMailboxInfo = folderStore.folderTable.get(previousFolderId)?.mailboxInfo;
    if (!isSameCoprincipalAccountMailboxInfos(previousMailboxInfo, currentMailboxInfo)) {
        const sessionSettings = getAccountScopeUserSettings(currentMailboxInfo).SessionSettings;
        const userDisplayName = sessionSettings?.UserDisplayName || '';
        const windowTitle = format(
            loc(windowTitleModuleUserFormatString),
            loc(mailModuleTitle),
            userDisplayName
        );
        document.title = windowTitle;
    }
}
