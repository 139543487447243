import { type LabelPreferenceId } from 'owa-mail-ribbon-store-shared-types/lib/util/labelPreferencesIds';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type RibbonCustomizerStore from './schema/ribbonCustomizerStore';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import { getAnonymousMailboxInfo } from 'owa-mailbox-info/lib/getAnonymousMailboxInfo';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';

const defaultMailboxInfo: MailboxInfo = isMailboxPackageFlightEnabled()
    ? getAnonymousMailboxInfo()
    : /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
       * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
       *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
      {
          type: 'UserMailbox',
          userIdentity: '',
          mailboxSmtpAddress: '',
          diagnosticData: 'RibbonCustomizerStore',
      };

/**
 * Store for the Ribbon Customizer. This essentially is a temporary holder for all the changes
 * that a user is making for a single tab. When a user clicks save, that's when we transfer the changes
 * over to owa-mail-ribbon-store to reflect the new ribbon.
 */
const defaultRibbonCustomizerStore: RibbonCustomizerStore = {
    layout: [],
    controlsInOverflow: [],
    staticGroupIdOrdering: [],
    showLabelsPreference: 1,
    dragControl: null,
    editingGroupName: null,
    dragGroup: null,
    isCustomizing: false,
    preDragLayout: null,
    selectedTab: 1,
    mailboxInfo: defaultMailboxInfo,
};

const store = createStore<RibbonCustomizerStore>(
    'RibbonCustomizer',
    defaultRibbonCustomizerStore
)();
export default store;
export const getStore = () => store;
