import CategoryWell from 'owa-categories/lib/components/CategoryWell';
import type { MailListItemSingleLineViewProps } from './MailListItemComponentProps';
import onCategoryClicked from '../utils/onCategoryClicked';
import renderReactions from '../utils/renderReactions';
import isValidEmailAddress from '../utils/isValidEmailAddress';
import classnames from 'owa-classnames';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getListViewColumnWidths } from 'owa-mail-layout';
import {
    onMailListItemClickHandler,
    useMailListItemContainerContext,
    lazyUpdateRsvpInCalendarCard,
} from 'owa-mail-list-item-shared';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { type SortColumn } from 'owa-mail-list-store/lib/store/schema/SortBy';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import {
    full,
    medium,
    compact,
    secondColumn,
    itemView as mailListitemStyles_itemView,
    conversationView as mailListitemStyles_conversationView,
    senderImageOn as mailListitemStyles_senderImageOn,
    senderImageOff as mailListitemStyles_senderImageOff,
    categoryWellSingleLine,
    categoryContainerSingleLine,
    previewDisplaySingleLine,
    reactionsContainer,
    slvBigHoverThirdColumn,
    slvBigHoverThirdColumnTextWrapper,
    thirdColumnIconBarWithHeaders,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import {
    firstRowBase,
    singleLineViewContainer,
    content,
    subjectUnread,
    subject,
} from 'owa-mail-listitem-styles/lib/scss/ListViewItemCommonStyles.scss';
import {
    firstColumnWithHeaders,
    firstColumnNoTwistyWithHeaders,
    itemView,
    conversationView,
    senderImageOn,
    standardizedFirstColumn,
    standardizedSecondColumn,
} from './MailListItemSingleLineView.scss';
import { SenderGroupingsActionButton } from 'owa-sender-groupings-button';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import React from 'react';
import type { CSSProperties } from 'react';
import {
    MailListItemExternalMarker,
    MailListItemUnverifiedMarker,
    MailListItemFirstLineText,
    MailListItemIconBar,
    MailListItemLineText,
    MailListItemPreviewDisplay,
    MailListItemTwistyButton,
    MailListItemUnreadCount,
    PersonaCheckbox,
    MailListItemMeetingInfo,
    FolderTag,
    MailListItemRichPreviewWell,
    MailListItemTimestamp,
    MailListItemUnreadBar,
} from 'owa-mail-list-item-components';
import { SuggestedUnsubscribe } from 'owa-suggested-unsubscribe-peek';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { getConditionalFormattingStylesForText } from 'owa-conditional-formatting';
import { senderEmailNotFoundLabel } from './MailListItem.locstring.json';
import { CopilotInboxPriorityIcon } from 'owa-mail-copilot-inbox-shared';
import type { MeetingMessageActionType } from 'owa-meeting-message';
import type { AttendanceType } from 'owa-graph-schema';

export default observer(function MailListItemSingleLineView(
    props: MailListItemSingleLineViewProps
): JSX.Element {
    const {
        canArchive,
        canDelete,
        canPin,
        categories,
        conversationId,
        copilotInboxScore,
        effectiveMentioned,
        firstLineText,
        firstLineTooltipText,
        hasAttachment,
        hasSharepointLink,
        highlightTerms,
        importance,
        isComplete,
        isFirstLevelExpanded,
        isFlagged,
        isInArchiveFolder,
        isInNotesFolder,
        isInOutboxFolder,
        isInJunkFolder,
        isLoadingSecondLevelExpansion,
        isPinned,
        isSearchTable,
        isSecondLevelExpanded,
        isSelected,
        isSnoozed,
        isTaggedForBigScreen,
        isUnauthenticatedSender,
        itemClassIcon,
        lastDeliveryTimestamp,
        lastSender,
        latestItemId,
        listViewType,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        returnTime,
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        shouldShowExternalLabel,
        shouldShowTwisty,
        shouldShowSenderGroupingsButton,
        shouldSuggestUnsubscribe,
        showAttachmentPreview,
        showCondensedView,
        showDraftsIndicator,
        showFolderTag,
        showPreviewText,
        smimeType,
        supportsFlagging,
        supportsHoverIcons,
        supportsMove,
        supportsPinning,
        tableViewId,
        thirdLineText,
        unreadCount,
        shouldShowRSVP,
        isScheduledSend,
    } = props;

    const tableView: TableView = getListViewStore().tableViews.get(tableViewId) as TableView;

    const mailTableQuery = tableView?.tableQuery as MailFolderTableQuery;
    const isCategorySort = mailTableQuery?.sortBy?.sortColumn === 14;
    const isPrioritySort = mailTableQuery?.sortBy?.sortColumn === 16;

    // derived properties based on props/flights
    const densityModeString = getDensityModeCssClass(full, medium, compact);
    const isUnread = unreadCount > 0;
    const shouldShowCategories = categories?.length > 0 && !isCategorySort;
    const showAttachmentPreviews = !showCondensedView && showAttachmentPreview;
    const showPreviews =
        !shouldShowUnstackedReadingPane() ||
        (!isFirstLevelExpanded && !isSecondLevelExpanded && !isLoadingSecondLevelExpansion);

    const showMultiSelectButton = !isSearchTable;
    const senderImageEnabled = isSenderImageEnabled();
    const showCheckbox =
        !showMultiSelectButton ||
        (showMultiSelectButton &&
            (senderImageEnabled ||
                tableView?.isInSelectAllState ||
                tableView?.isInCheckedMode ||
                isFeatureEnabled('tri-reselect-checkbox-behavior')));
    const mailboxInfo = getMailboxInfo(tableView);
    const isItemView = tableView?.tableQuery.listViewType === 1;
    const { conditionalFormattingModifications } = useMailListItemContainerContext();

    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled('tri-mlRelocateHoverActions');
    const firstColumnStyles =
        isItemView || shouldShowTwisty ? firstColumnWithHeaders : firstColumnNoTwistyWithHeaders;

    const subjectClasses = classnames(
        isUnread ? subjectUnread : subject,
        useMessageListTextStyle('Major')
    );

    const { senderColumnWidth, subjectColumnWidth, receivedColumnWidth } =
        getListViewColumnWidths(tableView);

    const firstColumnWidth = React.useMemo(() => {
        return {
            width: senderColumnWidth,
            maxWidth: senderColumnWidth,
        };
    }, [senderColumnWidth]);

    const secondColumnWidth = React.useMemo(() => {
        return {
            width: subjectColumnWidth,
            maxWidth: subjectColumnWidth,
        };
    }, [subjectColumnWidth]);

    const thirdColumnWidth = React.useMemo<CSSProperties>(() => {
        const thirdColumnMarginLeft = isRelocateHoverActionsFlightEnabled ? '8px' : undefined;
        return {
            width: receivedColumnWidth,
            maxWidth: receivedColumnWidth,
            boxSizing: 'content-box' as const,
            marginLeft: thirdColumnMarginLeft,
        };
    }, [receivedColumnWidth]);

    let senderFirstLineText = firstLineText;

    // In the Junk Email folder, we'll show the sender's email address if the
    // sender's name is not a valid email address so users can determine the validity
    // of the sender's email address without opening potentially dangerous emails.
    if (isInJunkFolder && !isValidEmailAddress(firstLineText)) {
        const lastSenderMailboxEmailAddress = lastSender?.Mailbox?.EmailAddress;

        // If the sender's email address is valid, we'll show the sender's email address.
        // Otherwise, we'll show the sender's name and a label indicating that the sender's
        // email address is not found.
        if (lastSenderMailboxEmailAddress && isValidEmailAddress(lastSenderMailboxEmailAddress)) {
            senderFirstLineText = `${firstLineText} <${lastSenderMailboxEmailAddress}>`;
        } else {
            senderFirstLineText = `${firstLineText} <${loc(senderEmailNotFoundLabel)}>`;
        }
    }

    const textStyle = React.useMemo(() => {
        return conditionalFormattingModifications && conditionalFormattingModifications.length > 0
            ? getConditionalFormattingStylesForText(conditionalFormattingModifications)
            : undefined;
    }, [conditionalFormattingModifications]);

    const onPreviewClick = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            onMailListItemClickHandler(
                evt,
                MailListItemSelectionSource.MailListItemRichPreview,
                rowKey,
                tableViewId
            );
        },
        [rowKey, tableViewId]
    );

    const folderTagComponent = (
        <FolderTag
            folderId={parentFolderId}
            parentFolderDisplayName={parentFolderDisplayName}
            isPreviewTextOn={showPreviewText}
            isSingleLine={true}
            mailboxGuids={mailboxGuids}
            shouldBeInTabOrder={isSelected}
        />
    );

    const timestampComponent = returnTime ? (
        <MailListItemTimestamp
            isPinned={isPinned}
            isSingleLine={true}
            isSnoozed={true}
            returnTime={returnTime ?? ''}
            supportsPinning={supportsPinning}
        />
    ) : (
        <MailListItemTimestamp
            isPinned={isPinned}
            isSingleLine={true}
            isUnread={isUnread}
            lastDeliveryTimestamp={lastDeliveryTimestamp}
            returnTime={returnTime ?? ''}
            supportsPinning={supportsPinning}
        />
    );

    const renderIconBarComponent = (
        neverShowHoverIcons: boolean,
        neverShowPropertyIcons: boolean
    ) => {
        return (
            <MailListItemIconBar
                canArchive={canArchive}
                canDelete={canDelete}
                canPin={canPin}
                effectiveMentioned={effectiveMentioned}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                importance={importance}
                isComplete={isComplete}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isPinned={isPinned}
                isRowExpanded={isFirstLevelExpanded || isSecondLevelExpanded}
                isSingleLine={true}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                neverShowHoverIcons={neverShowHoverIcons || !supportsHoverIcons}
                supportsHoverIcons={supportsHoverIcons}
                neverShowPropertyIcons={neverShowPropertyIcons}
                rowKey={rowKey}
                smimeType={smimeType}
                supportsFlagging={supportsFlagging}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                unreadCount={unreadCount}
            />
        );
    };

    const rightSideIconBar = renderIconBarComponent(
        false /* neverShowHoverIcons */,
        true /* neverShowPropertyIcons */
    );

    const unreadBar = React.useMemo(() => {
        return (
            <MailListItemUnreadBar
                isExpanded={isFirstLevelExpanded || isSecondLevelExpanded}
                isUnread={isUnread}
                rowKey={rowKey}
                tableViewId={tableViewId}
            />
        );
    }, [isFirstLevelExpanded, isSecondLevelExpanded, isUnread, rowKey, tableViewId]);

    const onRsvpAction = React.useCallback(
        (interactionType: MeetingMessageActionType, attendance: AttendanceType) => {
            lazyUpdateRsvpInCalendarCard.importAndExecute(interactionType, attendance);
        },
        []
    );

    return (
        <>
            {unreadBar}
            <div className={singleLineViewContainer}>
                <div className={classnames(densityModeString, firstRowBase)}>
                    <div
                        className={classnames(
                            firstColumnStyles,
                            isItemView ? itemView : conversationView,
                            senderImageEnabled && senderImageOn,
                            densityModeString,
                            isRelocateHoverActionsFlightEnabled && standardizedFirstColumn
                        )}
                        style={firstColumnWidth}
                    >
                        {shouldShowTwisty && (
                            <MailListItemTwistyButton
                                isFirstLevelExpanded={isFirstLevelExpanded}
                                isSecondLevelExpanded={isSecondLevelExpanded}
                                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                                isSelected={isSelected}
                                isSingleLine={true}
                                isUnread={unreadCount > 0}
                                rowKey={rowKey}
                                showCondensedView={showCondensedView}
                                tableViewId={tableViewId}
                            />
                        )}
                        {showCheckbox && (
                            <PersonaCheckbox
                                tableViewId={tableViewId}
                                isSelected={isSelected}
                                lastSender={lastSender}
                                rowKey={rowKey}
                                lastDeliveryTimestamp={lastDeliveryTimestamp}
                                isUnauthenticatedSender={isUnauthenticatedSender}
                            />
                        )}
                        {lastSender && shouldShowSenderGroupingsButton && (
                            <SenderGroupingsActionButton
                                lastSender={lastSender}
                                mailboxInfo={mailboxInfo}
                            />
                        )}
                        {shouldShowExternalLabel && (
                            <MailListItemExternalMarker isSelected={isSelected} />
                        )}
                        {!shouldShowExternalLabel && isUnauthenticatedSender && (
                            <MailListItemUnverifiedMarker />
                        )}
                        <MailListItemFirstLineText
                            highlightTerms={highlightTerms}
                            highlightAccuracy={tableView?.highlightAccuracy}
                            showDraftsIndicator={showDraftsIndicator}
                            isInOutboxFolder={isInOutboxFolder}
                            unreadCount={unreadCount}
                            text={senderFirstLineText}
                            tooltipText={firstLineTooltipText ?? ''}
                            isSingleLineView={true}
                            isScheduledSend={isScheduledSend}
                            textColor={
                                isFeatureEnabled('tri-ml-mcltextstyles') ? textStyle : undefined
                            }
                        />
                        {isRelocateHoverActionsFlightEnabled ? (
                            <div className={thirdColumnIconBarWithHeaders}>
                                {renderIconBarComponent(
                                    false /* neverShowHoverIcons */,
                                    false /* neverShowPropertyIcons */
                                )}
                            </div>
                        ) : (
                            renderIconBarComponent(
                                true /* neverShowHoverIcons */,
                                false /* neverShowPropertyIcons */
                            )
                        )}
                    </div>
                    <div
                        className={classnames(
                            secondColumn,
                            isItemView
                                ? mailListitemStyles_itemView
                                : mailListitemStyles_conversationView,
                            senderImageEnabled
                                ? mailListitemStyles_senderImageOn
                                : mailListitemStyles_senderImageOff,
                            densityModeString,
                            isRelocateHoverActionsFlightEnabled && standardizedSecondColumn
                        )}
                        style={secondColumnWidth}
                    >
                        <CopilotInboxPriorityIcon
                            copilotInboxScore={copilotInboxScore}
                            scenario={isPrioritySort ? 'PrioritySort' : 'ML'}
                            mailboxInfo={mailboxInfo}
                            isUnread={isUnread}
                        />
                        {shouldShowCategories && (
                            <CategoryWell
                                key={rowId}
                                categoryWellContainerClass={categoryWellSingleLine}
                                categoryContainerClass={categoryContainerSingleLine}
                                categories={categories}
                                onCategoryClicked={onCategoryClicked}
                                mailboxInfo={mailboxInfo}
                            />
                        )}
                        <div className={content}>
                            <div className={subjectClasses} style={textStyle}>
                                <MailListItemLineText
                                    addOverflowStyles={true}
                                    highlightTerms={highlightTerms}
                                    text={secondLineText}
                                    tooltipText={secondLineTooltipText ?? ''}
                                />
                                <MailListItemUnreadCount unreadCount={unreadCount} />
                            </div>
                            {showPreviewText && (
                                <div className={classnames(previewDisplaySingleLine)}>
                                    {showPreviews && (
                                        <MailListItemPreviewDisplay
                                            highlightTerms={highlightTerms}
                                            previewDisplay={thirdLineText}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                        {isFeatureEnabled('tri-message-list-reactions') &&
                            !(isFirstLevelExpanded || isSecondLevelExpanded) &&
                            renderReactions({
                                latestItemId,
                                reactionsContainerClassName: reactionsContainer,
                                rowId,
                                tableViewId,
                            })}
                        {shouldShowRSVP && (
                            <MailListItemMeetingInfo
                                conversationId={conversationId}
                                isSelected={isSelected}
                                isSingleLineView={true}
                                itemClassIcon={itemClassIcon}
                                latestItemId={latestItemId}
                                listViewType={listViewType}
                                tableViewId={tableViewId}
                                onRsvpAction={onRsvpAction}
                            />
                        )}
                        {shouldSuggestUnsubscribe && (
                            <SuggestedUnsubscribe
                                isSingleLineView={true}
                                isSelected={isSelected}
                                rowId={rowId}
                                tableView={tableView}
                            />
                        )}
                        {showFolderTag && folderTagComponent}
                    </div>
                    {/* SLV THIRD COLUMN STARTS HERE */}
                    {isRelocateHoverActionsFlightEnabled && (
                        <div style={thirdColumnWidth}>{timestampComponent}</div>
                    )}
                    {!isRelocateHoverActionsFlightEnabled && (
                        <div className={slvBigHoverThirdColumn} style={thirdColumnWidth}>
                            <div className={slvBigHoverThirdColumnTextWrapper}>
                                {timestampComponent}
                            </div>
                            {rightSideIconBar ? (
                                <div className={thirdColumnIconBarWithHeaders}>
                                    {rightSideIconBar}
                                </div>
                            ) : null}
                        </div>
                    )}
                    {/* SLV THIRD COLUMN ENDS HERE */}
                </div>
                {showPreviews && showAttachmentPreviews && (
                    <MailListItemRichPreviewWell
                        isWideView={true}
                        onPreviewClick={onPreviewClick}
                        rowId={rowId}
                        tableViewId={tableViewId}
                    />
                )}
            </div>
        </>
    );
},
'MailListItemSingleLineView');
