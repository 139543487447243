import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import type { SearchScope } from '../../data/schema/SearchScope';
import {
    primaryMailboxSearchScope,
    archiveMailboxSearchScope,
    sharedFoldersSearchScope,
    SearchScopeKind,
    singleGroup3sSearchScope,
    pstFileSearchScope,
} from '../../data/schema/SearchScope';
import {
    PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
    ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
    ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { getMailRootFolderChildIds } from 'owa-folders/lib/util/folderUtility';
import type { MailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import {
    lazyHasUserCreatedFolders,
    lazyGetGroupFolderChildFolderIds,
} from 'owa-groups-shared-folders-store/lib/lazyFunctions';

export function getPrimaryMailboxSearchScopeList(mailboxInfo?: MailboxInfo): SearchScope[] {
    const searchScopes: SearchScope[] = [];

    // Add some distinguished folders and a root folder
    searchScopes.push(
        primaryMailboxSearchScope({
            folderId: folderNameToId(PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID, mailboxInfo),
            kind: SearchScopeKind.PrimaryMailbox,
        })
    );

    const folderIds = mailboxInfo
        ? getMailRootFolderChildIds(mailboxInfo)
        : getMailRootFolderChildIds(getGlobalSettingsAccountMailboxInfo());

    for (const folderId of folderIds) {
        searchScopes.push(
            primaryMailboxSearchScope({
                folderId,
                kind: SearchScopeKind.PrimaryMailbox,
            })
        );
    }

    return searchScopes;
}

export function getArchiveMailboxSearchScopeList(mailboxInfo?: MailboxInfo): SearchScope[] {
    // Add some distinguished folders and archive root folder
    return [
        archiveMailboxSearchScope({
            folderId: folderNameToId(ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID, mailboxInfo),
            kind: SearchScopeKind.ArchiveMailbox,
        }),
        archiveMailboxSearchScope({
            folderId: folderNameToId(ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID, mailboxInfo),
            kind: SearchScopeKind.ArchiveMailbox,
        }),
    ];
}

export function getSharedFoldersScopeList(mailboxInfo?: MailboxInfo): SearchScope[] {
    const searchScopes: SearchScope[] = [];

    if (mailboxInfo) {
        // Add All folders scope
        searchScopes.push(
            sharedFoldersSearchScope({
                folderId: folderNameToId(PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID, mailboxInfo),
                kind: SearchScopeKind.SharedFolders,
            })
        );

        const folderIds = getMailRootFolderChildIds(mailboxInfo);

        for (const folderId of folderIds) {
            searchScopes.push(
                sharedFoldersSearchScope({
                    folderId,
                    kind: SearchScopeKind.SharedFolders,
                })
            );
        }
    }

    return searchScopes;
}

export function getGroupSearchScopeList(mailboxInfo?: MailboxInfo): SearchScope[] {
    const searchScopes: SearchScope[] = [];

    if (mailboxInfo?.mailboxSmtpAddress) {
        const hasUserCreatedFolders = lazyHasUserCreatedFolders.tryImportForRender();
        const getGroupFolderChildFolderIds = lazyGetGroupFolderChildFolderIds.tryImportForRender();
        if (hasUserCreatedFolders?.(mailboxInfo.mailboxSmtpAddress, mailboxInfo)) {
            const folderIds = getGroupFolderChildFolderIds?.(
                mailboxInfo.mailboxSmtpAddress,
                mailboxInfo
            );
            folderIds?.map(folderId => {
                searchScopes.push(
                    singleGroup3sSearchScope({
                        folderId,
                        kind: SearchScopeKind.Group,
                        groupId: mailboxInfo.mailboxSmtpAddress,
                    })
                );
            });
        }
    }

    return searchScopes;
}

export function getPstFileSearchScopeList(mailboxInfo?: MailboxInfo): SearchScope[] {
    const searchScopes: SearchScope[] = [];

    if (mailboxInfo) {
        // Add All folders scope
        searchScopes.push(
            pstFileSearchScope({
                folderId: folderNameToId(PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID, mailboxInfo),
                kind: SearchScopeKind.PstFile,
            })
        );

        const folderIds = getMailRootFolderChildIds(mailboxInfo);

        for (const folderId of folderIds) {
            searchScopes.push(
                pstFileSearchScope({
                    folderId,
                    kind: SearchScopeKind.PstFile,
                })
            );
        }
    }

    return searchScopes;
}

export function getCalendarSearchScopeList(): SearchScope[] {
    return [];
}

export function getPublicFolderSearchScopeList(): SearchScope[] {
    return [];
}

export function getMetaOSAppSearchScopeList(_mailboxInfo?: MailboxInfo): SearchScope[] {
    return [];
}
