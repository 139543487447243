import { getUserMailboxInfo } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';

export default function getPublicFolderMailboxInfoForSmtpAddress(
    publicFolderSmtpAddress: string
): MailboxInfo {
    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    const mailboxInfo: MailboxInfo = {
        type: 'PublicMailbox',
        /* eslint-disable-next-line owa-custom-rules/no-default-methods  -- (https://aka.ms/OWALintWiki)
         * Methods that use the default mailbox should not be used, see https://aka.ms/multiaccountlinter
         *	> Do Not Use Default: The getUserMailboxInfo function should not be used for Respond multi-account support, user identity is not enough to identify account information. */
        userIdentity: getUserMailboxInfo().userIdentity,
        mailboxSmtpAddress: publicFolderSmtpAddress,
    };

    return mailboxInfo;
}
