import { ApolloLink, Observable } from '@apollo/client';
import type { FetchResult, Operation } from '@apollo/client';
import type { OperationTypeNode, SelectionNode } from 'graphql';
import { enabledForIdb, enabledForRemote, enabledForWeb } from 'owa-application-settings';

import type { Resolvers, ResolverContext } from 'owa-graph-schema';
import { getOperationAST, GraphQLError } from 'graphql';
import { isFeatureEnabled } from 'owa-feature-flags';
import objMerge from 'lodash-es/merge';
import { trace } from 'owa-trace';
import { createOperation } from '@apollo/client/link/utils';
import { type DatasourceExtension } from 'owa-offline-errors';
import { type QueuedActionContext } from 'owa-queued-actions';
import { isIdbFallbackResult } from 'owa-graph-idb-fallback-result';

type TopLevelResolver =
    | keyof Resolvers['Query']
    | keyof Resolvers['Mutation']
    | keyof Resolvers['Subscription'];
type OperationType = 'Query' | 'Mutation' | 'Subscription';

/**
 * The local remote router link takes a graphql operation document and examines the data resolvers that are defined on the client.
 * (data resolvers resolve the operation against OWS/HX/etc)
 *
 * If there is a resolver for the operation, the operation is forwarded to that local execution link.  If not, the operation is
 * dispatched to the remote graphql gateway endpoint.
 *
 * If a single operation document has multiple selections, some local and some remote, document is split into a local-only document
 * and a remote-only document, evaluated, and then the results combined into a single result set.
 */
export const localRemoteRouterLink = ({
    localLink,
    remoteLink,
    getResolversProfile,
}: {
    localLink: ApolloLink;
    remoteLink: ApolloLink;
    getResolversProfile: () => Promise<{
        resolvers: Resolvers;
        dataSource?: string;
        isOfflineEnabled: boolean;
    }>;
}) => {
    const link = new ApolloLink(operation => {
        const context = operation.getContext() as ResolverContext & {
            headers: Record<string, any>;
        };
        const opNode = getOperationAST(operation.query);

        if (!opNode) {
            // don't have a valid single operation.  just forward it to the local (default) link and let it deal with it
            return localLink.request(operation);
        } else if (context.gatewayGraphRequest) {
            // call site is forcing remote graph execution.  avoid importing local resolver packages.
            return remoteLink.request(operation);
        } else {
            return new Observable(observer => {
                getResolversProfile().then(({ resolvers, isOfflineEnabled }) => {
                    const operationType: OperationType = capitalizeOp(opNode.operation);
                    const selections = opNode.selectionSet.selections;
                    const localResolverRoot = resolvers[operationType] || {};

                    // Force skipping the remote gateway path for some scenarios that we do not support
                    // 1. We bypass all the requests of archivemailbox, sharedmailbox, publicfolder, teamsmailbox, groupmailbox, etc to Gateway. We allow the regular delegation, shared mailbox, explicit logon to the gateway.
                    // 2. When fwk-useoutlookgateway-sendArchiveRequest is on, we allow the archive mailbox's request to the gateway.
                    let skipRemoteCallForcefully = false;
                    const mailboxInfo = operation?.variables?.mailboxInfo;
                    if (mailboxInfo) {
                        skipRemoteCallForcefully =
                            mailboxInfo.mailboxSmtpAddress != mailboxInfo.userIdentity;

                        if (
                            isFeatureEnabled('fwk-useoutlookgateway-sendArchiveRequest') &&
                            mailboxInfo.type === 'ArchiveMailbox'
                        ) {
                            skipRemoteCallForcefully = false;
                        }

                        if (
                            mailboxInfo.type != 'GroupMailbox' &&
                            mailboxInfo.type != 'PublicMailbox' &&
                            mailboxInfo.type != 'TeamsMailbox' &&
                            mailboxInfo.type != 'ArchiveMailbox'
                        ) {
                            skipRemoteCallForcefully = false;
                        }
                    }

                    const { remoteSelections, localSelections, remoteEnabledSelections } =
                        processSelectionNodes(
                            selections,
                            context,
                            localResolverRoot,
                            skipRemoteCallForcefully,
                            isOfflineEnabled,
                            operationType,
                            operation
                        );

                    // execute the remote ops
                    let remoteOperation = operation;
                    let remoteObserver = Observable.of<FetchResult>();
                    if (remoteSelections.length > 0) {
                        remoteOperation = buildOperation(operation, remoteSelections);
                        remoteObserver = remoteLink.request(remoteOperation) || remoteObserver;
                    }

                    // ...and the local ops (in parallel)
                    let localOperation = operation;
                    let localObserver = Observable.of<FetchResult>();
                    if (localSelections.length > 0) {
                        localOperation = buildOperation(operation, localSelections);
                        localObserver = localLink.request(localOperation) || localObserver;
                    }

                    // ...and combine the results into a single result set
                    // ...mapping hx fallbacks to remote operations
                    let combinedObserver = localObserver
                        .concat(remoteObserver)
                        .flatMap(result =>
                            mapIdbFallbackToRemote(
                                result,
                                operation,
                                remoteLink,
                                remoteEnabledSelections
                            )
                        );

                    const activeSelections = remoteSelections.length + localSelections.length;
                    if (operationType === 'Subscription') {
                        // ...if it's a gql subscription, the consumer will pull results as they come in.  So, just return the combinedObserver.
                        // don't reassign combined observer
                    } else if (activeSelections > 0) {
                        // merge the local the remote results, preferring any remote result that replaced a local fallback
                        combinedObserver = combinedObserver.reduce(objMerge);
                    } else {
                        // there were no active selections in the operation
                        const error = new GraphQLError(
                            'there were no active resolvers in the operation'
                        );
                        combinedObserver = Observable.of<FetchResult>({
                            errors: [error],
                        });
                    }

                    // the local operation is a new operation forked off the original.  it is initialized with the original's context,
                    // but if we want the original context to reflect any changes made by the forked operation, we need to stamp those on
                    // the original context

                    const sub = combinedObserver.subscribe({
                        next: result => {
                            operation.setContext(localOperation.getContext());
                            observer.next?.(result);
                        },
                        error: err => {
                            operation.setContext(localOperation.getContext());
                            observer.error?.(err);
                        },
                        complete: () => {
                            operation.setContext(localOperation.getContext());
                            observer.complete?.();
                        },
                    });

                    return () => {
                        sub.unsubscribe();
                    };
                });
            });
        }
    });

    return link;
};

/**
 * If local resolvers returns a graphql error, it means the selection needs to fallback to the web implementation
 * If the web implementation has a locally defined resolver, it will consume the fallback error code and execute the operation.
 * But, if the web implementation is a remote operation (i.e, there is no local web resolver for it), then the operation needs to
 * fallback to the remote operation, here
 * @param result the result of the local results, which may include hx fallbacksn
 * @param operation the original operation
 * @param remoteLink the remote link
 * @param remoteSelections remote selections for this operation
 */
function mapIdbFallbackToRemote(
    result: FetchResult,
    operation: Operation,
    remoteLink: ApolloLink,
    remoteSelections: SelectionNode[]
) {
    const nonfallbackErrors: Array<GraphQLError> = [];
    const fallbacks: Array<SelectionNode> = [];
    const context = operation.getContext() as ResolverContext & QueuedActionContext;

    result.errors?.reduce(
        (accumulator, err) => {
            const datasource = (err?.extensions as DatasourceExtension)?.datasource;

            if (
                context.fallbackBehavior === 'NoFallback' ||
                context.resolverPolicy === 'localOnly' ||
                context.queuedAction?.state === 'OfflineExecution'
            ) {
                // never fallback to gateway if the callsite has explicitly disabled fallback or is local only
                accumulator.nonfallbackErrors.push(err);
            } else if (datasource !== 'INDEXDB' && !isIdbFallbackResult(err)) {
                // never fallback to gateway if the error didn't originate from IDB or is not an explicit IDB fallback result
                accumulator.nonfallbackErrors.push(err);
            } else {
                // otherwise, fallback if the selection is remote enabled
                const node = findSelectionNode(remoteSelections, err);
                if (node) {
                    accumulator.fallbacks.push(node);
                } else {
                    accumulator.nonfallbackErrors.push(err);
                }
            }

            return accumulator;
        },
        { nonfallbackErrors, fallbacks }
    );

    const originalResult: FetchResult = { ...result, errors: nonfallbackErrors };
    if (nonfallbackErrors.length == 0) {
        delete originalResult.errors;
    }

    const originalObserver = Observable.of(originalResult);

    if (fallbacks.length > 0) {
        return originalObserver.concat(
            remoteLink.request(buildOperation(operation, fallbacks)) || Observable.of<FetchResult>()
        );
    } else {
        return originalObserver;
    }
}

function findSelectionNode(selections: readonly SelectionNode[], err: GraphQLError) {
    // find selection nodes that resulted in hx fallback errors
    let rv = null;
    const path = err.path?.[0];
    if (path) {
        selections.some(s => {
            if (s.kind === 'Field') {
                if (s.alias) {
                    if (s.alias.value === path) {
                        rv = s;
                        return true;
                    }
                } else if (s.name?.value === path) {
                    rv = s;
                    return true;
                }
            }

            return false;
        });
    }

    return rv;
}

function buildOperation(operation: Operation, selections: SelectionNode[]): Operation {
    // we need to make a copy of the gql request specific to the subset of selections for the
    // local or remote endpoint.  the variables/context are assumed to be safe to share between
    // the two operations.
    const copy = {
        ...operation,
        query: { ...operation.query },
        context: operation.getContext(),
    };

    copy.query.definitions = operation.query.definitions.map(d => {
        if (d.kind !== 'OperationDefinition') {
            return d;
        } else {
            return {
                ...d,
                selectionSet: { ...d.selectionSet, selections },
            };
        }
    });

    return createOperation(operation.getContext(), copy);
}

const capitalizeOp = (str: OperationTypeNode) => {
    switch (str) {
        case 'query':
            return 'Query';
        case 'mutation':
            return 'Mutation';
        case 'subscription':
            return 'Subscription';
    }
};

/**
 * From a list of selections, determine which ones are local, remote, or remote enabled
 * @param selections the selections to process
 * @param context the resolver context
 * @param localResolverRoot the root resolver object for the operation type
 * @param skipRemoteCallForcefully whether to skip the remote call forcefully
 * @param isOfflineEnabled whether offline is enabled
 * @param operationType the operation type name
 * @param operation the operation
 */
export const processSelectionNodes = (
    selections: readonly SelectionNode[],
    context: ResolverContext & {
        headers: Record<string, any>;
    },
    localResolverRoot: Resolvers['Query'] | Resolvers['Mutation'] | Resolvers['Subscription'] = {},
    skipRemoteCallForcefully: boolean,
    isOfflineEnabled: boolean,
    operationType: OperationType,
    operation: Operation
) => {
    const remoteSelections: SelectionNode[] = [];
    const localSelections: SelectionNode[] = [];
    const remoteEnabledSelections: SelectionNode[] = [];

    // see if any of the toplevel selections are missing a local execution resolver OR configured to be remote only
    // (__typename is a meta field that is always resolvable locally)
    const sendRemote = (resolverName: TopLevelResolver): boolean => {
        if (context?.gatewayGraphRequest) {
            // call site is forcing execution to remote gateway
            return true;
        } else if (!localResolverRoot[resolverName]) {
            // don't have a local resolver, send remote if configured
            return enabledForRemote(
                operationType,
                resolverName,
                skipRemoteCallForcefully,
                context?.resolverPolicy
            );
        } else if (isOfflineEnabled) {
            // MONARCH: see if we're not enabled locally and also enabled remote
            return (
                !enabledForIdb(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                ) &&
                !enabledForWeb(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                ) &&
                enabledForRemote(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                )
            );
        } else {
            // WEB: see if we're not enabled for web and also enabled for remote
            return (
                !enabledForWeb(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                ) &&
                enabledForRemote(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                )
            );
        }
    };

    const sendLocal = (resolverName: string): boolean => {
        if (context?.gatewayGraphRequest) {
            // call site is forcing execution against the remote gateway
            return false;
        } else if (isOfflineEnabled) {
            // MONARCH: local resolvers
            return (
                enabledForIdb(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                ) ||
                enabledForWeb(
                    operationType,
                    resolverName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                )
            );
        } else {
            // WEB: local resolvers
            return enabledForWeb(
                operationType,
                resolverName,
                skipRemoteCallForcefully,
                context?.resolverPolicy
            );
        }
    };

    const processSelection = (selection: SelectionNode) => {
        if (selection.kind === 'Field') {
            const opName = selection.name.value as TopLevelResolver;
            if (
                enabledForRemote(
                    operationType,
                    opName,
                    skipRemoteCallForcefully,
                    context?.resolverPolicy
                )
            ) {
                remoteEnabledSelections.push(selection);
            }
            if (selection.name.value !== '__typename' && sendRemote(opName)) {
                remoteSelections.push(selection);
            } else if (sendLocal(opName)) {
                localSelections.push(selection);
            } else {
                trace.warn(`[localRemoteRouterLink] ${operationType}.${opName} is not enabled`);
            }
        } else if (selection.kind === 'FragmentSpread') {
            // Expand the fragment spread
            const fragmentName = selection.name.value;
            const fragment = operation.query.definitions.find(
                def => def.kind === 'FragmentDefinition' && def.name.value === fragmentName
            );
            if (fragment && fragment.kind === 'FragmentDefinition') {
                fragment.selectionSet.selections.forEach(fragSelection =>
                    processSelection(fragSelection)
                );
            }
        } else if (selection.kind === 'InlineFragment') {
            // Inline fragment
            selection.selectionSet.selections.forEach(fragSelection =>
                processSelection(fragSelection)
            );
        }
    };

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    selections.forEach(selection => {
        processSelection(selection);
    });

    return { remoteSelections, localSelections, remoteEnabledSelections };
};
