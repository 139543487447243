import { isSuccessStatusCode } from 'owa-http-status-codes';
import { format } from 'owa-localize';
import { makeGetRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import type { EasidResponse } from './schema/EasidResponse';
import type { TraceErrorObject } from 'owa-trace';

const GET_IS_EASID_ENDPOINT =
    'ows/beta/CloudCacheMailboxPremiumPolicy/IsEmailAddressAssociatedWithEasid?emailAddress={0}';

export async function getEasidStatusForCloudCacheAccount(
    emailAddress: string,
    mailboxInfo: MailboxInfo
): Promise<EasidResponse> {
    const easidStatusEndpoint = format(GET_IS_EASID_ENDPOINT, emailAddress);

    const response = await makeGetRequest(
        easidStatusEndpoint,
        undefined /* correlationId */,
        true /* returnFullResponse */,
        undefined /* customHeaders */,
        undefined /* throwServiceError */,
        undefined /* includeCredentials */,
        'getEasidStatusForCloudCacheAccount',
        mailboxInfo
    );

    if (isSuccessStatusCode(response.status)) {
        const easidResponse = await response.json();
        return easidResponse;
    } else {
        const err: TraceErrorObject = new Error('Failed to get EASID status');
        err.additionalInfo = { status: response.status };
        throw err;
    }
}
