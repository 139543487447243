import type { MailboxInfo } from 'owa-client-types';
import { generateMailboxSmtpAddress } from 'owa-folders';
import { getAccountScopeUserSettings } from 'owa-session-store';

export default function getMailboxInfoForArchive(
    userMailboxInfo: MailboxInfo,
    mailboxGuid: string
): MailboxInfo {
    const orgDomain =
        getAccountScopeUserSettings(userMailboxInfo).SessionSettings?.OrganizationDomain;
    const mailboxSmtpAddress =
        mailboxGuid && orgDomain ? generateMailboxSmtpAddress(mailboxGuid, orgDomain) : '';

    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    return {
        type: 'ArchiveMailbox',
        userIdentity: userMailboxInfo.userIdentity,
        mailboxSmtpAddress,
        auxiliaryMailboxGuid: mailboxGuid,
    };
}
