import type { StartSelfProfilerFunction } from 'owa-analytics-types';
import safeRequestAnimationFrame from './safeRequestAnimationFrame';

const timingMap: Map<number, string> = new Map();

export function getTimingMap() {
    return timingMap;
}

export function clearTimingMap() {
    timingMap.clear();
}

type TimingPrefix =
    | 'commandExecutor'
    | 'contentEditor'
    | 'eventListener'
    | 'governor'
    | 'keytrap'
    | 'lazymodule'
    | 'observable'
    | 'onload'
    | 'onfinish'
    | 'perfObserver'
    | 'reactionScheduler'
    | 'resetFocus'
    | 'setInterval'
    | 'setTimeout'
    | 'useCustomAnimationFrame'
    | 'useCustomTimeout'
    | 'useEffect'
    | 'workerResponse'
    | 'workerCallback'
    | 'useResizeObserver';

let profileFunc: StartSelfProfilerFunction | null = null;
export function setProfileFunc(func: StartSelfProfilerFunction) {
    profileFunc = func;
}

export function addToTimingMap(prefix: TimingPrefix, value: string) {
    if (typeof performance?.now === 'function') {
        const source = `${prefix}:${value}`;
        timingMap.set(performance.now(), source);

        if (profileFunc) {
            const endProfile = profileFunc(source, { minDuration: 1000 });
            if (endProfile) {
                safeRequestAnimationFrame(isVisibile => {
                    setTimeout(() => {
                        endProfile(isVisibile);
                    });
                });
            }
        }
    }
}
