import {
    MAIN_WINDOW_ID,
    getStore,
    emptyMenuDefinition,
    placeholderMenuDefinition,
} from '../store/menuDefinitionStore';
import type { MenuDefinition } from '@1js/acui-menu';
import type { MenuDefinitionStoreNames } from '../store/menuDefinitionStore';
import { ObservableMap } from 'mobx';
import { mutatorAction } from 'satcheljs';

/**
 * Creates an entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const createMenuDefinitionStore = mutatorAction(
    'createMenuDefinitionStore',
    function createMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId === undefined) {
            // We create a store on-boot for the main window (undefined), so return early here.
            return;
        }
        getStore().menuDefinitionMapManager.set(projectionTabId, {
            MenuDefinitionMap: new ObservableMap<MenuDefinitionStoreNames, () => MenuDefinition>(),
        });
    }
);

/**
 * Removes the entrypoint in the MenuDefinition map manager for a given projectionTabId.
 * Used specifically for projection popout scenarios.
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const removeMenuDefinitionStore = mutatorAction(
    'removeMenuDefinitionStore',
    function removeMenuDefinitionStore(projectionTabId: string | undefined) {
        if (projectionTabId === undefined) {
            // We never want to delete the main window store, so we return early here.
            return;
        }

        const { menuDefinitionMapManager } = getStore();
        const projectionMap = menuDefinitionMapManager.get(projectionTabId);

        if (projectionMap) {
            projectionMap.MenuDefinitionMap.clear();
        }

        menuDefinitionMapManager.delete(projectionTabId);
    }
);

/**
 * Resets a store's menuDefinition back to one of two "reset" values.
 * Call this mutator in the `onAfterMenuDismiss` callback of a Ribbon control only if you explicitly
 * do not want the menu to be loaded even after the first click.
 * An example of when this is useful is Flag/Unflag and Snooze, of which their menuDefinition has a call
 * to observableNow(), which causes the Ribbon to render every 60 seconds even if nothing is happening.
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const resetMenuDefinition = mutatorAction(
    'resetMenuDefinition',
    function resetMenuDefinition(storeName: MenuDefinitionStoreNames, projectionTabId?: string) {
        const id = projectionTabId || MAIN_WINDOW_ID;
        const { menuDefinitionMapManager } = getStore();
        const rootMap = menuDefinitionMapManager.get(id);
        const storeMap = rootMap && rootMap.MenuDefinitionMap.get(storeName);

        if (storeMap) {
            const resetMenu = () =>
                id === MAIN_WINDOW_ID ? emptyMenuDefinition : placeholderMenuDefinition;

            const isMenuReset = storeMap() === resetMenu();

            // If menu is already reset, avoid resetting it again
            if (!isMenuReset) {
                rootMap.MenuDefinitionMap.set(storeName, resetMenu);
            }
        }
    }
);
