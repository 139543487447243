import { isFeatureEnabled } from 'owa-feature-flags';
import { isTeamsChatEnabled } from 'owa-teams-policies';
import type { TeamsPoliciesScenario } from 'owa-teams-policies-store/lib/store/schema';

export default async function isTeamsEnabled(scenario?: TeamsPoliciesScenario): Promise<boolean> {
    if (isFeatureEnabled('fwk-teamsPolicies')) {
        return isTeamsChatEnabled(scenario);
    } else {
        return false;
    }
}
