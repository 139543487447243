import { LazyModule, LazyAction, LazyImport } from 'owa-bundling-light';
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaM365Acquisitions" */ './lazyIndex'),
    { name: 'OwaM365Acquisitions' }
);

export const lazyGetM365Acquisitions = new LazyAction(lazyModule, m => m.getM365Acquisitions);
export const lazyGetM365Acquisition = new LazyAction(lazyModule, m => m.getM365Acquisition);
export const lazyIsAddInAppStoreEnabled = new LazyImport(lazyModule, m => m.isAddInAppStoreEnabled);
export const lazyUninstallM365Acquisition = new LazyAction(
    lazyModule,
    m => m.uninstallM365Acquisition
);
export const lazyGetCachedAcquisitionsState = new LazyAction(
    lazyModule,
    m => m.getCachedAcquisitionsState
);
export const lazySetAcquisitionsCacheLoadState = new LazyAction(
    lazyModule,
    m => m.setAcquisitionsCacheLoadState
);
export const lazyQueueAcquisitionsNetworkCall = new LazyAction(
    lazyModule,
    m => m.queueAcquisitionsNetworkCall
);
export const lazyAddMessageExtensionToReadSurfaceActionsMutator = new LazyAction(
    lazyModule,
    m => m.addMessageExtensionToReadSurfaceActionsMutator
);
export const lazyAddMessageExtensionToComposeSurfaceActionsMutator = new LazyAction(
    lazyModule,
    m => m.addMessageExtensionToComposeSurfaceActionsMutator
);
export const lazyAddMessageExtensionToCalendarSurfaceActionsMutator = new LazyAction(
    lazyModule,
    m => m.addMessageExtensionToCalendarSurfaceActionsMutator
);
export const lazyRemoveMessageExtensionFromSurfaceActionsMutator = new LazyAction(
    lazyModule,
    m => m.removeMessageExtensionFromSurfaceActionsMutator
);
