import type { MailboxInfo } from 'owa-client-types';
import {
    copilotSettingsStore,
    lazyFetchCopilotSettings,
    FetchState,
    type ImplementedCopilotFeatures,
    type CopilotSettingsStore,
} from 'owa-copilot-settings-store';
import { toJS } from 'mobx';
import { type DatapointVariant, logUsage, logGreyError } from 'owa-analytics';
import { getCurrentLanguage } from 'owa-localize';
import { areFeatureFlagsInitialized } from 'owa-feature-flags';

type GenericEnablementCheckFunction<T extends ImplementedCopilotFeatures, K> = (
    store: CopilotSettingsStore,
    language: string,
    scenario: T,
    mailboxInfo: MailboxInfo,
    skipLanguageCheck?: boolean
) => K;

export default function createStoreCheckForGenericEnablement<
    T extends ImplementedCopilotFeatures,
    K
>(
    funcName: string,
    checkFunc: GenericEnablementCheckFunction<T, K>
): (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => K | undefined {
    return (scenario: T, mailboxInfo: MailboxInfo, skipLanguageCheck?: boolean) => {
        const language = getCurrentLanguage();
        const store = copilotSettingsStore(mailboxInfo);
        switch (store.fetchState) {
            case FetchState.NOT_STARTED:
                if (!areFeatureFlagsInitialized(mailboxInfo)) {
                    // Log a grey error to collect a callstack before the async jump
                    logGreyError('FetchCopilotSettingsWithoutFF');
                }

                // Make sure we do this in a separate microtask as this will call a mutator
                // and this getter should not call mutators to avoid mutators being called
                // within mutators
                Promise.resolve().then(() => {
                    lazyFetchCopilotSettings.importAndExecute(mailboxInfo);
                });
            /* falls through */
            case FetchState.IN_PROGRESS:
                if (store.isEnabled) {
                    // The copilot settings has existed before, but the service call is in progress.
                    // We should still check the feature enablement based on the existing settings.
                    return checkFunc(store, language, scenario, mailboxInfo, skipLanguageCheck);
                } else {
                    // The copilot settings has not existed before, and the service call is in progress.
                    // We should return undefined to indicate that the feature enablement is unknown.
                    logUsage(
                        'Copilot_CheckedBeforeStorePopulated',
                        {
                            funcName,
                            scenario_1: scenario,
                            state_2: toJS(store.fetchState),
                        },
                        {
                            variant: 2,
                        }
                    );
                    return undefined; //Need a separate undefined state when service call is in progress or not started so that perf optimizations can be made in the calling code
                }
            case FetchState.SUCCEEDED:
            case FetchState.FAILED:
                return checkFunc(store, language, scenario, mailboxInfo, skipLanguageCheck);
        }
    };
}
