import { getListViewState, getSelectedTableView, isItemPartOperation } from 'owa-mail-list-store';
import {
    lazyMoveItemsBasedOnNodeIds,
    lazyMoveMailListRows,
    lazyMoveMailListRowsToFocusedOrOther,
    lazyAlwaysMoveRowToFocusedOrOther,
    lazyCopyMailListRows,
    lazyMoveSingleItemFromItemId,
} from 'owa-mail-triage-action';
import { getActionSourceFromCommandingViewMode } from 'owa-command-ribbon';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { resetFocus } from 'owa-mail-focus-manager';
import { getRootFolderIdForGivenContext } from 'owa-mail-moveto-control/lib/helpers/moveToCommonUtils';
import {
    setFocusOnMoveToMenuSearchBox,
    setFocusOnCopyToMenuSearchBox,
} from 'owa-mail-moveto-control/lib/helpers/setFocusOnMoveToMenuSearchBox';
import { getMailboxInfoForFolderId, lazyFetchMoreFolders } from 'owa-folders';
import {
    createNewFolder,
    createNewGroupFolder,
} from 'owa-mail-movetofolder-view/lib/components/createNewFolderUtil';
import moveToStore from 'owa-mail-moveto-control/lib/store/store';
import { getGroupIdFromTableQuery, isGroupTableQuery } from 'owa-group-utils';
import { getGroupRootFolderId } from 'owa-groups-shared-folders-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailboxInfo, MailboxType } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    onSearchTextChanged,
    onViewAllMenuClicked,
} from 'owa-mail-moveto-control/lib/actions/internalActions';
import { type LoadMoreFoldersActionSource } from 'owa-folders-constants';
import type { ClientItemId } from 'owa-client-ids';
import { getStore as getMailStore } from 'owa-mail-store/lib/store/Store';
import { errorThatWillCauseAlert } from 'owa-trace';
import closeProjection from 'owa-popout-v2/lib/actions/closeProjection';
import getProjectionViewState from 'owa-popout-v2/lib/selectors/getProjectionViewState';

import { lazyCreateCopyItem, lazyCreateCopyItemFailedNotification } from 'owa-mail-file-support';
import type CreateItemResponse from 'owa-service/lib/contract/CreateItemResponse';
import getTabById from 'owa-tab-store/lib/utils/getTabById';
import { type SecondaryReadingPaneTabViewState } from 'owa-tab-store';

export const onMoveToFolderClicked = (folderId: string) => {
    const tableView = getSelectedTableView();
    let markAsReadAction = Promise.resolve();

    // Mark as read item if user selected only one item, as reading pane
    // cannot show more than one item at a time if there are multiple items selected.
    if (tableView.selectedRowKeys.size === 1) {
        markAsReadAction = lazyOnSingleSelectionChanged.importAndExecute(
            [...tableView.selectedRowKeys.keys()][0],
            tableView,
            true /* isUserNavigation */
        );
    }

    if (isFeatureEnabled('grp-loadFolders') && isGroupTableQuery(tableView?.tableQuery)) {
        // In groups, the folder IDs are present in REST format. Since we are using OWS API to perform Move To in Groups,
        // REST ID needs to be converted to OWS format.
        // Tracking Bug - Bug 131273: Remove OWS to REST ID and vice versa Id conversion logic once platform provides support.
        folderId = folderId.split('-').join('/').split('_').join('+');
    }

    markAsReadAction.then(() => {
        if (isItemPartOperation()) {
            lazyMoveItemsBasedOnNodeIds.import().then(moveItemsBasedOnNodeIds => {
                moveItemsBasedOnNodeIds(
                    getListViewState().expandedConversationViewState.selectedNodeIds,
                    folderId,
                    tableView.id,
                    getActionSourceFromCommandingViewMode()
                );
            });
        } else {
            lazyMoveMailListRows.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                folderId,
                tableView.id,
                getActionSourceFromCommandingViewMode()
            );
        }
    });
    resetFocus('ribbonMoveMenuDismissed');
};

export const onCopyToFolderClicked = (
    folderId: string,
    itemId?: ClientItemId,
    projectionTabId?: string
) => {
    const clientItem = itemId ? getMailStore().items.get(itemId.Id) : undefined;

    if (!clientItem || !clientItem.MailboxInfo) {
        errorThatWillCauseAlert('MailboxInfo is empty');
    }

    if (itemId && folderId && clientItem) {
        // Check if the item is a local item
        const tabData = (getTabById(projectionTabId) as SecondaryReadingPaneTabViewState)?.data;
        const isCachedItem = !!tabData?.isFileActivation && !!tabData?.isCachedItem;

        // Copy/Move requires a parent folder id, so if the item is local or if the item doesn't have a parent folder id, we need to create the item
        if (isCachedItem || !clientItem?.ParentFolderId?.Id) {
            lazyCreateCopyItem
                .importAndExecute(clientItem, folderId, clientItem.MailboxInfo)
                .then((res: CreateItemResponse) => {
                    if (res.ResponseMessages?.Items?.[0]) {
                        const itemInfoResponseMessage = res.ResponseMessages.Items[0];
                        if (itemInfoResponseMessage.ResponseClass === 'Success') {
                            clientItem.ItemId = itemInfoResponseMessage.Items[0].ItemId;
                        }
                    }
                })
                .catch(e => {
                    lazyCreateCopyItemFailedNotification.importAndExecute(e.networkError);
                })
                .finally(() => {
                    // closing the deeplink window after the item is copied to the folder because the window is empty after copy/move
                    if (isFeatureEnabled('mon-file-projection') && projectionTabId) {
                        // close the projection tab
                        closeProjection(projectionTabId, false /* redirectToDeeplink */);
                    } else {
                        // close the deeplink window
                        window.close();
                    }
                });
        } else {
            lazyMoveSingleItemFromItemId.importAndExecute(itemId.Id, folderId).then(() => {
                // closing the deeplink window after the item is copied to the folder because the window is empty after copy/move
                if (isFeatureEnabled('mon-file-projection') && projectionTabId) {
                    // close the projection tab
                    closeProjection(projectionTabId, false /* redirectToDeeplink */);
                } else {
                    // close the deeplink window
                    window.close();
                }
            });
        }
    } else {
        errorThatWillCauseAlert('Folder id should always be set');
    }
};

export const onCreateNewFolderClicked = (folderName: string) => {
    const tableView = getSelectedTableView();
    const userMailboxInfo = getModuleContextMailboxInfo();
    if (isFeatureEnabled('grp-loadFolders') && isGroupTableQuery(tableView?.tableQuery)) {
        const groupId = getGroupIdFromTableQuery(tableView?.tableQuery);
        if (groupId) {
            const rootFolderId = getGroupRootFolderId(groupId, userMailboxInfo);
            /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
             * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
             *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
            const mailboxInfo: MailboxInfo = {
                type: 'GroupMailbox',
                userIdentity: userMailboxInfo?.userIdentity ?? '',
                mailboxSmtpAddress: groupId,
            };

            if (rootFolderId) {
                createNewGroupFolder(folderName, rootFolderId, mailboxInfo).then(folderId => {
                    if (folderId) {
                        onMoveToFolderClicked(folderId);
                    }
                });
            }
        }
    } else {
        const { moveToMailboxType, moveToSharedFolderRootId } = moveToStore;

        // Get appropriate root folder id depending on the current moveToMailboxType.
        // Except 'SharedMailbox', all other mbxs have static rootFolderId. But sharedFolder root Id is stored in local store.
        const rootFolderId =
            moveToMailboxType === 'SharedMailbox'
                ? moveToSharedFolderRootId
                : getRootFolderIdForGivenContext(moveToMailboxType, userMailboxInfo);

        if (rootFolderId === undefined) {
            return;
        }

        const mailboxInfo = getMailboxInfoForFolderId(
            rootFolderId,
            false /* routeToAuxIfAuxArchive */
        );

        createNewFolder(folderName, rootFolderId, mailboxInfo).then(folderId => {
            if (folderId) {
                onMoveToFolderClicked(folderId);
            }
        });
    }

    // Dismiss menu
    resetFocus('RibbonNewFolderDismissed');
};

// Handle custom folder creation for integrated spam (extesnsibility feature), to create the
// custom folder under the correct root folder ie. the Inbox of the mailbox that the item is in
export const onCreateAndMoveCustomFolderForSpamAddin = (
    folderName: string,
    mailboxInfo: MailboxInfo
) => {
    const { moveToSharedFolderRootId } = moveToStore;
    if (moveToSharedFolderRootId === undefined) {
        return;
    }

    // Create custom under the correct root folder
    createNewFolder(folderName, moveToSharedFolderRootId, mailboxInfo).then(folderId => {
        if (folderId) {
            onMoveToFolderClicked(folderId);
        }
    });
};

export const onMoveToFocusedOtherInboxClicked = (toViewFilter: FocusedViewFilter) => {
    const tableView = getSelectedTableView();

    lazyMoveMailListRowsToFocusedOrOther.importAndExecute(
        [...tableView.selectedRowKeys.keys()],
        tableView.id,
        toViewFilter,
        getActionSourceFromCommandingViewMode()
    );
    resetFocus('ribbonMoveMenuFocusedOther');
};

export const onAlwaysMoveToFocusedOtherInboxClicked = (toViewFilter: FocusedViewFilter) => {
    const tableView = getSelectedTableView();

    lazyAlwaysMoveRowToFocusedOrOther.importAndExecute(
        [...tableView.selectedRowKeys.keys()],
        tableView,
        toViewFilter,
        getActionSourceFromCommandingViewMode()
    );
    resetFocus('ribbonMoveMenuAlwaysFocusedOther');
};

export const onCopyClicked = (folderId: string) => {
    const tableView = getSelectedTableView();
    // Functions for individual actions
    lazyCopyMailListRows.importAndExecute(
        [...tableView.selectedRowKeys.keys()],
        tableView,
        folderId,
        getActionSourceFromCommandingViewMode()
    );
};

export const onMoveToDifferentFolderClicked = (
    mailboxInfo: MailboxInfo,
    currentSearchText: string,
    newSearchText: string,
    mailboxType: MailboxType | undefined,
    mailboxSmtpAddress: string | undefined,
    isCopyTo?: boolean,
    projectionTabId?: string
) => {
    isCopyTo
        ? setFocusOnCopyToMenuSearchBox(getProjectionViewState(projectionTabId || '')?.window)
        : setFocusOnMoveToMenuSearchBox();

    onViewAllMenuClicked({
        ...mailboxInfo,
        type: mailboxType ?? mailboxInfo.type,
        mailboxSmtpAddress: mailboxSmtpAddress ?? '',
    });

    onSearchTextChanged(currentSearchText, newSearchText);
};

export const onLoadMoreFoldersClicked = (mailboxInfo: MailboxInfo) => {
    lazyFetchMoreFolders.import().then(fetchMoreFolders => fetchMoreFolders(mailboxInfo, 'Ribbon'));
};
