import loadInitialRowsFromServer from './loadInitialRowsFromServer';
import type { PerformanceDatapoint } from 'owa-analytics';
import { returnTopExecutingActionDatapoint } from 'owa-analytics';
import { initTableSelectionOnLoad } from 'owa-mail-actions/lib/initTableSelectionOnLoad';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import type { ActionSource } from 'owa-mail-store';
import { updateTablesInCache } from 'owa-mail-triage-local-updates/lib/actions/tableCache/updateTablesInCache';
import { MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD } from 'owa-mail-triage-common';
import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';
import { type TableQueryType, resetTableViewProperties } from 'owa-mail-list-store';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { mutatorAction } from 'satcheljs';
import { DataSource } from 'owa-analytics-types';
import { isOfflineMailDataEnabled } from 'owa-offline-sync-feature-flags';

/**
 * Performs the first load for a mail table
 * @param tableView - the given mail tableView
 * @param onInitialTableLoadComplete - callback for when the initial table load has completed
 * @param actionSource action that initiated the switch folder action
 * @return a promise that resolves when the load initial table has completed either from server or from cache
 */
export default function loadInitialMailTable(
    tableView: TableView,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    actionSource: ActionSource,
    initialSessionData?: SessionData
): Promise<void> {
    setCurrentLoadedIndex(tableView);

    // Init table selection on any cached items we have
    initTableSelectionOnLoad(tableView, actionSource == 'FolderNodeClick');

    const isTableViewOfFolderType = tableView.tableQuery.type == 0;

    // Update tables in cache
    updateTablesInCache(tableView);

    const scenarioType = (tableView.tableQuery as MailFolderTableQuery).scenarioType;

    if (isTableViewOfFolderType) {
        const datapoint = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
            return dp.getEventName() == 'SwitchMailFolder';
        });

        if (datapoint) {
            datapoint.addToCustomWaterfall(2, 'LoadInitialMailTable_S');
            datapoint.addCustomProperty(
                'isCached',
                tableView.isInitialLoadComplete // table was loaded once before so its in the cache
            );
            datapoint.addCustomProperty('scenario', scenarioType);
            datapoint.addDataSource(
                tableView.isInitialLoadComplete ? DataSource.IN_MEMORY : DataSource.NETWORK
            );
            datapoint.addToCustomWaterfall(
                1,
                'isOfflineSyncEnabled',
                false /* discardIfDefined*/,
                isOfflineMailDataEnabled() ? 'true' : 'false'
            );
        }
    }

    const shouldShowCachedItems =
        tableView.isInitialLoadComplete && tableView.loadedStartIndex === 0;

    // Reset the table view properties, if we are not loaded from the top of the table
    if (tableView.loadedStartIndex !== 0) {
        resetTableViewProperties(tableView);
    }

    const loadFromServerPromise = loadInitialRowsFromServer(
        tableView,
        false /* isTablePrefetched */,
        onInitialTableLoadComplete,
        initialSessionData,
        shouldShowCachedItems /* skipGqlTimings */
    );

    // Resolve the initial load promise immediately if we're showing cached items, otherwise resolve when the server returns results
    return shouldShowCachedItems ? Promise.resolve() : loadFromServerPromise;
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setCurrentLoadedIndex = mutatorAction('setCurrentLoadedIndex', (tableView: TableView) => {
    // load table from cache by setting the currentLoadedIndex
    tableView.currentLoadedIndex = Math.min(
        MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD,
        tableView.rowKeys.length
    );
});
