import type { MailboxInfo } from 'owa-client-types';
import type {
    M365UserMailboxAccountSource,
    M365SharedMailboxAccountSource,
} from 'owa-account-source-list-store';
import { isRemovedAccount } from 'owa-account-source-list-types';
import { getAccountBySourceId, setSharedSources } from 'owa-account-source-list-store';
import { buildM365SharedMailboxAccountSource } from 'owa-account-source-list/lib/utils/accountSourceBuilder';
import getUserAccount from './utils/getUserAccount';
import {
    runActionWithAsyncFallback,
    type ActionExecution,
} from './utils/runActionWithAsyncFallback';
import { logMailboxInfoNotFromStore } from './utils/logMailboxInfoNotFromStore';

/**
 * Adds the shared mailbox to the account source
 * @param account Specifies the account to which the shared mailbox is to be added
 * @param smtpAddress SMTP address of the shared mailbox
 * @param displayName Optionally specifies if the display name of the shared mailbox
 * @param isAutomapped Optionally specifies if the shared mailbox is auto mapped
 */
function addShardMailboxToAccount(
    account: M365UserMailboxAccountSource,
    sharedMailbox: M365SharedMailboxAccountSource
): ActionExecution {
    return runActionWithAsyncFallback('SharedMailboxes', () => {
        setSharedSources(account.sourceId, {
            loadState: account.sharedSources.loadState,
            sources: [...account.sharedSources.sources, sharedMailbox],
            coprincipalSourceId: account.sourceId,
        });
    });
}

/**
 * Returns the MailboxInfo for the shared mailbox
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @param smtpAddress SMTP address of the shared mailbox
 * @param displayName Optionally specifies the display name of the shared mailbox
 * @param isAutomapped Optionally specifies if the shared mailbox is auto mapped
 * @returns MailboxInfo for the shared mailbox
 */
export function getMailboxInfoForShared(
    mailboxInfoInAccount: MailboxInfo,
    smtpAddress: string,
    displayName?: string,
    isAutomapped?: boolean,
    accountDescription?: string
): MailboxInfo {
    const account = getUserAccount(mailboxInfoInAccount);

    // We build the shared mailbox account source that would need to be added, this will get us the sourceId
    // for the shared mailbox which we can use to find if it already exists or needs to be added
    const sharedMailbox = buildM365SharedMailboxAccountSource(
        displayName || smtpAddress,
        smtpAddress,
        account.mailboxInfo,
        isAutomapped || false,
        account.persistenceId,
        {},
        accountDescription
    );

    if (isRemovedAccount(account)) {
        // Mark the MailboxInfo as coming from a removed account
        return { ...sharedMailbox.mailboxInfo, isRemoved: true, mailboxRank: 'Resource' };
    }

    let existing = getAccountBySourceId(sharedMailbox.sourceId);
    if (!existing) {
        const execution = addShardMailboxToAccount(account, sharedMailbox);
        existing = getAccountBySourceId(sharedMailbox.sourceId);
        if (!existing) {
            logMailboxInfoNotFromStore(execution, sharedMailbox.mailboxInfo.type);
            return sharedMailbox.mailboxInfo;
        }
    }

    return existing.mailboxInfo;
}
