import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getSelectedCalendarsStore } from '../store/store';
import { dedupeArrayValues } from 'owa-selected-calendars-utils';
import { getSelectedCalendarsForUser } from '../selectors/getSelectedCalendarsForUser';
import { convertIdsToTargetFormat, ConvertIdSource } from 'owa-immutable-id';
import { getTargetFormat } from 'owa-immutable-id-store';
import { updateSelectedCalendars, refreshImmutableSelectedCalendars } from './publicActions';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const updateSelectedCalendarsInStore = mutatorAction(
    'UPDATE_SELECTED_CALENDARS_IN_STORE',
    (calendarIds: string[], mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendarsStore().selectedCalendars.set(key, dedupeArrayValues(calendarIds));
    }
);

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const addSelectedCalendarInStore = mutatorAction(
    'ADD_SELECTED_CALENDAR_IN_STORE',
    (calendarId: string, mailboxInfo: MailboxInfo) => {
        const key = getIndexerValueForMailboxInfo(mailboxInfo);
        getSelectedCalendarsStore().selectedCalendars.set(
            key,
            dedupeArrayValues([...getSelectedCalendarsForUser(mailboxInfo), calendarId])
        );
    }
);

export const formatInitialSelectedCalendars = async (ids: string[], mailboxInfo: MailboxInfo) => {
    const formattedResult = await convertIdsToTargetFormat(
        ids,
        getTargetFormat(mailboxInfo),
        mailboxInfo.userIdentity,
        ConvertIdSource.SelectedCalendars,
        mailboxInfo
    );

    updateSelectedCalendars(formattedResult, mailboxInfo);
    refreshImmutableSelectedCalendars(mailboxInfo);
};
