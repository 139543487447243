import React from 'react';
import { observer } from 'owa-mobx-react';
import { ToggleFavoriteButton } from 'owa-favorites';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isSingleLineListView } from 'owa-mail-layout';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import type { UserSettingsStyleSelector } from '../../utils/getStyleSelectorAsPerUserSettings';

import {
    customTextContainer,
    showSelectionModeButtonSCL,
    showSelectionModeButtonMCL,
    full,
    medium,
    compact,
    customHeaderText,
    customHeaderTextWithFavorite,
    favoriteIconFull,
    favoriteIconMedium,
    favoriteIconCompact,
} from './MailListHeaderSecondRow.scss';
import classnames from 'owa-classnames';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { isCapabilityEnabled } from 'owa-capabilities';
import { favoritesServiceCapability } from 'owa-capabilities-definitions/lib/favoritesServiceCapability';

export interface MailListHeaderSecondRowTextContentProps {
    text?: string;
    containerCssClass?: string;
    showFavoriteToggle?: boolean;
    onFavoriteToggleClick?: (evt: React.MouseEvent<unknown>) => void;
    isInFavorites?: boolean;
    styleSelectorAsPerUserSettings?: UserSettingsStyleSelector;
    mailboxInfo: MailboxInfo;
}

const noop = () => {};

export default observer(function MailListHeaderSecondRowTextContent(
    props: MailListHeaderSecondRowTextContentProps
) {
    const isSingleLineView = isSingleLineListView();
    const showSelectionModeButon = !getIsSearchTableShown();
    const shouldShowFavoriteToggle =
        props.showFavoriteToggle &&
        isCapabilityEnabled(favoritesServiceCapability, props.mailboxInfo);

    return (
        <div
            className={classnames(
                customTextContainer,
                props.containerCssClass,
                showSelectionModeButon && !isSingleLineView && showSelectionModeButtonSCL,
                showSelectionModeButon && isSingleLineView && showSelectionModeButtonMCL,
                getDensityModeCssClass(full, medium, compact)
            )}
            role="heading"
            aria-level={2}
        >
            {props.text && (
                <span
                    className={classnames(
                        customHeaderText,
                        shouldShowFavoriteToggle && customHeaderTextWithFavorite,
                        getDensityModeCssClass(full, medium, compact)
                    )}
                >
                    {props.text}
                </span>
            )}
            {shouldShowFavoriteToggle && (
                <ToggleFavoriteButton
                    isInFavorites={props.isInFavorites ?? false}
                    onClick={props.onFavoriteToggleClick ?? noop}
                    iconStyles={getDensityModeCssClass(
                        favoriteIconFull,
                        favoriteIconMedium,
                        favoriteIconCompact
                    )}
                />
            )}
        </div>
    );
},
'MailListHeaderSecondRowTextContent');
