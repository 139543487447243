import { LazyModule, LazyAction } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SxSData" */ './lazyIndex'),
    { name: 'SxSData' }
);

export const lazyResetPreviewPane = new LazyAction(lazyModule, m => m.resetPreviewPane);
export const lazySetAudioPreviewState = new LazyAction(lazyModule, m => m.setAudioPreviewState);
export const lazySetBlankPreviewState = new LazyAction(lazyModule, m => m.setBlankPreviewState);
export const lazySetCustomPreviewState = new LazyAction(lazyModule, m => m.setCustomPreviewState);
export const lazySetIframePreviewState = new LazyAction(lazyModule, m => m.setIframePreviewState);
export const lazySetImageGalleryItems = new LazyAction(lazyModule, m => m.setImageGalleryItems);
export const lazySetImagePreviewLoaded = new LazyAction(lazyModule, m => m.setImagePreviewLoaded);
export const lazySetImagePreviewState = new LazyAction(lazyModule, m => m.setImagePreviewState);
export const lazySetImagePreviewStateFromImageGalleryItem = new LazyAction(
    lazyModule,
    m => m.setImagePreviewStateFromImageGalleryItem
);
export const lazySetNativePdfPreviewState = new LazyAction(
    lazyModule,
    m => m.setNativePdfPreviewState
);
export const lazySetPanesSize = new LazyAction(lazyModule, m => m.setPanesSize);
export const lazySetPdfJsPreviewState = new LazyAction(lazyModule, m => m.setPdfJsPreviewState);
export const lazySetPdfJsPreviewStateByData = new LazyAction(
    lazyModule,
    m => m.setPdfJsPreviewStateByData
);
export const lazySetPreviewError = new LazyAction(lazyModule, m => m.setPreviewError);
export const lazySetPreviewLoading = new LazyAction(lazyModule, m => m.setPreviewLoading);
export const lazySetRightPaneDisplayAndUpdateSize = new LazyAction(
    lazyModule,
    m => m.setRightPaneDisplayAndUpdateSize
);
export const lazySetSaveStatusText = new LazyAction(lazyModule, m => m.setSaveStatusText);
export const lazySetTextPreviewState = new LazyAction(lazyModule, m => m.setTextPreviewState);
export const lazySetVideoPreviewState = new LazyAction(lazyModule, m => m.setVideoPreviewState);
export const lazySetWacPreviewState = new LazyAction(lazyModule, m => m.setWacPreviewState);
export const lazySetWacReadOnly = new LazyAction(lazyModule, m => m.setWacReadOnly);
export const lazyUpdatePanesSize = new LazyAction(lazyModule, m => m.updatePanesSize);
export const lazySaveHideReadingPaneOption = new LazyAction(
    lazyModule,
    m => m.saveHideReadingPaneOption
);
export const lazySaveDefaultEditCommandOption = new LazyAction(
    lazyModule,
    m => m.saveDefaultEditCommandOption
);
export const lazySetShowSummaryPane = new LazyAction(lazyModule, m => m.setShowSummaryPane);
export const lazySetPdfJsPasswordState = new LazyAction(lazyModule, m => m.setPdfJsPasswordState);
export const lazySetMsPdfPasswordState = new LazyAction(lazyModule, m => m.setMsPdfPasswordState);
export const lazySetMsPdfPreviewState = new LazyAction(lazyModule, m => m.setMsPdfPreviewState);
export const lazySetMsPdfPreviewStateByData = new LazyAction(
    lazyModule,
    m => m.setMsPdfPreviewStateByData
);
export const lazyGetHideReadingPaneOption = new LazyAction(
    lazyModule,
    m => m.getHideReadingPaneOption
);
export const lazyGetDefaultEditCommandOption = new LazyAction(
    lazyModule,
    m => m.getDefaultEditCommandOption
);

export { AutoHideEmailEvent } from './utils/eventNames';
export { MIN_PREVIEW_PANE_WIDTH, RESPONSIVE_READING_PANE_WIDTH } from './utils/sxsConstants';
export type { default as JsApiInfo } from './store/schema/JsApiInfo';
export type { default as LoadedInfo } from './store/schema/LoadedInfo';
export type { default as WacPreviewState } from './store/schema/WacPreviewState';
export type { default as VideoPreviewState } from './store/schema/VideoPreviewState';
export type { default as TextPreviewState } from './store/schema/TextPreviewState';
export { RightPaneDisplay } from './store/schema/SxSViewState';
export type { default as SxSViewState } from './store/schema/SxSViewState';
export { PreviewPaneMode } from './store/schema/PreviewPaneViewState';
export type {
    PreviewPaneErrorViewState,
    PreviewPaneLoadingViewState,
    PreviewPaneViewStateBase,
} from './store/schema/PreviewPaneViewState';
export { PdfJsPasswordState } from './store/schema/PdfJsPreviewState';
export { MsPdfPasswordState } from './store/schema/MsPdfPreviewState';
export type { default as PdfJsPreviewState } from './store/schema/PdfJsPreviewState';
export type { default as MsPdfPreviewState } from './store/schema/MsPdfPreviewState';
export type { default as NativePdfPreviewState } from './store/schema/NativePdfPreviewState';
export type { default as ImagePreviewState } from './store/schema/ImagePreviewState';
export type { default as ImageGalleryItem } from './store/schema/ImageGalleryItem';
export type { ImageGalleryPromise } from './store/schema/ImageGalleryItem';
export type { default as IframePreviewState } from './store/schema/IFramePreviewState';
export type { default as AudioPreviewState } from './store/schema/AudioPreviewState';
