import { createStore } from 'satcheljs';

interface CopilotCommandCenterStore {
    isCommandCenterVisible: boolean;
}

const store = createStore<CopilotCommandCenterStore>('CopilotCommandCenterStore', {
    isCommandCenterVisible: false,
})();
export default () => store;
