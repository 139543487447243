import { createStore } from 'satcheljs';
import { getIsLightRPOnFromStorage } from '../utils/localStorage';
import type LighReadingPaneStore from './schema/LightReadingPaneStore';

const initialLightRPStore: LighReadingPaneStore = {
    isLightRPOn: getIsLightRPOnFromStorage(),
};

const store = createStore<LighReadingPaneStore>('lightReadingPaneStore', initialLightRPStore)();
export default store;
export const getStore = () => store;
