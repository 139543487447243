import type { Config } from 'dompurify';

// Centralized default config for the sanitizer
const defaultTrustedTypesConfig: Config = {
    USE_PROFILES: { html: true },
    // This attributes would be allowed on every html of the code
    ADD_ATTR: ['target', 'originalsrc', 'summary', 'name', 'content'],
};

const getGlobalSanitizerConfig = (extendedConfig: Config): Config => {
    // Merge USE_PROFILES
    const USE_PROFILES = {
        ...defaultTrustedTypesConfig.USE_PROFILES,
        ...extendedConfig.USE_PROFILES,
    };

    // Merge ADD_ATTR and remove duplicates
    const ADD_ATTR = Array.from(
        new Set([...(defaultTrustedTypesConfig.ADD_ATTR || []), ...(extendedConfig.ADD_ATTR || [])])
    );

    return {
        ...defaultTrustedTypesConfig,
        ...extendedConfig,
        ...{ USE_PROFILES, ADD_ATTR },
    };
};

export default getGlobalSanitizerConfig;
