import onSelectionChange from '../events/onSelectionChange';
import singleSelectRowInternal from '../internal/singleSelectRowInternal';
import singleSelectRowInternalMutatorAction from '../internal/mutators/singleSelectRowInternalMutatorAction';
import { onAfterSelectionChanged } from 'owa-mail-actions/lib/mailListSelectionActions';
import { singleSelectRow } from 'owa-mail-actions/lib/singleSelectRow';
import { orchestrator } from 'satcheljs';
import { delayMobxReactionsForCurrentTask } from 'owa-configure-mobx';
import { logUsage } from 'owa-analytics';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { getShouldAnimate } from 'owa-mail-list-item-animation-store';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import { createSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointUtils';
import shouldOpenPopout from '../utils/shouldOpenPopout';
import { rowExpansionStateMutator } from '../mutators/rowExpansionStateMutator';
import getRowExpansionState, {
    ExpansionState,
} from 'owa-mail-list-store/lib/selectors/getRowExpansionState';

/////////////////////////////////////// PLEASE READ ///////////////////////////////////////////////////////////
// This is a top level selection action, as such, it should never call other top-level selection
// actions in the same directory, but only call into internal subdirectories where the core logic is implemented.
// This prevents double logging for CTQs as well as prevents onSelectionChange from being fired multiple times
// for a single user action.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////

export default <(actionMessage: ReturnType<typeof singleSelectRow>) => void | Promise<void>>(
    orchestrator(singleSelectRow, actionMessage => {
        const { tableView, isUserNavigation, mailListItemSelectionSource, rowKey, eventTimestamp } =
            actionMessage;

        if (!tableView) {
            logUsage('MailListSelection_SingleSelectRow_InvalidTableView');
            return;
        }

        createSelectMailItemDatapoint(mailListItemSelectionSource, tableView, rowKey);

        // Before resetting selection, need to store currently selected row key and expansion state to be marked as read
        const oldSelectedRowKey =
            tableView.selectedRowKeys.size == 1
                ? [...tableView.selectedRowKeys.keys()][0]
                : undefined;
        const expansionState: ExpansionState = getRowExpansionState(oldSelectedRowKey ?? '');
        const { forks, selectedNodeIds } = getListViewState().expandedConversationViewState;
        const wasOldSelectedRowExpanded =
            expansionState === ExpansionState.FULLY_EXPANDED ||
            (expansionState === ExpansionState.BRANCHED && forks?.length);
        const nodeId = wasOldSelectedRowExpanded ? selectedNodeIds[0] : null;

        rowExpansionStateMutator(rowKey, mailListItemSelectionSource);
        // Delaying reactions messes up the row removal animation, so skip the delay in that case.
        if (
            !(
                mailListItemSelectionSource === MailListItemSelectionSource.RowRemoval &&
                getShouldAnimate()
            )
        ) {
            delayMobxReactionsForCurrentTask('singleSelectRowOrchestrator');
        }

        // Mark the previously selected row as read
        if (oldSelectedRowKey && actionMessage.rowKey !== oldSelectedRowKey) {
            lazyOnSingleSelectionChanged.importAndExecute(
                oldSelectedRowKey,
                tableView,
                isUserNavigation,
                nodeId,
                !!(
                    expansionState === ExpansionState.BRANCHED && forks?.length
                ) /*wasPreviousRowFirstLevelExpanded*/
            );
        }

        if (!shouldOpenPopout(mailListItemSelectionSource)) {
            singleSelectRowInternalMutatorAction(
                tableView,
                actionMessage.rowKey,
                mailListItemSelectionSource
            );
        } else {
            singleSelectRowInternal(
                tableView,
                actionMessage.rowKey,
                mailListItemSelectionSource,
                eventTimestamp
            );
        }

        // Propagate selection change event
        return onSelectionChange(
            tableView,
            isUserNavigation,
            mailListItemSelectionSource,
            eventTimestamp
        ).then(() => {
            onAfterSelectionChanged(actionMessage.rowKey, tableView, mailListItemSelectionSource);
        });
    })
);
