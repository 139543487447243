import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import getConditionalAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getConditionalAccessIssue';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { isVanityDomain } from './isVanityDomain';
import { logUsage } from 'owa-analytics';
import { getApplicationSettings } from 'owa-application-settings';

export function isMOSLaunchPagesEnabled(mailboxInfo?: MailboxInfo): boolean {
    const mboxInfo = mailboxInfo ?? getModuleContextMailboxInfo();
    const combinedAccessIssue = isFeatureEnabled('mos-disableCombinedAccessCheck')
        ? getConditionalAccessIssue(mboxInfo).shift() ?? 2
        : getCombinedAccessIssue(mboxInfo);
    const isVanityDomainValue = isVanityDomain();

    const mosAppinVanityDomain =
        !isVanityDomainValue || getApplicationSettings('OwaVanityDomains').enabled;

    logUsage('MOS-MOSLaunchPagesEnabled', {
        CombinedAccessIssue: combinedAccessIssue,
        IsVanityDomain: isVanityDomainValue,
    });

    return combinedAccessIssue === 2 && mosAppinVanityDomain;
}
