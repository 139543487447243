import { LazyAction, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { onLoadTheme } from 'owa-theme-common';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotThemes" */ './lazyIndex')
);

// Export public actions
export { onVisualThemingStarted, onVisualThemingEnded } from './actions/publicActions';

export const lazyApplyCopilotTheme = new LazyAction(lazyModule, m => m.applyCopilotTheme);

export const lazyFetchCopilotTheme = new LazyAction(lazyModule, m => m.fetchVisualTheme);

export const lazyFetchCopilotThemeImage = new LazyAction(lazyModule, m => m.fetchCopilotThemeImage);

export const lazyTryScheduleThemeUpdate = new LazyAction(lazyModule, m => m.tryScheduleThemeUpdate);

// Register lazy orchestrators
registerLazyOrchestrator(onLoadTheme, lazyModule, m => m.loadThemeOrchestrator);
