// documentation for adding colors: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/4626/Colors
export const LIGHT_THEME_NEUTRALS = {
    black: '#000000',
    blackTranslucent50: 'rgba(0,0,0,.5)',
    blackTranslucent40: 'rgba(0,0,0,.4)',
    blackTranslucent20: 'rgba(0,0,0,.2)',
    blackTranslucent10: 'rgba(0,0,0,.1)',
    white: '#FFFFFF',
    whiteTranslucent40: 'rgba(255,255,255,.4)',
    whiteTranslucent50: 'rgba(255,255,255,.5)',
    whiteTranslucent65: 'rgba(255,255,255,.65)',
    whiteTranslucent70: 'rgba(255,255,255,.7)',
    whiteTranslucent90: 'rgba(255,255,255,.9)',
    neutralDark: '#242424',
    neutralPrimary: '#424242',
    neutralPrimaryAlt: '#616161',
    neutralPrimarySurface: '#FFFFFF',
    neutralSecondary: '#808080',
    neutralSecondaryAlt: '#A6A6A6',
    neutralSecondarySurface: '#F5F5F5',
    neutralTertiary: '#D1D1D1',
    neutralTertiaryAlt: '#E0E0E0',
    neutralTertiarySurface: '#F0F0F0',
    neutralQuaternary: '#E5E5E5',
    neutralQuaternaryAlt: '#EBEBEB',
    neutralLight: '#F0F0F0',
    neutralLighter: '#F5F5F5',
    neutralLighterAlt: '#FAFAFA',
    redDark: '#A4262C',
    green20: '#498205',
    purple: '#881798',
    suiteuxNeutralLight: '#E1DFDD', // Gray40
    suiteuxNeutralSecondary: '#484644', // Gray140

    flaggedMessage: '#fffdd9',
    richUserContentBackground: '#fff',
    composeNeutralBackground: '#fff',
    composeNeutralLighterBackground: '#F5F5F5',
    readingPaneCardBorder: '#EBEBEB',
    readingPaneCardFocusBorder: '#D1D1D1',

    oobeWhite: '#ffffff',
    oobePrimary: '#0078d4',
    oobeDarkAlt: '#106EBE',
    oobeThemeLighter: '#DEECF9',

    cardStyleWhite: '#ffffff',
    freeBusyAwayColor: '#B4009E',

    // https://www.figma.com/file/c5TF4jwqrpms2PSQH3EXtFTc/Fluent-Color?node-id=3273%3A435
    messageWebWarning: '#fff4ce',
    messageMobileWarningPrimary: '#ffd335',
    messageMobileWarningShade20: '#c2a129',
    messageMobileWarningShade30: '#8f761e',
    messageMobileWarningTint30: '#fff2c3',
    messageMobileWarningTint40: '#fff8df',
    communicationBlueWebPrimary: '#0078d4',
    messageMobileDangerPrimary: '#d92c2c',
    messageMobileDangerShade20: '#a52121',
    messageMobileDangerTint30: '#f4b9b9',
    messageMobileDangerTint40: '#f9d9d9',
    messageMobileSuccessPrimary: '#13a10e',
    presenceAway: '#ffaa44',
    presenceDoNotDisturb: '#c50f1f',
    presenceOnline: '#6bb700',
    presenceInvisible: '#8a8886',
    presenceOOF: '#b4009e',

    // Addison component picker button gradient
    addisonComponentButtonGradientStart: '#f7f0ff',
    addisonComponentButtonGradientEnd: '#e9dfff',

    storageCritialStateColor: '#9F282B',
    storageCritialStateHoverColor: '#751D1F',
    storageNearingStateColor: '#835B00',
    storageNearingStateHoverColor: '#463100',
    storageUsageBarBorder: '#ffffff',

    bizBarRed: '#FDE7E9',
    msqOneDrive: '#637CEF',
    msqTeams: '#9A44FC',
    msqOutlook: '#E3008C',
    msqWindows: '#D64400',
    msqEdge: '#1BA756',
    msqLoop: '#2AA0A4',
    msqDesigner: '#9373C0',

    //Places Explore colors
    placesPrimary: '#583DFF',

    // Places linear gradient section
    placesLightBlueSolidColor: '#28DFF8',
    placesNavyBlueSolidColor: '#209DF5',

    // Places theme colors
    placesBackgroundColorPink: 'rgba(255,116,243,.2)',
    placesBackgroundColorPurple: 'rgba(205,112,255,.2)',
    placesBackgroundColorLightBlue: 'rgba(39,213,248,.2)',
    placesBackgroundColorNavy: 'rgba(9,99,121,.2)',

    // Places theme colors used in workspaces https://www.figma.com/file/3IJc3nTEB1EeHUKZjReFD8/Reserve-a-desk
    // referred to https://react.fluentui.dev/?path=/docs/theme-color--page for cross theme tokens
    placesBluePrimary: '#0078d4',
    placesBlueTint40: '#a9d3f2',
    placesLightBackground3: '#f5f5f5',
    placesLightBlueBackground: '#ebf3fc',
    placesNeutralGrey92: '#ebebeb',

    colorBrandBackgroundInvertedSelected: '#cfe4fa',
    colorBrandBackgroundInvertedHover: '#ebf3fc',
    colorPaletteCornflowerBackground2: '#2C3C85',

    placesBlueGradient1: '#2A4FFE',
    placesBlueGradient2: '#4D30FC',
    placesBlueBackground1: '#E1E6FC',
    placesBlueForeground1: '#637CEF',
    placesBlueBorder1: '#2510BC',
    placesCornflowerForeground1: '#F7F9FE',
    placesCornflowerForeground2: '#4F5BED',

    placesRemoteUnspecifiedBackground1: '#EBEBEB',
    placesRemoteUnspecifiedForeground1: '#424242',
    placesOOFBackground1: '#F5DAF2',
    placesOOFForeground1: '#C239B3',

    placesRemote1: '#CDEDF4',
    placesRemoteBorder1: '#005669',

    // Colors for floorMap in Places https://www.figma.com/file/h7UJrieBtHnGAimIh8BjVu/Modernize-improvements
    neutralBackground6: '#E6E6E6',
    lilacBackground2: '#E6BFED',
    neutralForeground4: '#707070',
    neutralBackground2Pressed: '#DBDBDB',

    sharedLavenderTint10: '#8172EB',

    sharedCornflowerTint20: '#778DF1',
    sharedCornflowerTint30: '#93A4F4',
    sharedCornflowerTint50: '#E1E6FC',
    sharedCornflowerTint60: '#F7F9FE',
    sharedCornFlowerShade20: '#3C51B4',

    placesPurpleOOFBackground1: '#F5DAF2',
    placesPurpleOOFForeground1: '#AF33A1',

    placesPurpleGradient1: '#6A11CB',

    boxShadowPrimary: 'rgba(0, 0, 0, 0.14)',
    boxShadowSecondary: 'rgba(0, 0, 0, 0.12)',

    places70: '#5432E6',
    places80: '#5B41FF',
    places80Pressed: '#4F28C1',
    places160: '#E4E2FF',

    paneLighterBackground: '#FAF9F8',

    placesExploreGradient1: '#EFF5FF',
    placesExploreGradient2: '#E6E4FF',

    copilotGradientStart: '#BA6DF1',
    copilotGradientEnd: '#4B9DF5',

    yellow: '#ffb900',
    orange: '#d83b01',
    red: '#e81123',
    blue: '#0078d4',
    green: '107c10',

    // Colors for neighborhoods in Places
    sharedLightGreenTint50: '#CEF0CD',
    sharedDarkGreenTint30: '#4DA64D',

    // Colors for home page in Bookings
    bookingsTeal10: '#EBF8FB',
    bookingsTeal20: '#BCE5F0',
    bookingsTeal50: '#2F9CB7',
    bookingsTeal80: '#006680',
    bookingsAccentGreen10: '#F1FAF1',
    bookingsAccentGreen20: '#9FD89F',
    bookingsForegroundTeal60: '#007F9C',
    bookingsForegroundTeal80: '#007F9C',
    bookingsNeutralForeground1: '#242424',
    bookingsNeutralForeground2: '#424242',
    bookingsNeutralForeground4: '#707070',
    bookingsRedForeground1: '#A80000',
    bookingsNeutralStroke1: '#D1D1D1',
    bookingsNeutralBackground1: '#FFFFFF',
    bookingsGreenConsistent: '#107C10',
    bookingsGrayConsistent: '#424242',
    bookingsGrayBackground1: '#757575',
    bookingsGrayLightBackground1: '#F3F2F1',
    bookingsSilverSandBorder1: '#C8C6C4',
    bookingsDefaultRed: '#a80000',
    bookingsWarning1: '#FFF9F5',
    bookingsWarning2: '#FDCFB4',
    bookingsWarning3: '#8A3707',
    bookingsDanger1: '#FDF6F6',
    bookingsDanger2: '#F1BBBC',
    bookingsDanger3: '#A80000',
    bookingsBlueForeground: '#007F9C',

    // Colors for floor map in Places. Figma: https://www.figma.com/design/Wgido27TpI2QHr8ckeNl3Z/Places-Map-%26-Finder-Master
    mapBackgroundFloor: '#FBFBFD',
    mapBackgroundFacilities: '#FFFFFF',
    mapBackgroundWall: '#FFFFFF', // TODO Not used yet
    mapBackgroundArea: '#EBEDF6',
    mapBackgroundUnavailable: '#C3C1D9',
    mapBackgroundFree: '#B5B0FF', // not yet in use
    mapStrokeFloorAndWall: '#99A0C3',
    pinAvailableTextFill: '#311555',
    pinUnavailableTextFill: '#292929',

    placesBlueTint50: '#D0E7F8',
    placesLightBlueShade20: '#2C72A8',
    placesCyanTint50: '#CDEDF4',
    placesCyanShade10: '#008AA9',
    placesCyanShade20: '#00748F',
    placesNeutralForeground2: '#616161',
    placesSeaformShade20: '#009B51',
    placesSeaformShade30: '#00723B',
    placesLightGreenShade20: '#0E7A0B',

    // Eventify/Workplace Events specific colors
    eventifySurveyStatusIncompleteColor: '#806a1a',
    eventifySurveyStatusIncompleteBackground: '#fff2c3',
    eventifyClosedSurveyBannerBorder: '#ffd94e',
    eventifyClosedSurveyBannerColor: '#8f761e',
    eventifySurveyListItemBorder: '#d9d9d9',
    eventifySurveyStatusCompleteBackground: '#caead8',
    eventifySurveyStatusCompleteColor: '#37a660',
    eventifyEventPreviewColor: '#7a6519',
    eventifyEventCancelledBackground: '#f9d9d9',
    eventifyEventCancelledBorder: '#e87979',
    eventifyEventCancelledColor: '#c32727',

    // Gradient colors for the app background image
    appBackgroundGradientColor: 'rgba(255,255,255,.7)',
    copilotAppBackgroundGradientColor: 'rgba(0,0,0,.5)',

    //Colors for Copilot Flair on Calendar Surface
    copilotFlairColor1: '#464feb',
    copilotFlairColor2: '#47cffa',
    copilotFlairColor3: '#b47cf8',
    copilotFlairColor1Transparent: 'rgba(70,79,235,0)',
    copilotFlairColor2Transparent: 'rgba(71,207,250,0)',
    copilotFlairColor3Transparent: 'rgba(180,124,248,0)',

    // Outlook custom colors for Fluent v9 components.
    // Figma: https://www.figma.com/design/DYS2ZPisghfHhw6N7u0Vtj/Outlook-web-variables?node-id=8609-2615&t=3gLSNnfOLDXbJ62W-1
    // Neutral colors
    customNeutralStrokeTransparent2: 'transparent',
    customNeutralStrokeOnBackground1: '#949494',
    customNeutralStrokeOnBackground1Hover: '#8A8A8A',
    customNeutralStrokeOnBackground1Pressed: '#757575',
    customNeutralBackground2Hover70: 'rgba(240,240,240,.7)',
    // Stable colors
    customStableForegroundLink: '#115EA3',
    customStableForegroundLinkHover: '#0F548C',
    customStableForegroundLinkPressed: '#0C3B5E',
    customStableForegroundLinkSelected: '#115EA3',

    // Calendar Work Hours border
    calendarWorkHoursBorder: '#949494',

    // Copilot theme creator experience colors
    // Copilot mesh gradient colors
    copilotThemeMeshColor1: '#b10606',
    copilotThemeMeshColor2: '#ffae52',
    copilotThemeMeshColor3: '#f05689',
    copilotThemeMeshColor4: '#e8a702',
    copilotThemeMeshColor5: '#51b36f',
    copilotThemeMeshColor6: '#0d91de',
    // UI colors
    copilotThemeWhite: '#ffffff',
    copilotThemeWhiteTranslucent: 'rgba(0, 0, 0, 0.25)',

    // Copilot Event Importance
    copilotEventImportanceLowPrimary: '#107C10',
    copilotEventImportanceLowLight: '#DFF6DD',
    copilotEventImportanceMediumPrimary: '#FFD335',
    copilotEventImportanceMediumLight: '#FFF8DF',
    copilotEventImportanceHighPrimary: '#A80000',
    copilotEventImportanceHighLight: '#FDE7E9',

    // Big Hover Button Colors
    archiveBigHoverForeground: '#107c10',
    archiveBigHoverBackground: '#dff6de',
    deleteBigHoverForeground: '#e81123',
    deleteBigHoverBackground: '#fef2f3',
};

export const BASE_LIGHT_THEME_PALETTE = {
    ...LIGHT_THEME_NEUTRALS,
    themeDarker: '#0C3B5E', // 40
    themeDark: '#0F548C', // 60
    themeDarkAlt: '#115EA3', // 70
    themePrimary: '#0F6CBD', // 80
    themeSecondary: '#2B88D8', // 100-ish
    themeTertiary: '#62ABF5', // Between 120 and 130
    themeLight: '#B4D6FA', // 140
    themeLighter: '#CFE4FA', // 150
    themeLighterAlt: '#EBF3FC', // 160
    themeSecondarySurfaceSelected: '#CFE4FA',
    headerBackground: '#0F6CBD',
    headerBackgroundSearch: '#0F6CBD',
    headerBrandText: '#FFFFFF',
    headerTextIcons: '#FFFFFF',
    headerSearchBoxBackground: 'rgba(255,255,255,.7)',
    headerSearchBoxIcon: '#0C3B5E',
    headerSearchPlaceholderText: '#0C3B5E',
    headerSearchButtonBackground: '#0F6CBD',
    headerSearchButtonBackgroundHover: '#0C3B5E',
    headerSearchButtonIcon: '#FFFFFF',
    headerSearchFilters: '#0F6CBD',
    headerSearchFiltersHover: '#0C3B5E',
    headerButtonsBackground: '#0F6CBD',
    headerButtonsBackgroundHover: '#0C3B5E',
    headerButtonsBackgroundSearch: '#0F6CBD',
    headerButtonsBackgroundSearchHover: '#0C3B5E',
    headerBadgeBackground: '#0C3B5E',
    headerBadgeText: '#FFFFFF',
    headerSearchIcon: '#FFFFFF',
    searchBoxBackground: 'rgba(255,255,255,.7)',
};
