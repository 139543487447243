import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isServiceRequestSupportedForMailbox } from 'owa-service/lib/utils/isServiceRequestSupportedForMailbox';
import isBusiness from 'owa-session-store/lib/utils/isBusiness';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 *
 * This capability is being used for show/hide the shared with me folders
 */
export const sharedCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true; // by default
    },
    isSupported: (mailboxInfo?: MailboxInfo) => {
        if (
            isSharedCoprincipalAccountEnabled() &&
            !!mailboxInfo &&
            isMailboxSharedOrDelegateUserMailbox(mailboxInfo)
        ) {
            // Coprincipal shared mailboxes do not support shared folders for the shared mailbox itself.
            return false;
        }

        if (isBusiness(mailboxInfo)) {
            // Business accounts that support service request can have
            // shared mailboxes
            return isServiceRequestSupportedForMailbox(mailboxInfo);
        }

        return false;
    },
};
