import { type LicensingMailboxInfo } from 'owa-account-source-list-types';
import { getJsonRequestHeader } from 'owa-service/lib/ServiceRequestUtils';
import type GetMailboxResponse from 'owa-service/lib/contract/GetMailboxResponse';
import getMailboxRequestOptions from 'owa-service/lib/getMailboxRequestOptions';
import getMailboxByIdentityOperation from 'owa-service/lib/operation/getMailboxByIdentityOperation';

export async function hasMailboxProvisioning(mailboxInfo: LicensingMailboxInfo) {
    return getMailboxByIdentityOperation(
        {
            Header: getJsonRequestHeader(),
            Identity: {
                RawIdentity: mailboxInfo.mailboxSmtpAddress,
            },
        },
        getMailboxRequestOptions(mailboxInfo)
    )
        .then((responseMessage: GetMailboxResponse) => {
            if (responseMessage.WasSuccessful) {
                return false;
            } else {
                return true;
            }
        })
        .catch(_error => {
            return true;
        });
}
