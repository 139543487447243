import { getWindowData } from 'owa-window-data';
// we will cache the width of the page to avoid layouts
let cachedBrowserWidth: number | undefined;
export function getBrowserWidth(skipCache?: boolean): number {
    /**
     * The reason we are getting min of widths here is because we have seen cases
     * where the window.innerWidth is not ready (and the value is bigger than the actual window width)
     * by the time we want to calculate the available width.
     * So far documentElement.clientWidth is showing correct value, but we are not switching to relying totally
     * on it as this is the way the browser width is being calculated.
     */
    const windowData = getWindowData();
    const doc = windowData.document;
    if (!cachedBrowserWidth || skipCache) {
        /* eslint-disable-next-line no-restricted-properties, owa-custom-rules/forbid-properties-access-outside-specific-function -- (https://aka.ms/OWALintWiki)
         * This is a helper function that will cache the value to reduce the number of forced re-layouts
         *	> 'clientWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please use a resize observer instead. (https://aka.ms/OWALintWiki)
         * This is baseline exception, if you edit this file you need to fix this exception.
         *	> Property 'clientWidth' must be accessed within 'wrapForcedLayout' imported from 'owa-performance'. */
        const documentElementClientWidth = doc?.documentElement?.clientWidth;
        /* eslint-disable-next-line no-restricted-properties, owa-custom-rules/forbid-properties-access-outside-specific-function -- (https://aka.ms/OWALintWiki)
         * This is a helper function that will cache the value to reduce the number of forced re-layouts
         *	> 'innerWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. (https://aka.ms/OWALintWiki)
         * This is baseline exception, if you edit this file you need to fix this exception.
         *	> Property 'innerWidth' must be accessed within 'wrapForcedLayout' imported from 'owa-performance'. */
        const windowInnerWidth = windowData.innerWidth;
        cachedBrowserWidth =
            windowInnerWidth && documentElementClientWidth
                ? Math.min(windowInnerWidth, documentElementClientWidth)
                : /* eslint-disable-next-line no-restricted-properties, owa-custom-rules/forbid-properties-access-outside-specific-function -- (https://aka.ms/OWALintWiki)
                   * This is a helper function that will cache the value to reduce the number of forced re-layouts
                   *	> 'clientWidth' is restricted from being used. This property can cause performance problems by causing re-layouts. Please use a resize observer instead. (https://aka.ms/OWALintWiki)
                   * This is baseline exception, if you edit this file you need to fix this exception.
                   *	> Property 'clientWidth' must be accessed within 'wrapForcedLayout' imported from 'owa-performance'. */
                  windowInnerWidth || documentElementClientWidth || doc?.body?.clientWidth;
    }
    return cachedBrowserWidth || 0;
}
