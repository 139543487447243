import { observer } from 'owa-mobx-react';
import FocusedInboxHeader from './FocusedInboxHeader';
import MailListHeaderSecondRowTextContent from './MailListHeaderSecondRowTextContent';
import { getEffectiveFolderDisplayName, isPublicFolder, isFolderInMailboxType } from 'owa-folders';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import isAttachmentsSearchFolder from 'owa-mail-store/lib/utils/isAttachmentsSearchFolder';
import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import React from 'react';
import { isFavoritingInProgress } from 'owa-mail-favorites-store';
import { lazyToggleFavoriteFolder } from 'owa-mail-folder-forest-actions';
import { lazyRemovePublicFolderFromFavorites } from 'owa-publicfolder-picker';
import {
    isFolderInFavorites,
    isPublicFolderInFavorites,
    isLocalFolderFavoriteSupported,
} from 'owa-favorites';
import { getMailboxInfoFromTableViewId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';
import type { UserSettingsStyleSelector } from '../../utils/getStyleSelectorAsPerUserSettings';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

export interface MailListFolderHeaderSecondRowContentProps {
    tableViewId: string;
    containerCssClass: string;
    mailListHeaderStylesAsPerUserSettings?: UserSettingsStyleSelector;
}

export default observer(function MailListFolderHeaderSecondRowContent(
    props: MailListFolderHeaderSecondRowContentProps
) {
    const selectedFolder = getSelectedFolder();
    const isInboxSelected = folderIdToName(selectedFolder?.id ?? '') === 'inbox';
    const mailboxInfo = getMailboxInfoFromTableViewId(props.tableViewId);
    if (isInboxSelected && isFocusedInboxEnabled(mailboxInfo)) {
        return (
            <FocusedInboxHeader
                tableViewId={props.tableViewId}
                mailListHeaderStylesAsPerUserSettings={props.mailListHeaderStylesAsPerUserSettings}
            />
        );
    }
    if (!selectedFolder) {
        /**
         * This function gets called when when user switches from a favorite persona to a favorite category. At that time, the selected node
         * is set to category node while the table is still persona table. Thus we are expecting the folder to be undefined as the category folder
         * is not saved in folder store.
         */
        return null;
    }
    const selectedFolderId = selectedFolder?.id;

    let shouldShowFavoriteToggle = !isAttachmentsSearchFolder(selectedFolderId);

    if (!isLocalFolderFavoriteSupported()) {
        // We don't currently support adding shared or archive folders to favorites if the local folder favorites feature is not enabled
        const isSharedOrArchiveFolder = !!(
            selectedFolderId &&
            (isFolderInMailboxType(selectedFolderId, 'SharedMailbox') ||
                isFolderInMailboxType(selectedFolderId, 'ArchiveMailbox') ||
                (selectedFolder &&
                    isSharedCoprincipalAccountEnabled() &&
                    isMailboxSharedOrDelegateUserMailbox(selectedFolder.mailboxInfo)))
        );
        shouldShowFavoriteToggle &&= !isSharedOrArchiveFolder;
    }

    const isFolderInFavorite =
        !!selectedFolderId &&
        (isPublicFolder(selectedFolderId)
            ? isPublicFolderInFavorites(selectedFolderId)
            : isFolderInFavorites(selectedFolderId));

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const toggleFavoriteState = async (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        if (!selectedFolder || !selectedFolderId) {
            return;
        }
        if (isFavoritingInProgress(selectedFolderId)) {
            // Do nothing: favoriting is in progress
            return;
        }
        if (isPublicFolder(selectedFolderId)) {
            const removePublicFolderFromFavorites =
                await lazyRemovePublicFolderFromFavorites.import();
            removePublicFolderFromFavorites(selectedFolder);
        } else {
            lazyToggleFavoriteFolder.importAndExecute(
                selectedFolderId,
                !!isFolderInFavorite,
                'FolderHeader'
            );
        }
    };

    return (
        <MailListHeaderSecondRowTextContent
            text={getEffectiveFolderDisplayName(selectedFolder)}
            containerCssClass={props.containerCssClass}
            showFavoriteToggle={shouldShowFavoriteToggle}
            onFavoriteToggleClick={toggleFavoriteState}
            isInFavorites={isFolderInFavorite}
            styleSelectorAsPerUserSettings={props.mailListHeaderStylesAsPerUserSettings}
            mailboxInfo={mailboxInfo}
        />
    );
},
'MailListFolderHeaderSecondRowContent');
