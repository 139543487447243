import * as SxSEventNames from './utils/eventNames';
import { LazyImport, LazyAction, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SxSStore" */ './lazyIndex')
);

export const lazyCloseComposeInSxS = new LazyAction(lazyModule, m => m.closeComposeInSxS);
export const lazyCreateImageGalleryFromAttachmentViewStateIds = new LazyAction(
    lazyModule,
    m => m.createImageGalleryFromAttachmentViewStateIds
);
export const lazyForceCloseSxS = new LazyAction(lazyModule, m => m.forceCloseSxS);
export const lazyLogUsageWithSxSCustomData = new LazyAction(
    lazyModule,
    m => m.logUsageWithSxSCustomData
);
export const lazyRemoveSxSViewFromDOM = new LazyAction(lazyModule, m => m.removeSxSViewFromDOM);
export const lazyOnBeforeImageGalleryItemSelected = new LazyAction(
    lazyModule,
    m => m.onBeforeImageGalleryItemSelected
);
export const lazyOnChangeByImageGallery = new LazyAction(lazyModule, m => m.onChangeByImageGallery);
export const lazyOnPendingNavigation = new LazyAction(lazyModule, m => m.onPendingNavigation);
export const lazyOnPreviewOperationFinished = new LazyAction(
    lazyModule,
    m => m.onPreviewOperationFinished
);
export const lazyOnPreviewOperationStart = new LazyAction(
    lazyModule,
    m => m.onPreviewOperationStart
);
export const lazyOpenComposeInSxS = new LazyAction(lazyModule, m => m.openComposeInSxS);
export const lazyReloadTextPreview = new LazyAction(lazyModule, m => m.reloadTextPreview);
export const lazyShowSxS = new LazyAction(lazyModule, m => m.showSxS);
export const lazyTryCloseSxS = new LazyAction(lazyModule, m => m.tryCloseSxS);
export const lazySaveToCloudFromSxS = new LazyAction(lazyModule, m => m.saveToCloudFromSxS);
export const lazySaveToCloud = new LazyAction(lazyModule, m => m.saveToCloud);
export const lazyOpenSxSInANewWindow = new LazyAction(lazyModule, m => m.openSxSInANewWindow);
export const lazyOnShowCustomPreview = new LazyAction(lazyModule, m => m.onShowCustomPreview);
export const lazyTryGetAttachmentFullViewStateByIdInReadingPane = new LazyAction(
    lazyModule,
    m => m.tryGetAttachmentFullViewStateByIdInReadingPane
);
export const lazyRepeatTracker = new LazyImport(lazyModule, m => m.repeatTracker);
export const lazyWaitForPreviewLoadOrThrowError = new LazyAction(
    lazyModule,
    m => m.waitForPreviewLoadOrThrowError
);

export {
    cloneSxSStore,
    showSxS,
    closeSxS,
    onSxSReadingPaneChange,
    onBeforeSxSReadingPaneChange,
    onComposeStateChange,
    onCustomizationChange,
} from './actions/publicActions';

export { default as getOrCreateSxSStoreData } from './store/Store';
export { SxSEventNames };
export { default as getActiveSxSId } from './utils/getActiveSxSId';
export {
    default as isSxSDisplayed,
    isSxSDisplayedForAttachment,
    isSxSDisplayedWithCompose,
} from './utils/isSxSDisplayed';
export { default as isAnySxSDisplayedInMainWindow } from './utils/isAnySxSDisplayed';
export { default as findSxSIdByFullComposeId } from './utils/findSxSIdByFullComposeId';
export { SxSCustomization, SXSID_MAIN_WINDOW, SXSV2_CONTAINER_ID } from './store/schema/SxSStore';
export type { SxSStoreData } from './store/schema/SxSStore';
export { SxSReadingPaneMode } from './store/schema/SxSReadingPaneState';
export type {
    SxSConversationReadingPaneState,
    SxSItemReadingPaneState,
} from './store/schema/SxSReadingPaneState';
export type {
    default as SxSExtendedViewState,
    TextPreviewCustomEncoding,
} from './store/schema/SxSExtendedViewState';
export { default as AttachmentPreviewMode } from './store/schema/AttachmentPreviewMode';

/* eslint-disable-next-line owa-custom-rules/forbid-package-reexport  -- (https://aka.ms/OWALintWiki)
 * Baseline comment; do not copy-paste. Rexporting from a separate package complicates the package graph.
 *	> Do not re-export from a separate package */
export { AttachmentSelectionSource } from 'owa-attachment-data';
