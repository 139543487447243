import { isGroupTableQuery } from 'owa-group-utils';
import type { TableView } from 'owa-mail-list-store';
import { getFolderIdFromTableView } from 'owa-mail-folder-common';
import { getFolderTable } from 'owa-folders';
import type { ClientItem } from 'owa-mail-store';
import type { MailboxInfo } from 'owa-client-types';
import { default as isMailboxPackageFlightEnabled } from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';

export type FolderInfo = {
    folderId: string | null;
    mailboxInfo: MailboxInfo;
};

export default function getFolderInfoForRetentionPolicy(tableView: TableView): FolderInfo | null {
    if (isMailboxPackageFlightEnabled()) {
        if (tableView && isGroupTableQuery(tableView.tableQuery)) {
            const groupMailboxInfo = getMailboxInfo(tableView);
            return {
                folderId: null,
                mailboxInfo: groupMailboxInfo,
            };
        } else {
            const tableViewFolderId = getFolderIdFromTableView(tableView);
            const folder = tableViewFolderId && getFolderTable().get(tableViewFolderId);
            if (!folder) {
                return null;
            }
            return {
                folderId: folder.distinguishedFolderType || folder.id,
                mailboxInfo: folder.mailboxInfo,
            };
        }
    } else {
        let folderId: string | null = null;
        let mailboxType;
        let userIdentity = '';
        let mailboxSmtpAddress = '';
        if (tableView && isGroupTableQuery(tableView.tableQuery)) {
            // No folder id needs to be set for groups
            mailboxType = 'GroupMailbox';
        } else {
            const tableViewFolderId = getFolderIdFromTableView(tableView);
            const folder = tableViewFolderId && getFolderTable().get(tableViewFolderId);
            if (!folder) {
                return null;
            }

            folderId = folder.distinguishedFolderType || folder.id;
            mailboxType = folder.mailboxInfo.type;
            userIdentity = folder.mailboxInfo.userIdentity;
            mailboxSmtpAddress = folder.mailboxInfo.mailboxSmtpAddress;
        }

        return {
            folderId,
            /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
             * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
             *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
            mailboxInfo: {
                type: mailboxType,
                userIdentity,
                mailboxSmtpAddress,
            },
        };
    }
}

export function getFolderInfoFromItemForRetentionPolicy(item: ClientItem): FolderInfo | null {
    let folderId: string | null = null;
    let userIdentity = '';
    let mailboxSmtpAddress = '';
    if (item.MailboxInfo?.type !== 'GroupMailbox') {
        const folder = item.ParentFolderId;
        if (!folder) {
            return null;
        }
        folderId = folder.Id;
        userIdentity = item.MailboxInfo?.userIdentity;
        mailboxSmtpAddress = item.MailboxInfo?.mailboxSmtpAddress;
    }

    return {
        folderId,
        /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
         * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
         *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
        mailboxInfo: {
            type: item.MailboxInfo?.type,
            userIdentity,
            mailboxSmtpAddress,
        },
    };
}
