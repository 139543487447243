import type {
    AttachmentPickers,
    ComposeRibbonControlDefProps,
    ReadOnlyRibbonControlDefProps,
} from 'owa-mail-ribbon-utils';
import type {
    RibbonTabDefinitionBase,
    RibbonTabDefinitionSet,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { propsBaseGlobal } from '../utils/ribbonDefinitionProps';
import type { ComposeViewState } from 'owa-mail-compose-store/lib/store/schema/ComposeViewState';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { getPlaceholderHelpTab } from 'owa-ribbon-help/lib/utils/getPlaceholderHelpTab';
import getSLRHomeTab from './slr-home-tab/getSLRHomeTab';
import getSLRNotesTab from './slr-notes-tab/getSLRNotesTab';
import getSLRViewTab from './slr-view-tab/getSLRViewTab';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isNotesScenario } from 'owa-mail-ribbon-utils/lib/isNotesScenario';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { lazyGetSLRHelpTab } from 'owa-ribbon-help';
import type { MailboxInfo } from 'owa-client-types';
import { lazyGetSLRComposeTabs } from '../../lazyComposeFunctions';

let composePropsGlobal: ComposeRibbonControlDefProps | undefined;
let prevAttachmentPickers: AttachmentPickers | undefined;
let prevViewState: ComposeViewState | undefined;

// Clarification: "readOnly" does not mean that this property never has its properties changed.
// "readOnly" in this context means the mail state, so "not compose".
// Importantly, mailboxInfo can change and we track that change.
let readOnlyPropsGlobal: ReadOnlyRibbonControlDefProps = {
    isPopout: false,
    mailboxInfo: undefined,
    itemId: undefined,
    projectionTabId: undefined,
};

export const getMailRibbonDefinitionsSLR = owaComputedFn(function getMailRibbonDefinitionsSLR(
    attachmentPickers: AttachmentPickers
): RibbonTabDefinitionSet {
    const tabs: RibbonTabDefinitionBase[] = [];

    if (isFeatureEnabled('mon-ribbon-customization-multiAccount')) {
        // Check if we need to update readOnlyProps for Mailbox Info
        const mailboxInfo: MailboxInfo = getRibbonMailboxInfo(
            undefined /* RibbonControlDefinitionProps */
        );
        if (mailboxInfo !== readOnlyPropsGlobal.mailboxInfo) {
            readOnlyPropsGlobal = {
                ...readOnlyPropsGlobal,
                mailboxInfo,
            };
        }
    }

    tabs.push(getSLRHomeTab(readOnlyPropsGlobal), getSLRViewTab(readOnlyPropsGlobal));

    const getSLRHelpTab = lazyGetSLRHelpTab.tryImportForRender();
    tabs.push(getSLRHelpTab ? getSLRHelpTab() : getPlaceholderHelpTab());

    const viewState: ComposeViewState | undefined = getComposeViewState();
    if (viewState?.isComposeRendered) {
        // Check if we need to update compose props
        if (
            !composePropsGlobal ||
            !prevAttachmentPickers ||
            prevAttachmentPickers !== attachmentPickers ||
            !prevViewState ||
            prevViewState !== viewState
        ) {
            prevAttachmentPickers = attachmentPickers;
            prevViewState = viewState;
            composePropsGlobal = {
                ...propsBaseGlobal,
                composeViewState: viewState,
                attachmentPickers,
                mailboxInfo: viewState.mailboxInfo,
            };
        }

        const getSLRComposeTabs = lazyGetSLRComposeTabs.tryImportForRender();
        if (getSLRComposeTabs) {
            tabs.push(...getSLRComposeTabs(composePropsGlobal));
        }
    }

    if (isNotesScenario()) {
        tabs.push(getSLRNotesTab(readOnlyPropsGlobal));
    }

    return {
        tabs,
    };
});
