import favoritesStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import { FolderTreeLoadStateEnum } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';
import { setLoadingStateForFavorites } from './setLoadingStateForFavorites';

export const setFavoritesLoaded = (accountKey: string) => {
    if (isFeatureEnabled('tri-favorites-roaming')) {
        setLoadingStateForFavorites(accountKey, FolderTreeLoadStateEnum.Loaded);
    } else {
        setFavoritesLoadedV1();
    }
};

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const setFavoritesLoadedV1 = mutatorAction('setFavoritesLoadedV1', () => {
    // Favorites V1 state
    favoritesStore.favoritesLoaded = true;
});
