import { observer } from 'owa-mobx-react';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { selectFolder, lazyToggleFavoriteFolder } from 'owa-mail-folder-forest-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import isArchiveFolder from 'owa-mail-folder-store/lib/utils/isArchiveFolder';
import type KeyboardCharCodes from 'owa-hotkeys/lib/utils/keyboardCharCodes';
import type { MailFolder, FolderForestTreeType } from 'owa-graph-schema';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { isFolderInFavorites } from 'owa-favorites';
import { logUsage } from 'owa-analytics';
import type { ChevronProps } from 'owa-tree-node/lib/components/TreeNode';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React, { useCallback } from 'react';
import { default as viewStateStore } from 'owa-mail-folder-store/lib/store/store';

import { type FolderForestNodeType } from 'owa-favorites-types';
import type { ITouchHandlerParams } from 'owa-touch-handler';
import { touchHandler } from 'owa-touch-handler';
import { BulkActionSpinner, isBulkActionRunning } from 'owa-bulk-action-store';
import { UnreadReadCountBadge } from 'owa-unreadread-count-badge';
import { isFeatureEnabled } from 'owa-feature-flags';
import { buildUnreadTotalCountTooltip } from 'owa-folders';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID, SourceMap } from 'owa-folders-constants';
import toggleFolderNodeExpansion from 'owa-mail-folder-store/lib/actions/toggleFolderNodeExpansion';
import { Spinner } from '@fluentui/react-components';
import type { FluentIcon } from '@fluentui/react-icons';

// Folders that should show total count instead of unread count
const FOLDERS_TO_SHOW_TOTALCOUNT: DistinguishedFolderIdName[] = [
    'drafts',
    'junkemail',
    'outbox',
    'notes',
    'scheduled',
];

export interface FolderNodeProps extends React.HTMLProps<HTMLDivElement> {
    displayName: string;
    folderId: string;
    treeType: FolderForestTreeType;
    distinguishedFolderId: string;
    totalCount: number;
    unreadCount: number;

    // Optional parameters
    chevronProps?: ChevronProps;
    customIconComponent?: FluentIcon;
    depth?: number; // root is 0, every sub node increase this number by 1.
    isBeingDragged?: boolean;
    isDroppedOver?: boolean;
    onContextMenu?: (evt: React.MouseEvent<any>, folder?: MailFolder) => void;
    showHoverStateOnDroppedOver?: boolean;
    shouldHideToggleFavorite?: boolean;
    isPublicFolder?: boolean;
    isLoading?: boolean;
    deleteFolder?: () => void;
    ellipsesOnHover?: boolean;
    customIconColor?: string;
    tooltipNameContent?: string;
}

const FolderNode: React.FC<FolderNodeProps> = ({
    displayName,
    folderId,
    treeType,
    distinguishedFolderId,
    totalCount,
    unreadCount,
    chevronProps,
    customIconComponent,
    depth,
    isBeingDragged,
    isDroppedOver,
    onContextMenu,
    showHoverStateOnDroppedOver,
    isPublicFolder,
    isLoading,
    deleteFolder,
    ellipsesOnHover,
    customIconColor,
    tooltipNameContent,
}) => {
    const isSelected = useComputedValue((): boolean => {
        const selectedNode = getSelectedNode();
        return treeType == selectedNode.treeType && folderId == selectedNode.id;
    }, [treeType, folderId]);

    const isWithContextMenuOpen = useComputedValue((): boolean => {
        return folderId == viewStateStore.withContextMenuFolderId;
    }, [folderId]);

    const onTouchHandler = useComputedValue(() => {
        const touchHandlerParams: ITouchHandlerParams = {
            onLongPress: onContextMenu,
        };
        return touchHandler(touchHandlerParams);
    }, [onContextMenu]);

    const isSearchFolderRoot = useComputedValue((): boolean => {
        return distinguishedFolderId == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID;
    }, [distinguishedFolderId]);

    const renderUnreadOrTotalCount = useCallback((): JSX.Element | null => {
        const shouldDisplayTotalCount =
            FOLDERS_TO_SHOW_TOTALCOUNT.indexOf(
                distinguishedFolderId as DistinguishedFolderIdName
            ) >= 0 && totalCount > 0;
        const count = shouldDisplayTotalCount ? totalCount : unreadCount;

        if (distinguishedFolderId == 'outbox') {
            logUsage('MailComposeOfflineAction', {
                step: 'renderOutboxFolderNode',
                totalCount,
                unreadCount,
                shouldDisplayTotalCount,
            });
        }

        return count ? (
            <UnreadReadCountBadge
                count={count}
                shouldDisplayTotalCount={shouldDisplayTotalCount}
                isSelected={isSelected}
            />
        ) : null;
    }, [distinguishedFolderId, totalCount, unreadCount, isSelected]);

    const renderRightCharm = useCallback((): JSX.Element | null => {
        if (isLoading) {
            return <Spinner size="extra-tiny" />;
        }

        if (isBulkActionRunning(folderId)) {
            return <BulkActionSpinner folderId={folderId} folderName={displayName} />;
        }

        if (!isArchiveFolder(folderId) || isFeatureEnabled('tri-showArchiveUnreadCount')) {
            return renderUnreadOrTotalCount();
        }

        return null;
    }, [folderId, isLoading, displayName, renderUnreadOrTotalCount]);

    const onClick = useCallback(
        (evt: React.MouseEvent<unknown>) => {
            evt.stopPropagation();
            // The Search Folders node toggles its subtree expansion state instead of selecting the folder
            if (isSearchFolderRoot) {
                // If the user has no search folders, pull up the create search folder dialog
                if (!chevronProps) {
                    onContextMenu?.(evt);
                }
                toggleFolderNodeExpansion(folderId);
            } else {
                /**
                 * selectFolder, even if it's the same folder users are already selected on
                 * the action has several side effects that users are counting on, including that
                 * if users are in immersive reader view, the reading pane is closed
                 */
                selectFolder(
                    folderId,
                    treeType,
                    'FolderNodeClick' /* actionSource */,
                    isPublicFolder ? 5 : 0,
                    undefined,
                    evt.nativeEvent.timeStamp
                );
            }
        },
        [isSearchFolderRoot, chevronProps, onContextMenu, folderId, treeType, isPublicFolder]
    );

    const onKeyDown = useCallback(
        (evt: React.KeyboardEvent<unknown>) => {
            switch (evt.keyCode) {
                case 13:
                case 32:
                    evt.stopPropagation();

                    // explicity cancelling event so click won't fire after this in case when folder pane is opened as overlay.
                    // only stopPropagation is not enough
                    evt.preventDefault();
                    selectFolder(
                        folderId,
                        treeType,
                        'Keyboard' /* actionSource */,
                        undefined,
                        undefined,
                        evt.nativeEvent.timeStamp
                    );
                    break;
                case 46:
                    deleteFolder?.();
                    break;
            }
        },
        [folderId, treeType, deleteFolder]
    );

    const toggleFavorite = useCallback(() => {
        const isFavorite = isFolderInFavorites(folderId);
        lazyToggleFavoriteFolder.importAndExecute(folderId, isFavorite, 'ContextFolder');
    }, [folderId]);

    return (
        <TreeNode
            {...onTouchHandler}
            chevronProps={chevronProps}
            customIconComponent={customIconComponent}
            depth={depth}
            displayName={displayName}
            isBeingDragged={isBeingDragged}
            isDroppedOver={isDroppedOver}
            isRootNode={false}
            isSelected={isSelected}
            isWithContextMenuOpen={isWithContextMenuOpen}
            key={folderId}
            onKeyDown={onKeyDown}
            onClick={onClick}
            onContextMenu={onContextMenu}
            renderRightCharm={renderRightCharm}
            showAsHoverOnDroppedOver={showHoverStateOnDroppedOver}
            isFavorited={isFolderInFavorites(folderId)}
            toggleFavorite={toggleFavorite}
            isinSharedFolderTree={treeType == 'sharedFolderTree'}
            isDisabled={isLoading}
            customTreeNodeTooltip={
                isSearchFolderRoot
                    ? undefined
                    : buildUnreadTotalCountTooltip(
                          tooltipNameContent ?? displayName,
                          totalCount,
                          unreadCount
                      )
            }
            ellipsesOnHover={ellipsesOnHover}
            source={SourceMap.subfolder}
            shouldShowFocusBorder={true}
            customIconColor={customIconColor}
        />
    );
};

export default observer(FolderNode, 'FolderNode');
