import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import { getDensityModeString, getDensityModeCssClass } from 'owa-fabric-theme';
import { FluentCheckbox } from 'owa-fluent-controls';
import loc from 'owa-localize';
import { isSingleLineListView } from 'owa-mail-layout';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import isConversationView from 'owa-mail-list-store/lib/utils/isConversationView';
import {
    full,
    medium,
    compact,
    highTwisty,
    scl,
    mcl,
    conversationView,
    itemView,
    personaCheckboxContainer,
    checkbox,
    showCheckbox,
    hideCheckbox,
    checkboxSpaceReserved,
    persona,
    personaCondensedView,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';

import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { PersonaControl } from 'owa-persona';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type { ITouchHandlerParams } from 'owa-touch-handler';
import { touchHandler } from 'owa-touch-handler';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import React from 'react';
import {
    getIsChecked,
    getIsIndeterminate,
    onMailListItemClickHandler,
    shouldTableShowCirclePersonas,
    getPersonaSizeForMLRow,
} from 'owa-mail-list-item-shared';
import type SingleRecipientType from 'owa-service/lib/contract/SingleRecipientType';
import {
    personaCheckboxSelectMessageLabel,
    personaCheckboxSelectConversationLabel,
} from './PersonaCheckbox.locstring.json';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { PersonaControlWrapperWithPresence } from 'owa-presence';
import { type PresenceUISource } from 'owa-presence/lib/components/useResolvedPresence';
import isPresenceSupported from 'owa-presence/lib/utils/isPresenceSupported';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { buildMriFromAADObjectId } from 'owa-presence/lib/utils/buildMriFromAADObjectId';

export interface PersonaCheckboxProps {
    tableViewId: string;
    isSelected: boolean;
    lastSender: SingleRecipientType | undefined;
    rowKey: string;
    lastDeliveryTimestamp: string;
    isUnauthenticatedSender: boolean;
    isCondensedView?: boolean;
}

export default observer(function PersonaCheckbox(props: PersonaCheckboxProps) {
    const { isSelected, lastSender, isUnauthenticatedSender, isCondensedView, tableViewId } = props;

    const isReselectCheckboxBehaviorEnabled = isFeatureEnabled('tri-reselect-checkbox-behavior');
    const showMultiSelectButton = !getIsSearchTableShown();
    const senderImageEnabled = isSenderImageEnabled();
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const isSingleLine = isSingleLineListView();

    const isCheckboxSpaceReserved = useComputedValue(() => {
        return (
            (showMultiSelectButton && (senderImageEnabled || tableView?.isInCheckedMode)) ||
            !showMultiSelectButton ||
            (isReselectCheckboxBehaviorEnabled && isSingleLine)
        );
    }, [
        tableView,
        showMultiSelectButton,
        isSingleLine,
        senderImageEnabled,
        isReselectCheckboxBehaviorEnabled,
    ]);

    const shouldShowCheckbox = useComputedValue(() => {
        // When we show the "Select all" checkbox on the left side of the folder name
        if (isCheckboxSpaceReserved) {
            // Render the checkbox if the item is selected or hovered or"Multi select" mode is activated
            // Always show the checkbox if reselect behavior is enabled and the mail list is single line
            return (
                isSelected ||
                tableView?.isInCheckedMode ||
                (isSingleLine ? tableView?.isInSelectAllStateSLV : tableView?.isInSelectAllState) ||
                (isReselectCheckboxBehaviorEnabled && isSingleLine && !senderImageEnabled)
            );
        } else {
            // When the mail list item content is all flushed to the left because we aren't reserving space for the checkboxes
            return tableView?.isInSelectAllState;
        }
    }, [isSelected, tableView, isCheckboxSpaceReserved, isSingleLine, senderImageEnabled]);

    const isChecked = useComputedValue((): boolean => {
        return getIsChecked(tableView, props.rowKey, props.lastDeliveryTimestamp);
    }, [tableViewId, props.rowKey, props.lastDeliveryTimestamp]);

    const isIndeterminate = (): boolean => {
        return getIsIndeterminate(tableView, props.rowKey, props.lastDeliveryTimestamp);
    };

    const touchHandler_0 = {
        get() {
            const touchHandlerParams: ITouchHandlerParams = {
                onClick,
            };
            return touchHandler(touchHandlerParams);
        },
    };

    const onClick = React.useCallback(
        (evt: React.MouseEvent<HTMLDivElement>) => {
            evt.preventDefault();
            evt.stopPropagation();
            onMailListItemClickHandler(
                evt,
                MailListItemSelectionSource.MailListItemCheckbox,
                props.rowKey,
                tableViewId
            );
        },
        [props.rowKey, tableViewId]
    );

    // There's a double click on the outer component so must prevent propagation here (this is a corner case but causes an id of null error)
    const onDoubleClick = React.useCallback((evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    }, []);

    const isTableInConversationView = tableView && isConversationView(tableView);

    if (!tableView) {
        return null;
    }
    const isSCL = !isSingleLine;

    const isTableInMultiSelectionMode =
        tableView?.isInCheckedMode ||
        (isSingleLine ? tableView?.isInSelectAllStateSLV : tableView?.isInSelectAllState);
    const densityModeString = getDensityModeString();
    const personaCheckboxContainerClasses = classnames(
        getDensityModeCssClass(full, medium, compact),
        isSCL && highTwisty,
        isSCL ? scl : mcl,
        personaCheckboxContainer,
        isTableInConversationView ? conversationView : itemView,
        shouldShowCheckbox ? showCheckbox : hideCheckbox,
        isCheckboxSpaceReserved && checkboxSpaceReserved
    );
    const checkboxClasses = classnames(checkbox, getDensityModeCssClass(full, medium, compact));
    const checkBoxAriaProps: AriaProperties = {
        role: AriaRoles.checkbox,
        checked: isChecked,
        label: isTableInConversationView
            ? loc(personaCheckboxSelectConversationLabel)
            : loc(personaCheckboxSelectMessageLabel),
    };
    // lastSender is undefined in rowModified notification payload, if the message is directly POSTed
    const lastSenderMailbox = lastSender?.Mailbox;
    const showCirclePersonas = shouldTableShowCirclePersonas(tableView.tableQuery);
    const mailboxInfo = getMailboxInfo(tableView);
    const showPresence =
        isFeatureEnabled('mon-presence-messagelist') && isPresenceSupported(mailboxInfo);
    return (
        <div
            className={personaCheckboxContainerClasses}
            {...touchHandler_0.get()}
            onClick={onClick}
            tabIndex={-1}
            onDoubleClick={onDoubleClick}
            {...generateDomPropertiesForAria(checkBoxAriaProps)}
        >
            {showCirclePersonas && !!lastSenderMailbox && showPresence && (
                <PersonaControlWrapperWithPresence
                    name={lastSenderMailbox.Name}
                    emailAddress={lastSenderMailbox.EmailAddress}
                    size={getPersonaSizeForMLRow(isSingleLine, densityModeString)}
                    showUnknownPersonaCoin={isUnauthenticatedSender}
                    className={classnames(persona, isCondensedView && personaCondensedView)}
                    mailboxType={lastSenderMailbox.MailboxType}
                    componentSource="MailListItem"
                    enableGraphQLPhotos={isFeatureEnabled('peo-images-via-graphql')}
                    showPresence={showPresence}
                    source={'MessageListSender'}
                    mri={buildMriFromAADObjectId(lastSenderMailbox.AADObjectId)}
                    mailboxInfo={mailboxInfo}
                    clientScenario={'PersonaCheckbox'}
                />
            )}
            {showCirclePersonas && !!lastSenderMailbox && !showPresence && (
                <PersonaControl
                    name={lastSenderMailbox.Name}
                    emailAddress={lastSenderMailbox.EmailAddress}
                    size={getPersonaSizeForMLRow(isSingleLine, densityModeString)}
                    showUnknownPersonaCoin={isUnauthenticatedSender}
                    className={classnames(persona, isCondensedView && personaCondensedView)}
                    mailboxType={lastSenderMailbox.MailboxType}
                    componentSource="MailListItem"
                    enableGraphQLPhotos={isFeatureEnabled('peo-images-via-graphql')}
                />
            )}
            <FluentCheckbox
                className={checkboxClasses}
                checked={isChecked}
                indeterminate={isIndeterminate()}
                isMessageSelected={isSelected}
                onChange={onClick as any}
                inputProps={checkboxInputProps}
                shouldOverrideStyleInMCL={
                    !senderImageEnabled && isSingleLine && !isTableInMultiSelectionMode
                }
            />
        </div>
    );
}, 'PersonaCheckbox');

const checkboxInputProps = { tabIndex: -1 };
