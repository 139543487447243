import type { MailFolder } from 'owa-graph-schema';
import type { MailboxInfo } from 'owa-client-types';
import { ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import FolderTreeLoadStateEnum from '../store/schema/FolderTreeLoadStateEnum';
import { setFolderTreeData } from '../mutators/setFolderTreeData';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { generateMailboxSmtpAddress } from '../util/mailboxUtility';
import { getMailboxInfoForAuxArchive } from 'owa-mailbox-info/lib/getMailboxInfoForAuxArchive';
import compareMailboxInfos from 'owa-mailbox-info/lib/compareMailboxInfos';
import {
    default as isMailboxPackageFlightEnabled,
    isMailboxCompareFlightEnabled,
} from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';

export default async function initializeArchiveFolderTree(mboxInfo: MailboxInfo) {
    // Initialize the archive root folder using the data received in session data such as display name and set it in the store.
    const mailboxInfo = mboxInfo;
    const sessionSettings = getAccountScopeUserSettings(mailboxInfo).SessionSettings;
    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    const buildMailboxInfo: MailboxInfo = {
        type: 'ArchiveMailbox',
        userIdentity: mailboxInfo.userIdentity,
        mailboxSmtpAddress: generateMailboxSmtpAddress(
            sessionSettings?.ArchiveMailboxGuid ?? '',
            sessionSettings?.OrganizationDomain ?? ''
        ),
    };
    const archiveMailboxInfo = isMailboxPackageFlightEnabled()
        ? getMailboxInfoForAuxArchive(mailboxInfo, sessionSettings?.ArchiveMailboxGuid ?? '')
        : buildMailboxInfo;

    if (isMailboxPackageFlightEnabled() && isMailboxCompareFlightEnabled()) {
        compareMailboxInfos(buildMailboxInfo, archiveMailboxInfo);
    }
    const folder = <MailFolder>{
        distinguishedFolderType: ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        displayName: sessionSettings?.ArchiveDisplayName,
        mailboxInfo: archiveMailboxInfo,
    };

    // update the archive folder tree root and loading state.
    setFolderTreeData(
        ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
        folder,
        FolderTreeLoadStateEnum.Uninitialized,
        false,
        0,
        mailboxInfo,
        undefined
    );
}
