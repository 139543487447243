import type TokenResponse from './contract/TokenResponse';
import type { MailboxInfo } from 'owa-client-types';
import type { HeadersWithoutIterator, RequestOptions } from './RequestOptions';
import { type AccountFeatureName, type GlobalFeatureName } from 'owa-feature-flags';
import type MailboxRequestOptions from './MailboxRequestOptions';
export interface MetaOSContext {
    locale: string;
    hostName: string;
    clientType?: string;
}
import type { ApplicationSettingGroup, ApplicationSettings } from 'owa-application-settings';
import type { LicensingMailboxInfo, PolicyStateTypeEnum } from 'owa-account-source-list-types';

export interface ServiceRequestConfig {
    getMsalToken?: (
        mailboxInfo: MailboxInfo,
        resource?: string,
        scope?: string,
        correlationId?: string,
        wwwAuthenticateHeader?: string
    ) => Promise<TokenResponse | undefined>;
    getAuthToken?: (
        headers?: HeadersWithoutIterator,
        mailboxInfo?: MailboxInfo
    ) => Promise<string | undefined>;
    getResourceToken?: (
        resource: string,
        wwwAuthenticateHeader?: string,
        mailboxInfo?: MailboxInfo,
        scope?: string,
        childAppId?: string,
        allowPrompt?: boolean,
        claims?: string
    ) => Promise<TokenResponse | undefined>;
    getUserToken?: (
        headers?: HeadersWithoutIterator,
        mailboxInfo?: MailboxInfo
    ) => Promise<string | undefined>;
    getWebSessionType?: (mailboxInfo?: MailboxInfo) => Promise<string>;
    onAuthFailed?: (headers?: HeadersWithoutIterator, mailboxInfo?: MailboxInfo) => void;
    baseUrl: string;
    timezone?: string;
    isFeatureEnabled: (feature: GlobalFeatureName, mailboxInfo?: MailboxInfo) => boolean;
    isAccountFeatureEnabled: (feature: AccountFeatureName, mailboxInfo: MailboxInfo) => boolean;
    isUserIdle?: () => boolean;
    prepareRequestOptions?: (
        options: RequestOptions | MailboxRequestOptions | undefined
    ) => Promise<RequestOptions | MailboxRequestOptions | null | undefined>;
    timeoutMS?: number;
    disableAllRequests?: boolean;
    getAnchorMailbox?: (mailboxInfo?: MailboxInfo) => Promise<string | undefined>;
    getExplicitLogon?: (mailboxInfo?: MailboxInfo) => Promise<string | undefined>;
    isResourceTokenPrefetchEnabled?: boolean;
    metaOSContext?: MetaOSContext;
    getGlobalSettingsAccountMailboxInfo?: () => MailboxInfo | undefined;
    getGlobalSettingsAccountPersistenceId?: () => string | undefined;
    getCanaryHeaders?: () => Record<string, string>;
    executeNaaRequest?: (
        mailboxInfo: MailboxInfo,
        request: string,
        origin: string,
        isTrustedOrigin: boolean,
        targetWindow: Window,
        scenarioName: string,
        callerId: string
    ) => Promise<string>;
    checkAndHandleHipException?: (
        headers?: HeadersWithoutIterator,
        mailboxInfo?: MailboxInfo
    ) => Promise<boolean>;
    onActivityTimeoutError?: () => Promise<void>;
    onPinnedAppsChanged?: (mailboxInfo: MailboxInfo, newSetting: string) => void;
    getModuleContextMailboxInfo?: () => Promise<MailboxInfo>;
    getApplicationSettings?: <TGroup extends ApplicationSettingGroup>(
        group: TGroup,
        mailboxInfo?: MailboxInfo,
        dontErrorIfNotInitialized?: boolean
    ) => ApplicationSettings[TGroup];
    getAudienceForCallsToOWS?: (requestUrl: string) => string | undefined;
    owsServerParamOverrideAllowed?: boolean;
    isMsalFlowEnabled?: boolean;
    // Checks the account soure list store to try and get the policy state of the licensing mailbox
    getLicensingMailboxPolicyState?: (
        mailboxInfo: LicensingMailboxInfo
    ) => PolicyStateTypeEnum | undefined;
}

const defaultCachedConfig = {
    baseUrl: '/owa',
    isFeatureEnabled: () => false,
    isAccountFeatureEnabled: () => false,
    timeoutMS: 90000,
    disableAllRequests: false,
    isResourceTokenPrefetchEnabled: false,
};

let cachedConfig: ServiceRequestConfig = defaultCachedConfig;

export function updateServiceConfig(config?: Partial<ServiceRequestConfig>) {
    cachedConfig = {
        ...cachedConfig,
        ...config,
    };
}

export function getConfig(): ServiceRequestConfig {
    return cachedConfig;
}

export function resetDefault() {
    cachedConfig = defaultCachedConfig;
}
