import { setChatProvider } from '../actions/bootstrapActions';
import { getStore } from '../store/chatStore';
import type ChatProvider from '../store/schema/ChatProvider';
import isTeamsEnabled from '../ucwa/chatManager/isTeamsEnabled';
import { TeamsPoliciesScenario } from 'owa-teams-policies-store/lib/store/schema';

// when fwk-skype-deprecation is deleted, rename the function to "initializeTeamsChatProvider"
export default async function initializeSkypeForBusiness() {
    const store = getStore();

    if (store.chatProvider == 0) {
        setChatProvider(1);
        if (await isTeamsEnabled(TeamsPoliciesScenario.SkypeForBusiness)) {
            setChatProvider(3);
        } else {
            setChatProvider(2);
        }
    }
}
