import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';
import { options_messageorganization_searchterms } from 'owa-locstrings/lib/strings/options_messageorganization_searchterms.locstring.json';
import { options_messageorganization_title } from 'owa-locstrings/lib/strings/options_messageorganization_title.locstring.json';
import type { LazyOption } from 'owa-options-core';
import { createLazyOption } from 'owa-options-core';

const lazyModule = new LazyModule(() => import('./lazyIndex'));
export const ConversationsOption: LazyOption = createLazyOption(
    {
        key: 'conversations',
        titleStringKey: options_messageorganization_title,
        searchTermsStringKey: options_messageorganization_searchterms,
        allowedOptionKeys: ['Conversations'],
    },
    lazyModule,
    m => m.fullOption
);

// Delay loaded components
export const StackedModeOptions = createLazyComponent(lazyModule, m => m.StackedModeOptions);
export const lazySaveStackedModeOptions = new LazyAction(lazyModule, m => m.saveStackedModeOptions);
export const lazyDiscardStackedModeOptions = new LazyAction(
    lazyModule,
    m => m.discardStackedModeOptions
);
export const lazySaveConversationsOptions = new LazyAction(
    lazyModule,
    m => m.saveConversationsOptions
);
export const lazySaveConversationsOptionQuick = new LazyAction(
    lazyModule,
    m => m.saveConversationsOptionQuick
);
