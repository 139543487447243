import assert from 'owa-assert/lib/assert';
import { mutatorAction } from 'satcheljs';
import getAccountBySourceId from '../selectors/getAccountBySourceId';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * Update the accountDescription of the specified Coprincipal account source in the store
 */
export default mutatorAction(
    'UPDATE_COPRINCIPAL_ACCOUNT_DESCRIPTION',
    function (sourceId: string, accountDescription: string): void {
        assert(getAccountBySourceId(sourceId), 'Cannot update account not in store');
        const store = getAccountSourceListStore();
        for (const source of store.sources) {
            if (source.sourceId === sourceId) {
                source.accountDescription = accountDescription;
            }
        }
    }
);
