import isMultiAccountsCombinedFavoritesEnabled from './isMultiAccountsCombinedFavoritesEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { MailFolder } from 'owa-graph-schema';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

export function isLocalFolderFavoriteSupported(): boolean {
    return (
        isMultiAccountsCombinedFavoritesEnabled() && isFeatureEnabled('fp-local-folder-favorites')
    );
}

export function isLocalFolderFavoriteSupportedForFolder(folder: MailFolder | undefined): boolean {
    if (!folder) {
        return false;
    } else {
        // Local folder favorites are only supported for shared and archive mailboxes
        // and shared or delegate user mailboxes when the shared co-principal account feature is enabled
        return (
            (isLocalFolderFavoriteSupported() &&
                folder.mailboxInfo &&
                (folder.mailboxInfo.type === 'SharedMailbox' ||
                    folder.mailboxInfo.type === 'ArchiveMailbox')) ||
            (isSharedCoprincipalAccountEnabled() &&
                isMailboxSharedOrDelegateUserMailbox(folder.mailboxInfo))
        );
    }
}
