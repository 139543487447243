import { owaComputedFn } from 'owa-computed-fn';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import { getMailboxInfoForGroup } from 'owa-mailbox-info/lib/getMailboxInfoForGroup';
import compareMailboxInfos from 'owa-mailbox-info/lib/compareMailboxInfos';
import {
    default as isMailboxPackageFlightEnabled,
    isMailboxCompareFlightEnabled,
} from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import transformGroupSmtp from 'owa-group-common/lib/utils/transformGroupSmtp';

export const getGroupsPanelMailboxInfo = owaComputedFn((groupId: string): MailboxInfo => {
    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    const builtMailboxInfo: MailboxInfo = {
        type: 'GroupMailbox',
        userIdentity: getModuleContextMailboxInfo().userIdentity,
        mailboxSmtpAddress: transformGroupSmtp(groupId),
    };

    const mailboxInfo = isMailboxPackageFlightEnabled()
        ? getMailboxInfoForGroup(getModuleContextMailboxInfo(), groupId)
        : builtMailboxInfo;

    if (isMailboxPackageFlightEnabled() && isMailboxCompareFlightEnabled()) {
        compareMailboxInfos(builtMailboxInfo, mailboxInfo);
    }

    return mailboxInfo;
});
