import type { HoverActionKey } from 'owa-outlook-service-option-store';
import loc from 'owa-localize';
import { archive } from 'owa-locstrings/lib/strings/archive.locstring.json';
import { deleteItem } from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { markAsRead } from 'owa-locstrings/lib/strings/markasread.locstring.json';
import { markAsUnread } from 'owa-locstrings/lib/strings/markasunread.locstring.json';
import { moveTo } from 'owa-locstrings/lib/strings/moveto.locstring.json';
import { unpin } from 'owa-locstrings/lib/strings/unpin.locstring.json';
import { pinIcon } from '../components/MailListItemIconBar.scss';
import { default as Archive } from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import { default as Checkmark } from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';
import { default as Delete } from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import { default as FlagIconFilled } from 'owa-fluent-icons-svg/lib/icons/FlagFilled';
import { default as Flag } from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import { default as FolderArrowRight } from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import { default as MailRead } from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import { default as Mail } from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import { default as PinIconFilled } from 'owa-fluent-icons-svg/lib/icons/PinFilled';
import { default as Pin } from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import { flagIcon } from 'owa-mail-listitem-styles/lib/scss/FlagIconStyles.scss';
import { pinTitle } from '../components/MailListItemIconBar.locstring.json';
import {
    flagTitle,
    unflagTitle,
} from 'owa-mail-list-item-shared/lib/getFlagCompleteHelper.locstring.json';
import classnames from 'owa-classnames';
import {
    ArchiveFilled,
    ArchiveRegular,
    bundleIcon,
    CheckmarkFilled,
    CheckmarkRegular,
    DeleteFilled,
    DeleteRegular,
    FolderArrowRightFilled,
    FolderArrowRightRegular,
    MailFilled,
    MailReadFilled,
    MailReadRegular,
    MailRegular,
    FlagFilled,
    FlagRegular,
    PinRegular,
    PinFilled,
} from '@fluentui/react-icons';
import { createIconModule } from 'owa-fluent-icons-svg';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function getHoverActionIcon(
    action: HoverActionKey,
    unreadCount?: number,
    isPinned?: boolean,
    isFlagged?: boolean,
    isComplete?: boolean
): {
    title: string;
    key: string;
    iconName: string;
    iconClassName?: string;
} {
    const useBundledIcons = isFeatureEnabled('tri-mlRelocateHoverActions');
    switch (action) {
        case 'Archive':
            return {
                title: loc(archive),
                key: 'archiveIcon',
                iconName: useBundledIcons
                    ? createIconModule('ArchiveBundle', bundleIcon(ArchiveFilled, ArchiveRegular))
                    : Archive,
            };
        case 'Move':
            return {
                title: loc(moveTo),
                key: 'moveIcon',
                iconName: useBundledIcons
                    ? createIconModule(
                          'FolderArrowRightBundle',
                          bundleIcon(FolderArrowRightFilled, FolderArrowRightRegular)
                      )
                    : FolderArrowRight,
            };
        case 'Delete':
            return {
                title: loc(deleteItem),
                key: 'deleteIcon',
                iconName: useBundledIcons
                    ? createIconModule('DeleteBundle', bundleIcon(DeleteFilled, DeleteRegular))
                    : Delete,
            };
        case 'ReadUnread':
            const hasUnread = unreadCount && unreadCount > 0;
            const readUnreadBundle = hasUnread
                ? createIconModule('MailUnreadBundle', bundleIcon(MailReadFilled, MailReadRegular))
                : createIconModule('MailReadBundle', bundleIcon(MailFilled, MailRegular));
            return {
                title: hasUnread ? loc(markAsRead) : loc(markAsUnread),
                key: 'markReadIcon',
                iconName: useBundledIcons ? readUnreadBundle : hasUnread ? MailRead : Mail,
            };
        case 'FlagUnflag':
            const flagIconName = isComplete
                ? createIconModule('CheckmarkBundle', bundleIcon(CheckmarkFilled, CheckmarkRegular))
                : isFlagged
                ? FlagIconFilled
                : createIconModule('FlagBundle', bundleIcon(FlagFilled, FlagRegular));
            return {
                title: isComplete ? loc(flagTitle) : isFlagged ? loc(unflagTitle) : loc(flagTitle),
                key: isComplete ? 'completeIcon' : 'flagIcon',
                iconClassName: classnames(isFlagged && flagIcon),
                iconName: useBundledIcons
                    ? flagIconName
                    : isComplete
                    ? Checkmark
                    : isFlagged
                    ? FlagIconFilled
                    : Flag,
            };
        case 'PinUnpin': {
            const pinClasses = classnames(isPinned && pinIcon);
            const pinIconBundle = createIconModule('PinBundle', bundleIcon(PinFilled, PinRegular));
            return {
                title: isPinned ? loc(unpin) : loc(pinTitle),
                key: 'pinIcon',
                iconClassName: classnames(pinClasses),
                iconName: isPinned ? PinIconFilled : useBundledIcons ? pinIconBundle : Pin,
            };
        }
        case 'None':
        default:
            return { title: '', key: '', iconName: '' };
    }
}
