import type { LoggerOptions } from '@azure/msal-common';
import {
    type IPublicClientApplication,
    type Configuration,
    type PerformanceTelemetryReporterConfiguration,
    type BrowserAuthOptions,
    type CacheOptions,
    PublicClientApplication,
    createNestablePublicClientApplication,
    LogLevel,
    BrowserCacheLocation,
} from '@azure/msal-browser-1p';
import { isBootFeatureEnabled } from 'owa-metatags/lib/isBootFeatureEnabled';
import { getClientVersion, isHosted } from 'owa-config';
import { getOrigin } from 'owa-url/lib/getOrigin';
import { isFeatureEnabled } from 'owa-feature-flags';
import { trace } from 'owa-trace';
import { getCommonAuthorityUrlFromMetaTag } from './utils/getAuthority';
import { OwaApplicationId } from 'owa-service/lib/constants/ApplicationId';
import { getBrowserInfo, type Browser } from 'owa-user-agent';

let msalInstancePromise: Promise<IPublicClientApplication> | null = null;
let appSpecificConfiguration: AppSpecificConfiguration | undefined = undefined;

declare global {
    interface Window {
        __JSWRAPPER?: boolean; // This is a flag set by MCAS
    }
}

export function getMsalInstance(): Promise<IPublicClientApplication> {
    // NOTE: getMsalInstance will only work after a call (during boot) to createMsalInstance which
    // configures MSAL with the correct clientId. If someone tries to use MSAL before creation, then throw
    // this error to let the developer know that MSAL is being called too early, or the boot flight is disabled.
    return msalInstancePromise ?? Promise.reject(new Error('MSAL instance not available'));
}

export type AppSpecificConfiguration = {
    auth: Pick<BrowserAuthOptions, 'clientId' | 'redirectUri'>;
    cache?: Pick<CacheOptions, 'cacheLocation'>;
};

// To do: Need to modify this funtion to return config for different environments.
export function createMsalInstance(appConfig?: AppSpecificConfiguration): void {
    if (msalInstancePromise) {
        // NOTE: this function should only be called once during boot with a specific clientId. To prevent
        // other callers from calling init with potentially different parameters, and to retain control
        // of when MSAL init occurs and use of the correct clientId, throw an error here. This should not
        // be hit during normal runtime and execution, and is to let client developers know.
        throw new Error('MSAL instance creation is managed by boot code');
    }

    appSpecificConfiguration = appConfig;

    const supportsNestedAppAuth = isHosted();

    const msalConfig: Configuration = {
        auth: {
            clientId: OwaApplicationId,
            redirectUri: `${getOrigin()}/mail/`,
            ...appSpecificConfiguration?.auth,
            authority: getCommonAuthorityUrlFromMetaTag(),
            navigateToLoginRequestUrl: true,
            clientCapabilities: ['CP1'],
            supportsNestedAppAuth,
        },
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            ...appSpecificConfiguration?.cache,
        },
        system: {
            asyncPopups: getBrowserInfo().browser === 'Safari',
            loggerOptions: getLoggerOptions(),
            allowPlatformBroker: isFeatureEnabled(
                'auth-msaljs-enableWamJsBridge',
                undefined /*mailboxInfo*/,
                true /*dontThrowErrorIfNotInitialized*/
            )
                ? true
                : false,
            allowRedirectInIframe: true,
        },
    };

    // When MCAS session starts, MCAS puts __JSWRAPPER into the window global var. If this is present, we should disable CAE capabilities.
    if (self.__JSWRAPPER || isBootFeatureEnabled('auth-msaljs-disableCaeCapabilities')) {
        msalConfig.auth.clientCapabilities = undefined;
    }

    // Configure Telemetry for MATS integration
    const telemConfig: PerformanceTelemetryReporterConfiguration = {
        appName: 'OWA',
        appVersion: getClientVersion(),
    };

    msalInstancePromise = supportsNestedAppAuth
        ? createNestablePublicClientApplication(msalConfig)
        : PublicClientApplication.createPublicClientApplication(msalConfig, telemConfig);

    // purposefully don't await the promise, as any consumers will await getMsalInstance when
    // they'll first need it, so no need to artificially do it ahead of time
}

// Get the client id of the application creating MSAL instance.
export function getClientId(): string {
    return appSpecificConfiguration?.auth.clientId ?? OwaApplicationId;
}

//Get the redirect uri where authentication responses can be received by the app.
export function getRedirectUri(): string {
    return appSpecificConfiguration?.auth.redirectUri ?? `${getOrigin()}/mail/`;
}

function getLoggerOptions(): LoggerOptions | undefined {
    if (
        isFeatureEnabled(
            'auth-msaljs-EnableLogging',
            undefined /*mailboxInfo*/,
            true /*dontThrowErrorIfNotInitialized*/
        )
    ) {
        return {
            logLevel: LogLevel.Trace,
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        trace.warn(message);
                        return;
                    case LogLevel.Info:
                        trace.info(message);
                        return;
                    case LogLevel.Verbose:
                        trace.verbose(message);
                        return;
                    case LogLevel.Warning:
                        trace.warn(message);
                        return;
                    default:
                        trace.info(message);
                        return;
                }
            },
        };
    }
    return undefined;
}
