import { orchestrator } from 'satcheljs';
import onAcquisitionsRemoteRefresh from 'owa-m365-acquisitions/lib/actions/onAcquisitionsRemoteRefresh';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { updateAppBarTiles } from '../utils/updateAppBarTiles';
import { refreshAppBarAcquisitions } from '../utils/refreshAppBarAcquisitions';

orchestrator(onAcquisitionsRemoteRefresh, async ({ mailboxInfo }) => {
    // onAcquisitionsRemoteRefresh lets us know MOS3 data refreshed, so we should
    // redraw the app bar with the current cached data
    await refreshAppBarAcquisitions(mailboxInfo);

    if (isMOS3AppServiceAvailable(mailboxInfo)) {
        // after redrawing the app bar, save the AppBarTiles setting
        await updateAppBarTiles(mailboxInfo);
    }
});
