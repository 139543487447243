import { AccountSourceType } from 'owa-account-source-list-types';
import type TokenResponse from 'owa-service/lib/contract/TokenResponse';
import type TokenResponseCode from 'owa-service/lib/contract/TokenResponseCode';
import { OUTLOOK_COBRAND_ID, PENDING_REDIRECT_KEY } from './utils/constants';
import { getMsalInstance, getClientId } from './initializeMsalLibrary';
import { msalAuthenticationResultToTokenResponse } from './utils/msalAuthenticationResultToTokenResponse';
import {
    PromptValue,
    type StringDict,
    createClientAuthError,
    createAuthError,
} from '@azure/msal-common';
import {
    CacheLookupPolicy,
    type AccountInfo,
    type AuthenticationResult,
    type IPublicClientApplication,
    type PopupRequest,
    type RedirectRequest,
    type SsoSilentRequest,
    type SilentRequest,
} from '@azure/msal-browser-1p';
import { getScopes } from './utils/getScopes';
import { InteractionType } from './utils/InteractionType';
import type { MailboxInfo } from 'owa-client-types';
import { getLoginHint } from './utils/getLoginHint';
import { getQueryStringParameters, stringify } from 'owa-querystring';
import { getLogAuthErrorData, getLogAuthResultData } from './utils/logger';
import { logStartCoreUsage, logStartUsage } from 'owa-analytics-start';
import { isUrlPresent } from 'owa-config';
import { isFeatureEnabled } from 'owa-feature-flags';
import getSilentRedirectUri from './utils/getSilentRedirectUri';
import { getGuid } from 'owa-guid';
import {
    setItem as sessionStorageSetItem,
    removeItem as sessionStorageRemoveItem,
} from 'owa-session-storage';
import { getAuthority, getCommonAuthorityUrlFromMetaTag } from './utils/getAuthority';
import { acquireTokenSilently } from './acquireAccessTokenMsal';
import { setMailboxInfoToAccountMapping } from './utils/MsalAccounts';
import { getTypeHintFromAccountInfo } from './getTypeHint';
import { setLoginHintForAadOrMsaRedirect } from './utils/getAadOrMsaRedirectUri';
import { getHostLocation } from 'owa-url/lib/hostLocation';
import { deleteSidCookie } from './utils/clearCookies';

const DISALLOWED_QUERY_PARAMS = ['login_hint', 'prompt'];

/**
 * Login user to the app with MSAL.
 * @param typeHint account type hint (e.g., OutlookDotCom, Office365)
 * @param interactionType what interaction type should be used (e.g., Silent, Popup, Redirect)
 * @param msalAccount MSAL account that should be used for login
 * @param mailboxInfo if account is not provided, mailboxInfo will be used to look up available account in MSAL cache
 * @param username if neither msalAccount nor mailboxInfo is provided, username (email address) will be passed as the login hint if provided.
 * @param resource additional resource to request access to
 * @param scope additional scope to request access to
 **/
export async function loginUserMsal(
    typeHint: AccountSourceType,
    interactionType: InteractionType,
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo,
    username?: string,
    resource?: string,
    scope?: string,
    promptValue?: string,
    claims?: string,
    correlationId?: string
): Promise<TokenResponse> {
    let authResult: AuthenticationResult;

    try {
        correlationId = correlationId ?? getGuid();
        const msalInstance = await getMsalInstance();

        authResult = await loginUserMsalInternal(
            correlationId,
            msalInstance,
            interactionType,
            typeHint,
            msalAccount,
            mailboxInfo,
            username,
            resource,
            scope,
            promptValue,
            claims
        );
    } catch (error) {
        return { TokenResultCode: 2, SubErrorCode: error.errorCode };
    }

    return msalAuthenticationResultToTokenResponse(authResult, typeHint);
}

export async function loginUserMsalInternal(
    correlationId: string,
    msalInstance: IPublicClientApplication,
    interactionType: InteractionType,
    typeHint: AccountSourceType,
    msalAccount?: AccountInfo,
    mailboxInfo?: MailboxInfo,
    username?: string,
    resource?: string,
    scope?: string,
    promptValue?: string,
    claims?: string
): Promise<AuthenticationResult> {
    if (typeHint === AccountSourceType.Other) {
        // Want insight into scenarios where tokens are requested when typeHint is Other
        if (isFeatureEnabled('auth-msaljs-mailboxInfoLogging')) {
            logStartUsage('Msal-Login-MailboxInfo', {
                mailboxInfoType: mailboxInfo?.type,
                isValidMailboxSmtpAddress: !!mailboxInfo?.mailboxSmtpAddress,
                isValidUserIdentity: !!mailboxInfo?.userIdentity,
                correlationId,
            });
        }

        throw createClientAuthError('UnsupportedAccountType');
    }

    // Display Outlook Cobrand for MSAL Consumer accounts on redirect/popup requests
    const extraQueryParams: StringDict = {};

    if (
        typeHint === AccountSourceType.OutlookDotCom &&
        (interactionType === InteractionType.Redirect || interactionType === InteractionType.Popup)
    ) {
        // Add extra query params to add Outlook cobranding, block EASI ID creation
        // fl = dob, flname, wld creates an @outlook.com account and forces collection of DOB, country, and first and last name
        // Note: The EASI ID creation needs to be blocked for both Sign In and Sign Up scenarios
        extraQueryParams.cobrandid = OUTLOOK_COBRAND_ID;
        extraQueryParams.fl = 'dob,flname,wld';

        // Add extra query params to show create account dialog
        // https://learn.microsoft.com/en-us/entra/identity-platform/reference-msa-server-side-api
        if (promptValue === PromptValue.CREATE) {
            extraQueryParams.signup = '1';
        }
    }

    const request: SsoSilentRequest &
        PopupRequest &
        RedirectRequest &
        Pick<SilentRequest, 'cacheLookupPolicy'> = {
        scopes: getScopes(typeHint, resource, scope),
        account: msalAccount ?? undefined,
        loginHint: getLoginHint(msalAccount, mailboxInfo, username),
        authority: getAuthority(typeHint),
        prompt: promptValue,
        extraQueryParameters: extraQueryParams,
        redirectUri: getSilentRedirectUri(),
        claims,
        correlationId,

        // msal-browser@3.26.1 has a regression in NAA where cacheLookupPolicy is not properly defaulted to
        // CacheLookupPolicy.Default. Explicitly pass Default here as a workaround until MSAL fix is in.
        cacheLookupPolicy: CacheLookupPolicy.Default,
    };

    const startTime = self.performance.now();
    let authResult: AuthenticationResult;

    try {
        if (interactionType === InteractionType.Redirect) {
            await loginRedirect(msalInstance, request, typeHint);
            throw createAuthError('NotRedirected'); // make TS happy
        } else if (interactionType === InteractionType.Popup) {
            authResult = await msalInstance.loginPopup(request);
        } else {
            authResult = await msalInstance.ssoSilent(request);
        }
    } catch (error) {
        logStartCoreUsage(
            'Msal-Login',
            getLogAuthErrorData(
                interactionType,
                error,
                startTime,
                request.scopes,
                typeHint,
                !!request.authority && isUrlPresent(request.authority)
            )
        );

        throw error;
    }

    logStartCoreUsage(
        'Msal-Login',
        getLogAuthResultData(
            interactionType,
            authResult,
            startTime,
            typeHint,
            !!request.authority && isUrlPresent(request.authority)
        )
    );

    if (mailboxInfo) {
        setMailboxInfoToAccountMapping(mailboxInfo, authResult.account);
    }

    return authResult;
}

function loginRedirect(
    msalInstance: IPublicClientApplication,
    request: RedirectRequest,
    typeHint: AccountSourceType
): Promise<void> {
    // Initiate the login process.
    // Use the /common endpoint for fresh login attempts or account switching to support cross-domain login and show both business and consumer accounts.
    if (!request.loginHint || request.prompt == PromptValue.SELECT_ACCOUNT) {
        request.prompt = request.prompt ?? PromptValue.SELECT_ACCOUNT; // Default to SELECT_ACCOUNT and skip SSO attempt when loginHint is not provided or if it's an account switching scenario

        // Create account flow must use the /consumers endpoint. Otherwise, use /common
        if (request.prompt != PromptValue.CREATE) {
            request.scopes = [];
            request.authority = getCommonAuthorityUrlFromMetaTag();
        }
    }

    request.redirectUri = undefined; // use the redirectUri from the MSAL config
    request.redirectStartPage = getOriginalRequestUrl();

    // Log the start of the login process, before the page redirect.
    logStartCoreUsage('Msal-Login', {
        accountType: typeHint,
        interactionType: InteractionType.Redirect,
        prompt: request.prompt,
        isValidAuthorityUrl: isUrlPresent(request.authority ?? ''),
        correlationId: request.correlationId,
        applicationId: getClientId(),
    });

    // set a temporary flag in session storage to indicate the page load should
    // use MSAL flow to handle the incoming redirect
    sessionStorageSetItem(self, PENDING_REDIRECT_KEY, '1');

    return msalInstance.loginRedirect(request);
}

export async function handleLoginRedirectPromise(
    msalInstance: IPublicClientApplication,
    domainTypeHint: AccountSourceType,
    correlationId: string,
    claims: string | undefined
): Promise<AuthenticationResult | null> {
    const startTime = self.performance.now();

    try {
        sessionStorageRemoveItem(self, PENDING_REDIRECT_KEY);
        let authResult = await msalInstance.handleRedirectPromise(self.owaLocationHash);

        if (authResult) {
            // Remove the sid cookie
            deleteSidCookie();

            // If the account is different from the domain type hint, redirect to the correct domain.
            const accountInfoTypeHint = getTypeHintFromAccountInfo(authResult.account);
            if (
                domainTypeHint != AccountSourceType.Other &&
                domainTypeHint != accountInfoTypeHint
            ) {
                setLoginHintForAadOrMsaRedirect(getLoginHint(authResult.account));
                throw new Error('AadOrMsaRedirRequired');
            }

            // If the common endpoint authority URL was used for the authentication, OWA needs to acquire a new token with domain specific scopes.
            if (authResult.authority == getCommonAuthorityUrlFromMetaTag()) {
                authResult = await acquireTokenSilently(
                    correlationId,
                    msalInstance,
                    authResult.account,
                    domainTypeHint,
                    undefined, // resource
                    undefined, // scope
                    claims
                );
            }

            // Coming back from a successful authentication redirect and token fetching.
            logStartCoreUsage(
                'Msal-Login-RedirectPromise',
                getLogAuthResultData(
                    InteractionType.Redirect,
                    authResult,
                    startTime,
                    domainTypeHint,
                    isUrlPresent(authResult.authority)
                )
            );

            return authResult;
        }
    } catch (error) {
        logStartCoreUsage(
            'Msal-Login-RedirectPromise',
            getLogAuthErrorData(
                InteractionType.Redirect,
                error,
                startTime,
                undefined /*scopes*/,
                domainTypeHint
            )
        );
        throw error;
    }

    // No redirect was detected
    return null;
}

function getOriginalRequestUrl(): string {
    // delete disallowed query params to prevent redirect loop
    const queryStringParams = getQueryStringParameters();
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    DISALLOWED_QUERY_PARAMS.forEach(param => {
        delete queryStringParams[param];
    });

    const path = getHostLocation().pathname;
    if (Object.keys(queryStringParams).length > 0) {
        return path + '?' + stringify(queryStringParams);
    }

    return path;
}
