/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * This is the repo-wide wrapper for observer.
 */
import { observer as mobxObserver } from 'mobx-react';
import type React from 'react';
import { withEventPerformanceMeasure } from 'owa-performance-react';
import { isBootFeatureEnabled } from 'owa-metatags';

export type PropsType<TComponentType> = TComponentType extends React.ComponentType<infer TProps>
    ? TProps
    : TComponentType extends React.ForwardRefExoticComponent<infer TProps>
    ? TProps
    : never;

/*
 * When wrapping a functional component in `observer`, Mobx creates a new functional component
 * that simply invokes the wrapped component as a function. This means that frames for observer
 * components in component stacks from <ErroBoundary /> will simply point to Mobx code rather than
 * the underlying component.
 *
 * Theoretically, setting `displayName` on the component would at least fix the component name in
 * the stack frame (though not the code location), but React defies their own documentation and ignores
 * `displayName` when generating component stacks
 * (https://github.com/facebook/react/issues/22315#issuecomment-924819180).
 *
 * We can work around this by using `Object.defineProperty` to redefine the function's `name` property
 * (https://github.com/facebook/react/issues/22315#issuecomment-920152792). One caveat is that this does not
 * work on Firefox.
 *
 * An additional snag, Mobx's `observer()` wraps the component in `React.memo()`, and React helpfully unwraps such
 * internal wrappers when it generates the component stack. But this means we need to reach inside the wrapper
 * (component.type) in order to set the `name` property properly
 * (https://github.com/mobxjs/mobx/issues/3438#issuecomment-1421627378).
 */
export function observer<TComponentClass extends React.ComponentClass<any>>(
    baseComponent: TComponentClass
): TComponentClass;
export function observer<P extends object = {}, TRef = {}>(
    baseComponent: React.ForwardRefExoticComponent<
        React.PropsWithoutRef<P> & React.RefAttributes<TRef>
    >,
    displayNameForComponentStacks: string
): React.MemoExoticComponent<
    React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<TRef>>
>;
export function observer<P extends object = {}>(
    baseComponent: React.FunctionComponent<P>,
    displayNameForComponentStacks: string
): React.FunctionComponent<P>;
export function observer<
    C extends
        | React.FunctionComponent<any>
        | React.ForwardRefRenderFunction<any>
        | React.ComponentClass<any>
>(baseComponent: C, displayNameForComponentStacks?: string): C {
    let observerComponent = mobxObserver(baseComponent);

    if (isBootFeatureEnabled('fwk-eventPerformanceMeasure')) {
        observerComponent = withEventPerformanceMeasure(
            observerComponent,
            displayNameForComponentStacks
        );
    }

    if (displayNameForComponentStacks && 'type' in observerComponent) {
        Object.defineProperty(
            (
                observerComponent as unknown as {
                    type: React.FunctionComponent<any>;
                }
            ).type,
            'name',
            {
                value: displayNameForComponentStacks,
            }
        );
    }

    return observerComponent as C;
}
