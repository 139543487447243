import type { AccountSource } from 'owa-account-source-list-store';
import { isLocalDataBackedAccount } from './isLocalDataBackedAccount';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Gets the formatted account description in the format of "Account Description (SMTP Address)".
 * @param {AccountSource} accountSource The account source object.
 * @returns {string | undefined} The formatted account description or undefined if the account description should not be shown.
 */
export function getFormattedAccountDescription(accountSource: AccountSource): string | undefined {
    const smtpAddress = accountSource.mailboxInfo.mailboxSmtpAddress;
    if (
        !shouldShowAccountDescription(accountSource) ||
        !accountSource.accountDescription ||
        accountSource.accountDescription.toLowerCase() === smtpAddress.toLowerCase()
    ) {
        return undefined;
    }
    return `${accountSource.accountDescription} (${smtpAddress})`;
}
/**
 * Determines whether the account description should be displayed.
 * @param accountSource The account source object.
 * @returns `true` if the account description should be shown; otherwise, `false`.
 */
export const shouldShowAccountDescription = (accountSource: AccountSource): boolean => {
    return isFeatureEnabled('acct-accountDescription') && !isLocalDataBackedAccount(accountSource);
};
