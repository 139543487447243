import tombstoneOperations, { type TombstoneReasonType } from 'owa-mail-list-tombstone';
import { isBulkActionInState, BulkActionStateEnum } from 'owa-bulk-action-store';
import type { TableView } from 'owa-mail-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';

export function getCanTableLoadMore(tableView: TableView): boolean {
    // If bulk operation is running in this folder, do not load more
    if (isBulkActionInState(tableView.serverFolderId, BulkActionStateEnum.Running)) {
        return false;
    }

    // We have more items in the store locally outside of loaded range, so we can load more
    if (tableView.currentLoadedIndex < tableView.rowKeys.length) {
        return true;
    }

    // there is a brief period of time where immediately after client item deletion and before server
    // notification comes in, client totalRowsInView count is out of sync with server
    // this code accounts for the in progress delete count so that client does not trigger unnecessary load more calls
    const inProgressDeleteRowsCount = tombstoneOperations.getCount(tableView.serverFolderId, 3);

    // If we support JumpTo, then check based on the loaded start index and current loaded index
    if (isFeatureEnabled('tri-jumpToInMl-dev')) {
        return (
            tableView.rowKeys.length > 0 &&
            tableView.loadedStartIndex + tableView.currentLoadedIndex <
                tableView.totalRowsInView - inProgressDeleteRowsCount
        );
    }

    // If we have no more items locally in store, check if there are more items on server
    // We can only support load more SeekToCondition request if we have any items left on the client
    // VSO 12854: Listview should load more if user deletes all items that's on the client but the server has more rows
    return (
        tableView.rowKeys.length > 0 &&
        tableView.rowKeys.length < tableView.totalRowsInView - inProgressDeleteRowsCount
    );
}
