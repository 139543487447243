import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import { lazyOnFocusedElementChanged, lazyOnKeyDownEvent } from 'owa-accessibility-loop-nav';
import { isFeatureEnabled } from 'owa-feature-flags';

export function useNavigationManager() {
    useWindowEvent(
        'focusout',
        (event: FocusEvent) => {
            lazyOnFocusedElementChanged.importAndExecute(event);
        },
        'useNavigationManager_focusout',
        [],
        undefined /* targetWindow */,
        isFeatureEnabled('fwk-loop-less-focusout') /* skipAttach */
    );
    useWindowEvent(
        'keydown',
        (event: KeyboardEvent) => {
            lazyOnKeyDownEvent.importAndExecute(event);
        },
        'useNavigationManager_keydown',
        []
    );
}
