import getListViewState from '../selectors/getListViewState';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import getRowExpansionState, { ExpansionState } from './getRowExpansionState';

export function isFirstLevelExpanded(rowKey: string): boolean {
    return (
        shouldShowUnstackedReadingPane() && getRowExpansionState(rowKey) === ExpansionState.BRANCHED
    );
}

export function isSecondLevelExpanded(rowKey: string): boolean {
    if (shouldShowUnstackedReadingPane()) {
        return getRowExpansionState(rowKey) === ExpansionState.FULLY_EXPANDED;
    }
    const listViewState = getListViewState();
    return (
        listViewState.expandedConversationViewState.expandedRowKey == rowKey &&
        !listViewState.expandedConversationViewState.shouldBeExpanded
    );
}

export function isLoadingSecondLevelExpansion(rowKey: string): boolean {
    return (
        shouldShowUnstackedReadingPane() &&
        getRowExpansionState(rowKey) === ExpansionState.LOADING_ITEM_PARTS
    );
}
