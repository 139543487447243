import initializeExtendedCardForConversationReadingPane from './initializeExtendedCardForConversationReadingPane';
import initializeExtendedStateForItemViewState from 'owa-mail-reading-pane-store/lib/actions/initializeExtendedStateForItemViewState';
import { createItemPartViewState } from './loadConversationReadingPane';
import markConversationCollapsedItemsRollUp from './markConversationCollapsedItemsRollUp';
import { setIsExpandedMutator } from 'owa-mail-reading-pane-store/lib/actions/setIsExpanded';
import tryAddOofNodesInRollUp from './rollUp/tryAddOofNodesInRollUp';
import tryInitializeShouldShowCLPLabel from './tryInitializeShouldShowCLPLabel';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type FocusedItemPart from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import itemHasHighlightTerms from '../utils/itemHasHighlightTerms';
import { getUnsupportedItemIdFromConversation } from 'owa-mail-reading-pane-store/lib/utils/unsupportedItemUtils';
import type { ObservableMap } from 'mobx';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import getItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/utils/getItemReadingPaneViewState';
import { setShowDecryptedContent } from 'owa-mail-reading-pane-store/lib/mutators/setShowDecryptedContent';
import { isFeatureEnabled } from 'owa-feature-flags';
import isCLPEnabled from 'owa-mail-protection/lib/utils/clp/isCLPEnabled';
import type { ConversationReadingPaneNode, ClientItem } from 'owa-mail-store';
import type ConversationItemParts from 'owa-mail-store/lib/store/schema/ConversationItemParts';
import mailStore from 'owa-mail-store/lib/store/Store';
import { findItemToLoad } from 'owa-mail-store/lib/utils/conversationsUtils';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { yieldNow } from 'owa-task-queue/lib/schedule';

function setItemPartExpandedState(
    isNewestOnBottom: boolean,
    hasExpandedNonDraftItem: boolean,
    loadedConversationItemParts: ItemPartViewState[],
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>
) {
    const itemPartCount = loadedConversationItemParts.length;

    if (itemPartCount == 0 || hasExpandedNonDraftItem) {
        return;
    }
    /*
     ** Expand the latest local one if no item is expanded.
     ** The latest local message can also be an OOF message, for which ItemPartViewState does not exist
     ** In that case, we expand the trigger message for the OOF message
     */
    // We traverse the array from end to start if newest on bottom;
    // from start to end otherwise.
    let itemPartToExpand: ItemPartViewState | null = null;
    const latestItemIndex = isNewestOnBottom ? itemPartCount - 1 : 0;
    let itemIndex = latestItemIndex;
    const indexIncrement = isNewestOnBottom ? -1 : 1;
    const nodeHasLocalItem = (nodeId: string): boolean => {
        const node = conversationNodes.get(nodeId);

        if (node) {
            return findItemToLoad(node)?.[1] ?? false;
        }

        return false;
    };
    while (itemIndex >= 0 && itemIndex < itemPartCount) {
        const itemPartViewState = loadedConversationItemParts[itemIndex];
        const shouldExpandOOFRollupTrigger =
            itemPartViewState.oofRollUpViewState.oofReplyNodeIds.some(nodeHasLocalItem);
        // Expand the latest local item. If the latest item is an OOF item, expand its trigger message
        if (
            !itemPartViewState.isExpanded &&
            (itemPartViewState.isLocal || shouldExpandOOFRollupTrigger)
        ) {
            itemPartToExpand = itemPartViewState;
            break;
        }
        itemIndex += indexIncrement;
    }
    // If no item part is found to be expanded after for loop, blindly expand the latest one
    if (!itemPartToExpand) {
        itemPartToExpand = loadedConversationItemParts[latestItemIndex];
    }
    setIsExpandedMutator(itemPartToExpand, true);
    itemPartToExpand.isFossilizedTextExpanded = true;
}

function setItemPartSelectedState(
    loadedConversationItemParts: ItemPartViewState[],
    itemIdToScrollTo?: string
): FocusedItemPart | null {
    // Select the first expanded item
    const itemPartCount = loadedConversationItemParts.length;
    for (let i = 0; i < itemPartCount; i++) {
        const itemPartViewState = loadedConversationItemParts[i];
        const itemViewStateId = itemPartViewState.itemId;
        if (itemIdToScrollTo) {
            // If we have an itemId to scroll to, look for the corresponding item part.
            if (itemViewStateId == itemIdToScrollTo) {
                // When we find it, make sure it's expanded and selected
                setIsExpandedMutator(itemPartViewState, true);
                return {
                    itemPart: itemPartViewState,
                    focusedItemArea: FocusedItemArea.Item,
                };
            }
        } else if (itemPartViewState.isExpanded) {
            // Or look for the first expanded item.
            return {
                itemPart: itemPartViewState,
                focusedItemArea: FocusedItemArea.Item,
            };
        } else if (itemPartViewState.oofRollUpViewState.isOofRollUpExpanded) {
            // Or look for the first item with expanded oof rollup
            return {
                itemPart: itemPartViewState,
                focusedItemArea: FocusedItemArea.Oof,
            };
        }
    }

    // return null if itemPartCount is 0.
    // itemPartCount could be 0 when all conversationNodes return from GCI response are deleted drafts
    // and we're not in delete folder
    return null;
}

/**
 * @returns current selected item id.
 */
function loadItemPartStates(
    loadedConversation: ConversationItemParts,
    loadedConversationViewState: ConversationReadingPaneViewState,
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>,
    items: ObservableMap<string, ClientItem>
): FocusedItemPart | null {
    const loadedConversationItemParts: ItemPartViewState[] = [];
    const { itemPartsMap, itemIdToScrollTo } = loadedConversationViewState;
    const mailboxInfo = loadedConversation.conversationId.mailboxInfo;
    let currentSelectedItemPart: FocusedItemPart | null = null;
    if (loadedConversation.conversationNodeIds) {
        const shouldShowLoadingSpinner = loadedConversation.conversationNodeIds.length === 1;
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        loadedConversation.conversationNodeIds.forEach(nodeId => {
            const [itemPartViewState] = createItemPartViewState(
                {
                    mailboxInfo,
                    Id: nodeId,
                },
                shouldShowLoadingSpinner
            );
            if (itemPartViewState) {
                itemPartsMap.set(nodeId, itemPartViewState);
            }
        });

        isCLPEnabled(mailboxInfo) && tryInitializeShouldShowCLPLabel(loadedConversationViewState);

        tryAddOofNodesInRollUp(
            itemPartsMap,
            conversationNodes,
            loadedConversation.conversationNodeIds,
            false /*shouldCleanUpChildrenNodes*/
        );

        // After the calculation for oof roll up and calendar roll up, some nodes may not be existed in itemPartsMap
        // So we need to loop the conversation node ids again to find out all the normal item parts from itemPartsMap
        // for the further calcuation(Expanded state)
        let hasExpandedNonDraftItem = false;

        loadedConversation.conversationNodeIds.forEach(nodeId => {
            const itemPartViewState = itemPartsMap.get(nodeId);
            if (itemPartViewState && !itemPartViewState.isInRollUp) {
                loadedConversationItemParts.push(itemPartViewState);
                const item = items.get(itemPartViewState.itemId);

                if (item && itemHasHighlightTerms(item)) {
                    setIsExpandedMutator(itemPartViewState, true);
                }

                // Initial state for decrypted content display logic on loading the conversation item part
                if (isFeatureEnabled('rp-encryptedEmail2ClickPreview', mailboxInfo)) {
                    const viewState = getItemReadingPaneViewState(item?.ItemId?.Id);

                    if (item && viewState) {
                        setShowDecryptedContent(viewState, false);
                    }
                }

                hasExpandedNonDraftItem =
                    hasExpandedNonDraftItem || (itemPartViewState?.isExpanded && !item?.IsDraft);
            }
        });

        const isNewestOnBottom =
            loadedConversation.conversationSortOrder == 'ChronologicalNewestOnBottom';

        setItemPartExpandedState(
            isNewestOnBottom,
            hasExpandedNonDraftItem,
            loadedConversationItemParts,
            conversationNodes
        );

        currentSelectedItemPart = setItemPartSelectedState(
            loadedConversationItemParts,
            itemIdToScrollTo
        );

        loadedConversationItemParts.forEach((itemPart: ItemPartViewState) => {
            if (itemPart.isExpanded) {
                initializeExtendedStateForItemViewState(itemPart);
            }
        });

        initializeExtendedCardForConversationReadingPane(
            loadedConversationViewState,
            loadedConversationItemParts,
            mailboxInfo
        );

        if (loadedConversationItemParts.length) {
            const rootNodeIndex = isNewestOnBottom
                ? 0
                : loadedConversation.conversationNodeIds.length - 1;
            const rootNodeId = loadedConversation.conversationNodeIds[rootNodeIndex];

            if (loadedConversation.canLoadMore) {
                // If the conversation could load more, the see more button should be
                // bundled with the root node.
                loadedConversationViewState.nodeIdBundledWithSeeMoreMessages = rootNodeId;
            }

            // If there is a inline compose, dont show rollup as reply to rollup item comes top/bottom if item is rolled up
            if (!findInlineComposeViewState(loadedConversationViewState.conversationId.Id)) {
                markConversationCollapsedItemsRollUp(
                    loadedConversationViewState,
                    loadedConversationItemParts
                );
            }
        }
    }
    return currentSelectedItemPart;
}

export interface FirstLoadConversationReadingPaneState {
    loadedConversation: ConversationItemParts | undefined;
    conversationReadingPaneState: ConversationReadingPaneViewState;
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>;
    items: ObservableMap<string, ClientItem>;
}

export const firstLoadConversationReadingPane = action('firstLoadConversationReadingPane')(
    async function firstLoadConversationReadingPane(
        conversationId: string,
        state: FirstLoadConversationReadingPaneState = {
            loadedConversation: mailStore.conversations.get(conversationId),
            conversationNodes: mailStore.conversationNodes,
            conversationReadingPaneState: getConversationReadingPaneViewState(conversationId),
            items: mailStore.items,
        }
    ) {
        if (
            state.conversationReadingPaneState?.conversationId?.Id != conversationId ||
            !state.loadedConversation
        ) {
            // If a different conversation has been loaded before this action is called, skip.
            return;
        }
        const loadedConversationState = state.conversationReadingPaneState;
        loadedConversationState.loadingState.isLoading = false;
        loadedConversationState.loadingState.hasLoadFailed =
            state.loadedConversation.loadingState.hasLoadFailed;
        if (!loadedConversationState.loadingState.hasLoadFailed) {
            const unsupportedItemId = getUnsupportedItemIdFromConversation(conversationId);
            loadedConversationState.unsupportedItemId = unsupportedItemId;
            if (!unsupportedItemId) {
                const currentSelectedItemPart = loadItemPartStates(
                    state.loadedConversation,
                    loadedConversationState,
                    state.conversationNodes,
                    state.items
                );

                if (currentSelectedItemPart) {
                    loadedConversationState.initiallySelectedItemPart =
                        loadedConversationState.focusedItemPart = currentSelectedItemPart;
                }

                // LOAF optimization: yield to allow main task to complete before we start loading attachments
                await yieldNow();
            }
        }
    }
);
