import type { FavoriteData } from 'owa-favorites-types';
import moveDragAndDroppableItem from 'owa-dnd/lib/utils/moveDragAndDroppableItem';
import setCombinedFavoritesInCache from '../../utils/setCombinedFavoritesInCache';
import getCombinedFavoritesFromCache from '../../utils/getCombinedFavoritesFromCache';
import type { CombinedFavoritesList } from '../../store/schema/CombinedFavoritesList';
import { getUniqueDataForFavoriteType } from '../../utils/getUniqueDataForFavoriteType';

export function isFavoriteInCache(favoriteData: FavoriteData) {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!favoriteData || !combinedList) {
        return false;
    }

    const favDataUniqueId = getUniqueDataForFavoriteType(favoriteData);
    return (
        combinedList.combinedFavorites.filter(fav => fav && fav.uniqueId === favDataUniqueId)
            .length > 0 ||
        combinedList.combinedFavorites.filter(
            fav => fav && fav.favoriteId === favoriteData.favoriteId
        ).length > 0
    );
}

export function removeFavoriteFromCache(favoriteData: FavoriteData): void {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!favoriteData || !combinedList) {
        return;
    }

    const favDataUniqueId = getUniqueDataForFavoriteType(favoriteData);

    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites.filter(
            data => data && data.uniqueId !== favDataUniqueId
        ),
    });
}

export function addFavoriteToCache(favoriteData: FavoriteData, newIndex?: number): void {
    let combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!favoriteData || !combinedList) {
        combinedList = {
            combinedFavorites: [],
        };
    }

    // check if item already in cache
    if (isFavoriteInCache(favoriteData)) {
        return;
    }

    if (newIndex) {
        combinedList.combinedFavorites.splice(newIndex, 0, {
            favoriteId: favoriteData.favoriteId ?? '',
            mailboxInfo: favoriteData.mailboxInfo,
            type: favoriteData.type,
            uniqueId: getUniqueDataForFavoriteType(favoriteData),
        });
    } else {
        combinedList.combinedFavorites.push({
            favoriteId: favoriteData.favoriteId ?? '',
            mailboxInfo: favoriteData.mailboxInfo,
            type: favoriteData.type,
            uniqueId: getUniqueDataForFavoriteType(favoriteData),
        });
    }

    //save to cache
    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites,
    });
}

export function swapFavoriteItemInCache(fromIndex: number, toIndex: number): void {
    const combinedList: CombinedFavoritesList | undefined = getCombinedFavoritesFromCache();

    if (!combinedList) {
        return;
    }

    moveDragAndDroppableItem(combinedList.combinedFavorites, fromIndex, toIndex);

    setCombinedFavoritesInCache({
        combinedFavorites: combinedList.combinedFavorites,
    });
}
