import getTableId from './helpers/getTableId';
import { ObservableMap } from 'mobx';
import type { TableQuery, TableView } from 'owa-mail-list-store';
import { getStore, type LoadErrorStatus } from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';
import { isSingleLineListView } from 'owa-mail-layout';

/**
 * Create empty table view based on the table query
 * @param id the table id
 * @param tableQuery the table query
 * @return the table view
 */
function createTableView(id: string, tableQuery: TableQuery): TableView {
    const tableView: TableView = {
        currentLoadedIndex: 0,
        loadedStartIndex: 0,
        loadErrorStatus: 0,
        id,
        isInCheckedMode: false,
        isInVirtualSelectAllMode: false,
        isInitialLoadComplete: false,
        isLoading: true,
        rowKeys: [],
        rowIdToRowKeyMap: new ObservableMap<string, string[]>(),
        lastEmptiedTime: null,
        selectAllModeTimeStamp: null,
        selectedRowKeys: new ObservableMap<string, boolean>(),
        serverFolderId: tableQuery.folderId,
        tableQuery,
        totalRowsInView: 0,
        virtualSelectAllExclusionList: [],
        selectionAnchorRowKey: null,
        focusedRowKey: null,
        multiSelectionAnchorRowKey: null,
        isInSelectAllState: isSingleLineListView(), // By default the checkbox for each row is rendered in SLV
        isInSelectAllStateSLV: false,
        lastAutoFocusedRowKey: null,
        floatingAdIndex: -1,
        floatingAdIndexChanged: false,
    };

    return tableView;
}

/**
 * VSO-18425 - Adding the table to the store should be managed by table Cache
 * Add the table view to store
 * @param tableView the table view
 * @param tableViews the table views collection in the store
 */
/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const addTableViewToStore = mutatorAction(
    'addTableViewToStore',
    (tableView: TableView, tableViews: ObservableMap<string, TableView>) => {
        tableViews.set(tableView.id, tableView);
    }
);

/**
 * Get the table view from the list view store based on the table query
 * @param tableQuery the table query
 * @param state which contains all the table views in list view store
 * @return tableView the tableView
 */
export default function getTableViewFromTableQuery(
    tableQuery: TableQuery,
    skipAddToStore?: boolean
): TableView {
    const tableViews = getStore().tableViews;
    const tableId = getTableId(tableQuery);
    let tableView = tableViews.get(tableId);

    // Create tableView if it doesn't exist yet
    if (!tableView) {
        tableView = createTableView(tableId, tableQuery);

        if (!skipAddToStore) {
            addTableViewToStore(tableView, tableViews);
            tableView = tableViews.get(tableId) ?? tableView;
        }
    }

    return tableView;
}
