import { getSelectMailItemDatapoint } from './selectMailItemDatapointGetterAndSetter';
import type { PerformanceCoreDatapoint, PerformanceDatapoint } from 'owa-analytics';

const CUSTOM_WATERFALL_COLUMN = 4;
let extraCheckpointsString = '';

export default function appendCheckpointToSMI(checkpoint: string) {
    const smiDatapoint = getSelectMailItemDatapoint();
    // The checkpoint affects the SMI datapoint only if selection is still active, i.e. reading pane has not rendered yet.
    if (!!smiDatapoint && !smiDatapoint.hasEnded) {
        const smiStartTime = smiDatapoint.getStartTime();
        const checkpointTime = smiStartTime ? Date.now() - smiStartTime : 0;

        // Append the checkpoint and its time to the CUSTOM_WATERFALL_COLUMN value.
        extraCheckpointsString = extraCheckpointsString
            ? `${extraCheckpointsString}|${checkpoint}:${checkpointTime}`
            : `${checkpoint}:${checkpointTime}`;
    }
}

export function addExtrasCheckpointToSMI(
    selectMailItemDp: PerformanceCoreDatapoint | PerformanceDatapoint
) {
    // Add this new value to CUSTOM_WATERFALL_COLUMN.
    selectMailItemDp.addToCustomWaterfall(
        CUSTOM_WATERFALL_COLUMN,
        'extras',
        true /*discard if already added*/,
        extraCheckpointsString
    );

    extraCheckpointsString = '';
}
