import { computed } from 'mobx';
import loc, { format } from 'owa-localize';
import { lazyIsPrivateUnjoinedGroup } from 'owa-group-utils';
import { getGroupDisplayName } from 'owa-groups-shared-store/lib/selectors/getGroupDisplayName';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    lazyGetGroupFolderId,
    lazyGetGroupFolderTreeLoadingState,
} from 'owa-groups-shared-folders-store/lib/lazyFunctions';
import { GroupFolderTreeLoadStateEnum } from 'owa-groups-shared-folders-store/lib/store/schema/GroupFolderTreeLoadStateEnum';
import { type GroupFolderWellKnownName, PRIMARY_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import { folderForestStore } from 'owa-mail-folder-forest-store';
import { isFolderOrSubFolderOfArchiveRoot } from 'owa-mail-store/lib/utils/isFolderUnderArchiveRoot';
import {
    SearchPlaceholder,
    SearchAriaLabel,
} from 'owa-locstrings/lib/strings/searchplaceholder.locstring.json';
import {
    SearchDisabledInGroup,
    GroupsSearchPlaceholder,
    SearchDisabled,
    archiveMailboxFoldersSearchPlaceholder,
    MOSAppSearchPlaceholder,
    MOSAppSearchPlaceholderFallback,
} from '../components/MailSearchBoxContainer.locstring.json';
import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import { getUserConfiguration } from 'owa-session-store';
import { shouldUseAppHostSearch } from 'owa-app-host-search/lib/utils/shouldUseAppHostSearch';
import { lazyGetAppDefinition_sync } from 'owa-app-definitions';
import { getSelectedApp } from 'owa-appbar-state';

/**
 * Determines if the search bar should be disabled
 */
export const isSearchBarDisabled = computed(() => {
    const mailboxInfo = getModuleContextMailboxInfo();
    if (mailboxInfo.type == 'GroupMailbox') {
        const isPrivateUnjoinedGroup = lazyIsPrivateUnjoinedGroup.tryImportForRender();
        if (
            !isPrivateUnjoinedGroup ||
            isPrivateUnjoinedGroup(mailboxInfo.mailboxSmtpAddress, mailboxInfo)
        ) {
            return true;
        }

        const getGroupFolderTreeLoadingState =
            lazyGetGroupFolderTreeLoadingState.tryImportForRender();
        const loadingState = getGroupFolderTreeLoadingState?.(
            mailboxInfo.mailboxSmtpAddress,
            mailboxInfo
        );

        if (loadingState && loadingState === GroupFolderTreeLoadStateEnum.Loaded) {
            const getGroupFolderId = lazyGetGroupFolderId.tryImportForRender();
            const selectedNodeId = getGroupFolderId?.(
                mailboxInfo.mailboxSmtpAddress,
                'inbox',
                mailboxInfo
            );
            return !selectedNodeId;
        }
        return true;
    }
    return false;
});

// Get the right aria label for the narrator for the search box
export const getAriaLabelForSearchBox = computed(() => {
    // If in the primary mailbox scope, not in explicit logon, and 3s is available, return the aria label for the search box with extended entity suggestions
    if (
        folderForestStore.selectedNode?.treeType == PRIMARY_FOLDERS_TREE_TYPE &&
        is3SServiceAvailable() &&
        !getUserConfiguration().SessionSettings?.IsExplicitLogon
    ) {
        return loc(SearchAriaLabel);
    }

    // otherwise return the same aria label as the placeholder text
    return getSearchPlaceHolderText();
});

/**
 * Returns the placeholder text for the search box.
 */
export function getSearchPlaceHolderText() {
    const mailboxInfo = getModuleContextMailboxInfo();
    const isGroupMailbox = mailboxInfo.type == 'GroupMailbox';
    if (isSearchBarDisabled.get()) {
        return isGroupMailbox ? loc(SearchDisabledInGroup) : loc(SearchDisabled);
    }

    /**
     * If search box is not disabled, return the appropriate string depending
     * on if the user is in their primary or archive mailbox.
     */
    if (
        folderForestStore.selectedNode &&
        isFolderOrSubFolderOfArchiveRoot(folderForestStore.selectedNode.id)
    ) {
        return loc(archiveMailboxFoldersSearchPlaceholder);
    } else if (isGroupMailbox) {
        const groupSmtp = mailboxInfo.mailboxSmtpAddress;
        const groupName = groupSmtp ? getGroupDisplayName(mailboxInfo, groupSmtp) : null;
        if (groupName) {
            return format(loc(GroupsSearchPlaceholder), groupName);
        }
    } else if (shouldUseAppHostSearch()) {
        const appId = getSelectedApp();
        const getAppDefinition = lazyGetAppDefinition_sync.tryImportForRender();
        if (getAppDefinition) {
            const appName = getAppDefinition(appId, mailboxInfo)?.appDefinition?.name;
            return appName
                ? format(loc(MOSAppSearchPlaceholder), appName)
                : loc(MOSAppSearchPlaceholderFallback);
        }
    }

    return loc(SearchPlaceholder);
}
