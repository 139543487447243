import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { getListViewState } from 'owa-mail-list-store';
import { isFirstLevelExpanded } from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import resetExpansionViewStateInternal from 'owa-mail-list-store/lib/utils/resetExpansionViewStateInternal';

export default function setExpansionStoreState(
    rowKey: string,
    mailListItemSelectionSource: MailListItemSelectionSource
) {
    const expansionState = getListViewState().expandedConversationViewState;
    if (expansionState.expandedRowKey != rowKey) {
        // Collapsed state
        if (shouldShowUnstackedReadingPane()) {
            // Set to loading 1st level
            expansionState.shouldBeExpanded = true;
            expansionState.expandedRowKey = rowKey;
            expansionState.forks = [];
        } else if (mailListItemSelectionSource === MailListItemSelectionSource.MailListItemTwisty) {
            // set to loading second level
            expansionState.shouldBeExpanded = true;
            expansionState.expandedRowKey = rowKey;
            expansionState.forks = null;
        }
    } else {
        // set loading second level if currently first level is expanded and twisty is clicked.
        if (
            mailListItemSelectionSource === MailListItemSelectionSource.MailListItemTwisty &&
            isFirstLevelExpanded(rowKey)
        ) {
            resetExpansionViewStateInternal();
            expansionState.shouldBeExpanded = true;
            expansionState.expandedRowKey = rowKey;
        }
    }
}
