import { logUsage } from 'owa-analytics';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonId } from 'owa-ribbon-ids';
import { isUserCreatedRibbonGroupId } from 'owa-ribbon-ids';

/**
 * Go through the user's layout and ensure that all IDs are considered valid.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param userConfigLayout User's layout that we are testing
 * @param ribbonVersion The version of the user's layout, for telemetry purposes
 * @param shouldPrune if true, we will remove ids from the ribbonConfig that are not found in defaultConfig
 * return {boolean} `true` if all IDs are valid or `false` if at least one error is found
 */
export function validateUserConfigLayout(
    validDefaultConfigIds: Set<RibbonId>,
    userConfigLayout: MailRibbonGroup[],
    ribbonVersion: number,
    shouldPrune?: boolean
): boolean {
    let allValidIds = true;

    for (let i = userConfigLayout.length - 1; i >= 0; i--) {
        const { controlIds, groupId } = userConfigLayout[i];

        if (!isGroupIdValid(validDefaultConfigIds, groupId)) {
            logUsage('RibbonValidityCheck_FailedGroupIdValidityCheck', { ribbonVersion, groupId });
            if (shouldPrune) {
                userConfigLayout.splice(i, 1);
                allValidIds = false;
            } else {
                return false;
            }
        } else {
            for (let j = controlIds.length - 1; j >= 0; j--) {
                const controlId = controlIds[j];

                if (!isControlIdValid(validDefaultConfigIds, controlId)) {
                    logUsage('RibbonValidityCheck_FailedControlIdValidityCheck', {
                        ribbonVersion,
                        controlId,
                    });
                    if (shouldPrune) {
                        controlIds.splice(j, 1);
                        allValidIds = false;
                    } else {
                        return false;
                    }
                }
            }
        }
    }

    return allValidIds;
}

/**
 * Checks if the groupId is valid, meaning within our current version of defaultConfig or a user-created GroupId.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param groupId The RibbonId we're testing to see if valid
 * @returns true if valid, false if found an invalid RibbonId
 */
function isGroupIdValid(validDefaultConfigIds: Set<RibbonId>, groupId: RibbonId): boolean {
    return isUserCreatedRibbonGroupId(groupId) || validDefaultConfigIds.has(groupId);
}

/**
 * Checks if the controlId is valid, meaning within our current version of defaultConfig.
 * @param validDefaultConfigIds Set of valid RibbonIds from defaultConfig
 * @param controlId The RibbonId we're testing to see if valid
 * @returns true if valid, false if found an invalid RibbonId
 */
function isControlIdValid(validDefaultConfigIds: Set<RibbonId>, controlId: RibbonId): boolean {
    return validDefaultConfigIds.has(controlId);
}
