export default function shouldPreventCallback(
    { target }: KeyboardEvent | React.KeyboardEvent,
    allowHotkeyOnTextFields: boolean
): boolean {
    if (allowHotkeyOnTextFields || !(target instanceof HTMLElement)) {
        return false;
    }

    // If the target element is an input, we should block the hotkey, but not for checkboxes
    const isTextInput =
        target.tagName === 'INPUT' &&
        target instanceof HTMLInputElement &&
        target.type !== 'checkbox';

    return (
        isTextInput ||
        target.tagName == 'SELECT' ||
        target.tagName == 'TEXTAREA' ||
        target.contentEditable == 'true'
    );
}
