import React from 'react';
import Category from './Category';
import type CategoryType from 'owa-service/lib/contract/CategoryType';
import { logUsage } from 'owa-analytics';
import { observer } from 'owa-mobx-react';
import type { MailboxInfo } from 'owa-client-types';
import type { QuickStepCategoryType } from 'owa-quick-step-types';

export interface CategoryWellProps {
    categoryWellContainerClass: string;
    categoryContainerClass?: string;
    categories: string[];
    onCategoryClicked?: (
        ev: React.MouseEvent<unknown>,
        category: string,
        actionSource: string
    ) => void;
    categoryList?: CategoryType[];
    mailboxInfo: MailboxInfo;
    isDarkThemeOverride?: boolean;
}

function useLogNumberOfCategories(categories: string[]) {
    React.useEffect(() => {
        logUsage('Category_Well_TotalCategories', [categories.length], { sessionSampleRate: 10 });
        if (categories.some(category => category === 'object')) {
            logUsage('QuickSteps_CategoryError_Render');
        }
    }, [categories]);
}

export default observer(function CategoryWell(props: CategoryWellProps) {
    const {
        categoryContainerClass,
        categories,
        categoryWellContainerClass,
        onCategoryClicked,
        categoryList,
        mailboxInfo,
        isDarkThemeOverride,
    } = props;

    useLogNumberOfCategories(categories);

    const CategoryWellEntry = React.useCallback(
        ({ categoryName, isLastInWell }: { categoryName: string; isLastInWell: boolean }) => (
            <Category
                actionSource={'CategoryWell'}
                category={categoryName}
                containerClassName={categoryContainerClass}
                isLastInWell={isLastInWell}
                onCategoryClicked={onCategoryClicked}
                categoryList={categoryList}
                mailboxInfo={mailboxInfo}
                isDarkThemeOverride={isDarkThemeOverride}
            />
        ),
        [categoryContainerClass, onCategoryClicked, categoryList, isDarkThemeOverride]
    );

    // Categories are listed in the order that they are applied to the item, but they need
    // to be displayed such that the most recently applied category is first i.e. reverse order
    const categoriesInMostRecentOrder = [...categories].reverse();

    return (
        <div className={categoryWellContainerClass}>
            {categoriesInMostRecentOrder.map((categoryName, i) => {
                let categoryString = categoryName;
                // Temporary fix for 329260 to get the build deploying
                if (categoryName === 'object') {
                    categoryString = (categoryName as unknown as QuickStepCategoryType).categoryId;
                }
                return (
                    <CategoryWellEntry
                        key={categoryString}
                        categoryName={categoryString}
                        isLastInWell={i === categories.length - 1}
                    />
                );
            })}
        </div>
    );
}, 'CategoryWell');
