import { getCoprincipalAccountSourcesByBootState, BootState } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isOcpsStoreReady, isOcpsPolicyEnabled, OcpsPolicy } from 'owa-ocps-policy-store';

export interface DiagPolicies {
    collectEmailEnabled: boolean;
    collectLogsEnabled: boolean;
    npsSurveyEnabled: boolean;
    screenShotEnabled: boolean;
    contactSupportEnabled: boolean;
    diagnosticsTroubleshootingEnabled: boolean;
    isFeedbackEnabled: boolean;
    isGetDiagnosticsEnabled: boolean;
}

export default function getAggregatePolicyInfo(entrypoint: string): DiagPolicies {
    const accounts = getCoprincipalAccountSourcesByBootState(BootState.StartupComplete);
    let collectEmailEnabled = true; // true by default
    let collectLogsEnabled = true; // true by default
    let npsSurveyEnabled = true; // true by default
    let screenShotEnabled = true; // true by default
    let contactSupportEnabled = true; // true by default
    let diagnosticsTroubleshootingEnabled = true; // true by default
    let isFeedbackEnabled = true; // true by default
    let isGetDiagnosticsEnabled = true; // true by default

    // Only default these values to true if the store is ready
    // because if the store isn't ready, we can't be sure
    // if the values are undefined or if the store just isn't
    // ready yet
    if (!isOcpsStoreReady()) {
        logUsage('DiagnosticsAndSupport_IsOcpsStoreReady', {
            isStoreReady: false,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
        });

        return {
            collectEmailEnabled: false,
            collectLogsEnabled: false,
            npsSurveyEnabled: false,
            screenShotEnabled: false,
            contactSupportEnabled: false,
            diagnosticsTroubleshootingEnabled: false,
            isFeedbackEnabled: false,
            isGetDiagnosticsEnabled: false,
        };
    } else {
        logUsage('DiagnosticsAndSupport_IsOcpsStoreReady', {
            isStoreReady: true,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
        });
    }

    for (const account of accounts) {
        const mailboxInfo = account.mailboxInfo;
        let isSupportPolicyEnabled;
        let isFeedbackPolicyEnabled;

        collectEmailEnabled =
            collectEmailEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.Contact, true, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');
        collectLogsEnabled =
            collectLogsEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.Content, true, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');

        npsSurveyEnabled =
            npsSurveyEnabled && isOcpsPolicyEnabled(OcpsPolicy.NpsSurvey, true, mailboxInfo);
        screenShotEnabled =
            screenShotEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.ScreenShot, true, mailboxInfo) &&
            isFeatureEnabled('mon-diag-enableDataCollectionForFeedback');
        contactSupportEnabled =
            contactSupportEnabled &&
            (isSupportPolicyEnabled = isOcpsPolicyEnabled(
                OcpsPolicy.ContactSupport,
                true,
                mailboxInfo
            ));

        // TO BE DEPRECATED: DiagnosticsTroubleshooting policy
        diagnosticsTroubleshootingEnabled =
            diagnosticsTroubleshootingEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.DiagnosticsTroubleshooting, true, mailboxInfo);

        isFeedbackEnabled =
            isFeedbackEnabled &&
            (isFeedbackPolicyEnabled = isOcpsPolicyEnabled(OcpsPolicy.Feedback, true, mailboxInfo));

        isGetDiagnosticsEnabled =
            isGetDiagnosticsEnabled &&
            isOcpsPolicyEnabled(OcpsPolicy.GetDiagnostics, true, mailboxInfo);

        logUsage('DiagnosticsAndSupport_IsSupportPolicyEnabled', {
            accountType: mailboxInfo.type,
            isSupportPolicyEnabled,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled('mon-diag-enableAggregatePolicyChecks'),
        });

        logUsage('DiagnosticsAndSupport_IsFeedbackPolicyEnabled', {
            accountType: mailboxInfo.type,
            isFeedbackPolicyEnabled,
            entrypoint,
            isPolicyFlightEnabled: isFeatureEnabled(
                'mon-diag-enableAggregatePolicyCheckForFeedback'
            ),
        });
    }

    return {
        collectEmailEnabled,
        collectLogsEnabled,
        npsSurveyEnabled,
        screenShotEnabled,
        contactSupportEnabled,
        diagnosticsTroubleshootingEnabled,
        isFeedbackEnabled,
        isGetDiagnosticsEnabled,
    };
}
