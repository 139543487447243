import { getAccountByMailboxInfo } from 'owa-account-source-list-store';
import { shouldShowAccountDescription } from 'owa-account-source-list';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';

export const getMailboxLabelForFavorite = (mailboxInfo: MailboxInfo): string | undefined => {
    const accountSource = getAccountByMailboxInfo(mailboxInfo);
    if (accountSource && shouldShowAccountDescription(accountSource)) {
        if (mailboxInfo.type === 'ArchiveMailbox') {
            const userConfig = getAccountScopeUserSettings(mailboxInfo);
            return userConfig?.SessionSettings?.ArchiveDisplayName;
        } else {
            return accountSource.accountDescription;
        }
    }
    return undefined;
};
