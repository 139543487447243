import type { ObservableMap } from 'mobx';
import { trace } from 'owa-trace';
import type FolderTreeData from '../store/schema/FolderTreeData';
import getStore from '../store/store';
import { getAccountKeyForMailboxInfo, type MailboxInfo } from 'owa-client-types';

/**
 * Selector for folder tree table for given mailbox smtp identity
 */
export default function getMailboxFolderTreeDataTable(
    mailboxInfo: MailboxInfo
): ObservableMap<string, FolderTreeData> | undefined {
    const data = getMailboxFolderTreeData(mailboxInfo);
    if (!data) {
        trace.warn(
            `getMailboxFolderTreeDataTable Failure:${
                getStore().mailboxFolderTreeData.size
            } identities in store`
        );
    }

    return data?.folderTreeTable;
}

export function getMailboxFolderTreeData(mailboxInfo: MailboxInfo) {
    return getStore().mailboxFolderTreeData.get(getAccountKeyForMailboxInfo(mailboxInfo));
}
