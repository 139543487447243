import React from 'react';
import { addEventTimeToSourceMapping } from 'owa-performance';

export function withEventPerformanceMeasure<
    C extends
        | React.FunctionComponent<any>
        | React.ForwardRefRenderFunction<any>
        | React.ComponentClass<any>
>(WrappedComponent: C, displayNameForComponentStacks: string = 'UnknownComponent'): any {
    return function (props: any) {
        // Create a shallow copy of the props so we can mutate them
        const newProps = { ...props };

        // Check for React onClick event handlers
        if (typeof newProps.onClick === 'function') {
            const originalHandler = newProps.onClick;
            newProps.onClick = function wrappedEventHandler(...args: any[]) {
                // Attempt to map event timing, if the first argument is (or contains) a native event
                const [possibleEvent] = args;
                if (possibleEvent instanceof Event) {
                    addEventTimeToSourceMapping(
                        possibleEvent,
                        `Component:${displayNameForComponentStacks}`
                    );
                } else if (possibleEvent?.nativeEvent instanceof Event) {
                    addEventTimeToSourceMapping(
                        possibleEvent.nativeEvent,
                        `Component:${displayNameForComponentStacks}`
                    );
                }

                // Call the original event handler using the original context and arguments
                return originalHandler.apply(this, args);
            };
        }

        // Return the component by creating an element (helps preserve component nature & context)
        return React.createElement(WrappedComponent, newProps);
    };
}
