import { LazyBootModule, LazyAction } from 'owa-bundling-light';

const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "NativeAddAccountSupport" */ './lazyIndex')
);

export const lazyRestoreFromLocalBackup = new LazyAction(lazyModule, m => m.restoreFromLocalBackup);

export const lazyLocalAccountBackup = new LazyAction(lazyModule, m => m.localAccountBackup);

export const lazyAddAdditionalAccount = new LazyAction(lazyModule, m => m.addAdditionalAccount);

export const lazyCreateAndAddNewConsumerAccount = new LazyAction(
    lazyModule,
    m => m.createAndAddNewConsumerAccount
);

export const lazyRemoveAccount = new LazyAction(lazyModule, m => m.removeAccount);

export const lazyDisablePendingAccount = new LazyAction(lazyModule, m => m.disablePendingAccount);

export const lazyRemovePendingAccount = new LazyAction(lazyModule, m => m.removePendingAccount);

export const lazyRemediateAllLicensingAccounts = new LazyAction(
    lazyModule,
    m => m.remediateAllLicensingAccounts
);

export const lazyRemediateLicensingMailbox = new LazyAction(
    lazyModule,
    m => m.remediateLicensingMailbox
);
