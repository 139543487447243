import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type { ManageCategoriesEnabledCalendarsForDelegate } from './schema/ManageCategoriesEnabledCalendarsForDelegate';
import type { CalendarsUnionType } from 'owa-graph-schema';

const store = createStore<ManageCategoriesEnabledCalendarsForDelegate>(
    'manageCategoriesEnabledCalendarsForDelegate',
    { eligibleCalendars: new ObservableMap<string, CalendarsUnionType>() }
)();

export const getStore = () => store;
