/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'mobx' import is restricted from being used. Mobx is not allowed in the analytics worker [analytics-worker-lint] */
import { intercept } from 'mobx';
import { default as getAccountBySourceId } from '../selectors/getAccountBySourceId';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports -- (https://aka.ms/OWALintWiki)
 * Make sure this isn't imported in the analytics worker
 *	> 'mobx' import is restricted from being used. Mobx is not allowed in the analytics worker [analytics-worker-lint] */
import type { IValueWillChange } from 'mobx';
import { accountRankTypeChecker } from '../store/schema/AccountSourceList';
import { type TraceErrorObject, errorThatWillCauseAlert } from 'owa-trace';

/**
 * Interceptor handler for changes to the MailboxInfo, this will report that a
 * change happened and stop the change.
 */
function alertOnMailboxInfoModification<T>(propertyName: string): IValueWillChange<T> | null {
    const error: TraceErrorObject = new Error('AccountSourceMailboxInfoChanged');
    error.additionalInfo = { propertyName };
    errorThatWillCauseAlert('AccountSourceMailboxInfoChanged', error);

    // The MailboxInfo should be immutable, so we should not allow changes to it.
    return null;
}

/**
 * Intercepts changes to the given property and raises an alert if the property is modified, the
 * alert will icnlude the name of the property that was modified.
 * @param value Specifies the object for which changes will be intercepted
 * @param propertyName Specifies the property name for which changes will be intercepted
 */
function interceptChangesToProperty<T extends object, K extends keyof T>(
    value: T,
    propertyName: K
) {
    intercept(value, propertyName, _change => {
        return alertOnMailboxInfoModification(propertyName.toString());
    });
}

/**
 * The MailboxInfo of the account source is expected to be immutable, there have been issues
 * with the information in the MailboxInfo of the coprincipal account has been modified. To
 * make detecting these changes eaiser we add intercepts for the MailboxInfo and it's members.
 * If a change is made these will cause an alert to be raised.
 *
 * @param sourceId The sourceId of the coprincipal account to be made immutable
 */
export function interceptAndAlertOnMailboxInfoChanges(sourceId: string) {
    const account = getAccountBySourceId(sourceId);
    if (account) {
        interceptChangesToProperty(account, 'mailboxInfo');
        interceptChangesToProperty(account.mailboxInfo, 'type');
        interceptChangesToProperty(account.mailboxInfo, 'userIdentity');
        interceptChangesToProperty(account.mailboxInfo, 'mailboxSmtpAddress');
        if (account.mailboxInfo.auxiliaryMailboxGuid) {
            interceptChangesToProperty(account.mailboxInfo, 'auxiliaryMailboxGuid');
        }

        interceptChangesToProperty(account.mailboxInfo, 'sourceId');
        interceptChangesToProperty(account.mailboxInfo, 'mailboxRank');

        if (accountRankTypeChecker.isCoprincipal(account)) {
            // for coprincipal accounts, we also want to intercept the persistenceId
            interceptChangesToProperty(account, 'persistenceId');
        }
    }
}
