import type { MailboxInfo } from 'owa-client-types';
import isCopilotFeatureEnabled from './isCopilotFeatureEnabled';
import { isFeatureEnabled } from 'owa-feature-flags';
import hasM365CopilotLicense from './hasM365CopilotLicense';
import { isAIX00User } from 'owa-copilot-settings-store';
import { getApplicationSettings } from 'owa-application-settings';
import doesFolderIdEqualName from 'owa-session-store/lib/utils/doesFolderIdEqualName';
import type { MailFolder } from 'owa-graph-schema';
/**
 * @param mailboxInfo Mailbox info
 * @param selectedFolder for some folders, attachment summary should be disabled. Pass the folder if it needs to be checked.
 * @returns true if the feature is enabled, false if the feature is disabled
 */
export default function isAttachmentSummarizationEnabled(
    mailboxInfo: MailboxInfo,
    selectedFolder?: MailFolder | null
): boolean {
    // check flight
    // for AIX00 users, we want to scope them specifically as they are special group of users
    // who help with early feedback.
    const devFlightEnabled =
        isFeatureEnabled('doc-attachment-summarization') ||
        (isAIX00User(mailboxInfo) &&
            getApplicationSettings('CopilotAIX00', mailboxInfo).attachmentSummaryEnabled);

    if (!devFlightEnabled) {
        return false;
    }

    // check license
    if (!hasM365CopilotLicense(mailboxInfo)) {
        return false;
    }

    // check AttachmentSummarization setting
    const isSummarizeLanguageSkipEnabled = getApplicationSettings(
        'Copilot',
        mailboxInfo
    ).enableMultiLanguage; /** skipLanguageCheck */

    const featureEnabled =
        !!isCopilotFeatureEnabled(
            'AttachmentSummarization',
            mailboxInfo,
            isSummarizeLanguageSkipEnabled
        ) && !!isCopilotFeatureEnabled('Summarize', mailboxInfo, isSummarizeLanguageSkipEnabled);

    if (!featureEnabled) {
        return false;
    }

    // check selected folder
    let isSummarizeButtonHidden = false; // Checks for Junk/Outbox/Draft items as Summarize will be disabled in these scenarios

    if (selectedFolder !== undefined && selectedFolder !== null) {
        const folderId = selectedFolder?.id;
        if (folderId) {
            isSummarizeButtonHidden = doesFolderIdEqualName(folderId, 'junkemail');
        }
    }

    if (isSummarizeButtonHidden) {
        return false;
    }

    return true;
}
