import { mutatorAction } from 'satcheljs';
import { userOutlookClientsStore } from '../store/store';
import { getUserOutlookClientByType } from '../selectors/getUserOutlookClientByType';
import { updateUserOutlookClient } from '../services/updateUserOutlookClient';
import { getClientVersion } from 'owa-config';
import { owaDate } from 'owa-datetime';
import { logCoreUsage } from 'owa-analytics';

export const OutlookClientsToUpdate: string[] = ['OWA'];

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setPhoneAppCardDismissState = mutatorAction(
    'setPhoneAppCardDismissState',
    (isCardDismissed: boolean) => {
        const store = userOutlookClientsStore();
        store.isCardDismissed = isCardDismissed;

        OutlookClientsToUpdate.forEach(clientType => {
            const userClient = getUserOutlookClientByType(clientType);
            if (userClient) {
                logCoreUsage('updateUserOutlookClient', {
                    clientId: userClient.clientId,
                    mobilePromoDismissDate: owaDate().toString(),
                    clientType: userClient.clientType,
                });
                updateUserOutlookClient({
                    clientId: userClient.clientId,
                    clientType: userClient.clientType,
                    clientVersion: getClientVersion(),
                    mobilePromoDismissDate: owaDate(),
                });
            }
        });
    }
);
