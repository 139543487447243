import type { IPoint } from '@fluentui/react/lib/Utilities';
import type { FolderForestNodeType } from 'owa-favorites-types';
import { mutatorAction } from 'satcheljs';
import type { FolderContextMenuState } from 'owa-mail-folder-store/lib/store/schema/FolderContextMenuState';
import {
    getStore as getSharedFavoritesStore,
    isMultiAccountsCombinedFavoritesEnabled,
} from 'owa-favorites';
import getMailFavoritesViewState from '../selectors/getMailFavoritesViewState';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import store from '../store/store';

/*
 * Sets state to spawn a context menu attached to a favorite node.
 */
export function showFavoritesContextMenu(
    nodeId: string,
    nodeType: FolderForestNodeType,
    anchor: IPoint,
    folderId?: string,
    showRootMenu?: boolean
): void {
    const mailboxInfo = isMultiAccountsCombinedFavoritesEnabled()
        ? getGlobalSettingsAccountMailboxInfo()
        : getSharedFavoritesStore().outlookFavorites?.get(nodeId)?.mailboxInfo ??
          getModuleContextMailboxInfo();
    const mailboxKey = getIndexerValueForMailboxInfo(mailboxInfo);
    if (!mailboxKey) {
        return;
    }

    setContextMenuState(mailboxKey, {
        nodeId,
        nodeType,
        folderId,
        anchor,
        treeType: 'favorites',
        showRootMenu,
    });
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setContextMenuState = mutatorAction(
    'setContextMenuState',
    (mailboxKey: string, state: FolderContextMenuState | null) => {
        const mailFavoritesViewState = getMailFavoritesViewState(mailboxKey);
        if (mailFavoritesViewState) {
            mailFavoritesViewState.contextMenuState = state;
        } else {
            store.mailFavoritesViewState.set(mailboxKey, {
                contextMenuState: state,
                shouldShowFindFavoritesPicker: false,
            });
        }
    }
);
