import { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import { isFeatureEnabled } from 'owa-feature-flags';
import { SearchScenarioId } from 'owa-search-store';
import { lazyGetActiveSearchTab } from 'owa-tabbed-search';

export async function isSearchChatEnabledForSession(
    scenarioId?: SearchScenarioId
): Promise<boolean> {
    const getActiveSearchTab = await lazyGetActiveSearchTab.import();
    const activeSearchTab: SearchTab | undefined = getActiveSearchTab();
    return (
        (scenarioId === SearchScenarioId.Mail || scenarioId === undefined) &&
        (activeSearchTab == undefined ||
            activeSearchTab == SearchTab.Mail ||
            activeSearchTab == SearchTab.All) &&
        (isFeatureEnabled('sea-searchPlusChat') ||
            isFeatureEnabled('sea-searchPlusChat-noSERPUpdate') ||
            isFeatureEnabled('sea-searchPlusChat-suggestionsOnly-AppChatHandoff') ||
            isFeatureEnabled('sea-searchPlusChat-AppChatHandoff') ||
            isFeatureEnabled('sea-searchPlusChat-handoffAfterFirstTurn'))
    );
}
