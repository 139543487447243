import type { SearchResultPerson, SearchPerson } from '../../data/schema/SubstrateSearchResponse';

export default function convertSearchResultPersonToSearchPersonType(
    SearchResultPerson: SearchResultPerson
): SearchPerson {
    return {
        ItemId: SearchResultPerson.Id,
        name: SearchResultPerson.DisplayName,
        officeLocation: SearchResultPerson.OfficeLocation ?? '',
        role: SearchResultPerson?.JobTitle ?? '',
        email: SearchResultPerson.EmailAddresses[0]?.toLowerCase(),
        InstanceKey: SearchResultPerson.ReferenceId,
    };
}
