import { type LicensingMailboxInfo } from 'owa-account-source-list-types';
import { isEasidAccount } from './isEasidAccount';
import { hasMailboxProvisioning } from './hasMailboxProvisioning';

export async function isEasidOrM365AppsForBusinessAccount(
    email: string,
    mailboxInfo: LicensingMailboxInfo
) {
    try {
        if (mailboxInfo.mailboxProvider === 'Office365') {
            return hasMailboxProvisioning(mailboxInfo);
        } else {
            return isEasidAccount(email, mailboxInfo);
        }
    } catch (error) {
        return false;
    }
}
