import { observer } from 'owa-mobx-react';
import type { MailboxInfo } from 'owa-client-types';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import folderStore, { isFolderLoaded } from 'owa-folders';
import { getCustomBundledIcon } from 'owa-folders-common/lib/util/getCustomIconComponent';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { FolderForestTreeType, MailFolder } from 'owa-graph-schema';
import toggleFolderNodeExpansion from 'owa-mail-folder-store/lib/actions/toggleFolderNodeExpansion';
import getFolderViewStateFromId, {
    initializeFolderViewStateIfNeeded,
} from 'owa-mail-folder-store/lib/selectors/getFolderViewStateFromId';
import type MailFolderDragData from 'owa-mail-folder-store/lib/store/schema/MailFolderDragData';
import { getStore as getListViewStore, getSelectedTableView } from 'owa-mail-list-store';
import {
    lazyMoveItemsBasedOnNodeIds,
    lazyCopyMailListRows,
    lazyMoveMailListRows,
    lazyMoveSingleItemFromItemId,
} from 'owa-mail-triage-action';
import type { MailListItemPartDragData } from 'owa-mail-types/lib/types/MailListItemPartDragData';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type { ChevronProps } from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { DRAG_X_OFFSET, DRAG_Y_OFFSET } from '../helpers/folderNodeDragConstants';
import { default as FolderOperationNode } from './FolderOperationNode';
import { default as FolderNode } from './FolderNode';
import { shouldRenderNodeInEditMode } from './utils/shouldRenderNodeInEditMode';
import { dragHelperStyle } from './MailFolderNode.scss';
import { isFeatureEnabled } from 'owa-feature-flags';
import {
    SEARCH_FOLDER_ROOT_DISTINGUISHED_ID,
    SEARCH_FOLDER_TYPE_NAME,
    SHARED_FOLDERS_TREE_TYPE,
    ARCHIVE_FOLDERS_TREE_TYPE,
} from 'owa-folders-constants';
import type DropEffect from 'owa-dnd/lib/store/schema/DropEffect';
import { isCapabilityEnabled } from 'owa-capabilities';
import { mailboxModificationCapability } from 'owa-capabilities-definitions/lib/mailboxModificationCapability';
import { lazyMoveFolderPosition } from 'owa-folders-move-folder-position';
import { useManagedMutation } from 'owa-apollo-hooks';
import { MoveFolderPositionDocument } from 'owa-folders-move-folder-position/lib/graphql/__generated__/MoveFolderPositionMutation.interface';
import { getIsCustomSortEnabled } from 'owa-folders/lib/selectors/getIsCustomSortEnabled';
import {
    type OpenFileMessageResponse,
    lazyOpenFileMessage,
    lazyCreateCopyItem,
    lazyCreateCopyItemFailedNotification,
} from 'owa-mail-file-support';
import isMoveCopyCrossAccountSupported from 'owa-mail-triage-action/lib/actions/helpers/isMoveCopyCrossAccountSupported';
import { type LocalFilesDragData } from 'owa-dnd/lib/utils/LocalFilesDragData';
import { FolderPaneNavigationManager } from 'owa-folders-nav';
import { logUsage } from 'owa-analytics';
import { getCustomFolderColorHexValue } from 'owa-mail-folder-store/lib/selectors/getCustomFolderColor';

export interface MailFolderNodeProps {
    depth: number; // root is 0, every sub node increase this number by 1.
    folderId: string;
    folder: MailFolder;
    treeType: FolderForestTreeType; // Type of folder tree
    isFolderExpandable: boolean;
    effectiveFolderDisplayName: string;
    distinguishedFolderParentIds?: string[];
    onContextMenu?: (
        evt: React.MouseEvent<unknown>,
        folderId: string,
        distinguishedFolderParentIds: string[]
    ) => void;
    mailboxInfo: MailboxInfo;
    moveFolder: (
        destinationFolderId: string,
        destinationFolderMailboxInfo: MailboxInfo,
        sourceFolderId: string,
        sourceFolderMailboxInfo: MailboxInfo,
        sourceFolderParentFolderId: string,
        sourceFolderDisplayName: string
    ) => void;
    deleteFolder: (
        folderId: string,
        isSearchFolder?: boolean,
        distinguishedFolderParentIds?: string[]
    ) => void;
    isBeingDragged?: boolean;
    shouldHideToggleFavorite?: boolean; // flag indicating whether to show toggle favorite option
    ellipsesOnHover?: boolean;
}

const timeIntervalFolderExpand = 500;

export default observer(function MailFolderNode(props: MailFolderNodeProps) {
    // Besides the isDragOver property in store, we also add this property here to distinguish from the dropping on FavoriteFolderNode
    const [isDragOver, setIsDragOver] = React.useState(false);

    const {
        folderId,
        treeType,
        depth,
        folder,
        isBeingDragged,
        mailboxInfo,
        effectiveFolderDisplayName,
        shouldHideToggleFavorite,
        isFolderExpandable,
        onContextMenu,
        distinguishedFolderParentIds,
        moveFolder,
        ellipsesOnHover,
    } = props;

    // State to keep track of when to show move-folder-position or move-folder-into treatment in UI
    const [isDragMoveInto, setisDragMoveInto] = React.useState(
        getIsCustomSortEnabled(mailboxInfo) ? false : true
    );

    React.useEffect(() => {
        initializeFolderViewStateIfNeeded(folderId);
    }, []);

    const [moveFolderPositionMutation] = useManagedMutation(MoveFolderPositionDocument);

    const folderViewState = getFolderViewStateFromId(folderId);

    const renderInEditMode = useComputedValue((): boolean => {
        return shouldRenderNodeInEditMode(
            folderId,
            'rename',
            mailboxInfo.mailboxSmtpAddress,
            treeType
        );
    }, [folderId, mailboxInfo.mailboxSmtpAddress]);

    const renderAddNewNode = useComputedValue((): boolean => {
        return shouldRenderNodeInEditMode(
            folderId,
            'new',
            mailboxInfo.mailboxSmtpAddress,
            treeType
        );
    }, [folderId, mailboxInfo.mailboxSmtpAddress]);

    const renderAddNewOrEditNode = (
        nodeFolderId: string,
        nodeTreeType: FolderForestTreeType,
        nestDepth: number,
        operationType: string,
        originalValue?: string
    ): JSX.Element => {
        return (
            <FolderOperationNode
                folderId={nodeFolderId}
                treeType={nodeTreeType}
                nestDepth={nestDepth}
                operationType={operationType}
                originalValue={originalValue}
                mailboxInfo={mailboxInfo}
            />
        );
    };

    const onChevronClicked = React.useCallback(
        (evt: React.MouseEvent<unknown> | KeyboardEvent) => {
            evt.stopPropagation();
            toggleFolderNodeExpansion(folderId);
            FolderPaneNavigationManager.resetSearchResults();
        },
        [folderId]
    );

    const chevronProps = React.useMemo((): ChevronProps | undefined => {
        // Check if the node is expandable / should have chevrons
        if (isFolderExpandable) {
            return {
                isExpanded: !!folderViewState.isExpanded,
                onClick: onChevronClicked,
                isAnimated: true,
            };
        } else {
            return undefined;
        }
    }, [isFolderExpandable, folderViewState.isExpanded, onChevronClicked]);

    const onContextMenu_0 = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            if (onContextMenu) {
                evt.stopPropagation();
                evt.preventDefault();
                onContextMenu(evt, folderId, distinguishedFolderParentIds ?? []);
            }
        },
        [folderId, onContextMenu, distinguishedFolderParentIds]
    );

    const onDrop = React.useCallback(
        async (
            dragData: DragData,
            _pageX: number,
            _pageY: number,
            _currentTarget?: HTMLElement,
            ctrlKey?: boolean
        ) => {
            const itemType = dragData.itemType;
            switch (itemType) {
                case DraggableItemTypes.MailListItemPart:
                    {
                        const mailListItemPartDragData = dragData as MailListItemPartDragData;
                        const tableView = getListViewStore().tableViews.get(
                            mailListItemPartDragData.tableViewId
                        );
                        if (tableView?.id) {
                            const moveItemsBasedOnNodeIds =
                                await lazyMoveItemsBasedOnNodeIds.import();

                            moveItemsBasedOnNodeIds(
                                mailListItemPartDragData.nodeIds,
                                folderId,
                                tableView.id,
                                'Drag'
                            );
                        }
                    }
                    break;
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MultiMailListConversationRows:
                    const mailListRowDragData = dragData as MailListRowDragData;
                    const tableViewId = mailListRowDragData.tableViewId;
                    const rowKeys = mailListRowDragData.rowKeys;
                    if (ctrlKey) {
                        const tableView = getListViewStore().tableViews.get(tableViewId);
                        if (tableView) {
                            lazyCopyMailListRows.importAndExecute(
                                rowKeys,
                                tableView,
                                folderId,
                                'Drag'
                            );
                        }
                    } else {
                        lazyMoveMailListRows.importAndExecute(
                            rowKeys,
                            folderId,
                            tableViewId,
                            'Drag'
                        );
                    }
                    break;

                case DraggableItemTypes.MailFolderNode:
                    {
                        const mailFolderItemBeingDragged = dragData as MailFolderDragData;
                        const draggedFolderId = mailFolderItemBeingDragged.folderId;
                        const targetFolder = folderStore.folderTable.get(draggedFolderId);

                        if (targetFolder) {
                            const {
                                mailboxInfo: draggedFolderMailboxInfo,
                                parentFolderId: draggedFolderParentFolderId,
                            } = targetFolder;

                            if (isDragMoveInto) {
                                moveFolder(
                                    folderId /*destinationFolderId */,
                                    mailboxInfo /* destinationFolderMailboxInfo */,
                                    draggedFolderId /* sourceFolderId */,
                                    draggedFolderMailboxInfo /* sourceFolderMailboxInfo */,
                                    draggedFolderParentFolderId /* parentFolderId */,
                                    mailFolderItemBeingDragged.displayName
                                );
                            } else if (getIsCustomSortEnabled(mailboxInfo)) {
                                lazyMoveFolderPosition.importAndExecute(
                                    moveFolderPositionMutation,
                                    {
                                        folderId: draggedFolderId,
                                        destinationFolderId: folderId,
                                        mailboxInfo,
                                    }
                                );
                                logUsage('FP_MoveFolderPos_DragMove');
                            }
                        }
                    }
                    break;
                case DraggableItemTypes.LocalFile:
                    if (!isFeatureEnabled('mon-file-eml-msg') || !folderId) {
                        break;
                    }
                    const files = (dragData as LocalFilesDragData).files;
                    for (const file of files) {
                        const fileExt = file.name.split('.').pop()?.toLowerCase();
                        if (fileExt === 'eml' || fileExt === 'msg' || fileExt === 'oft') {
                            lazyOpenFileMessage
                                .importAndExecute(file, mailboxInfo)
                                .then((res: OpenFileMessageResponse) => {
                                    // If the file is successfully converted, move the item to the target folder
                                    if (res.item?.ItemId && res.item?.ParentFolderId) {
                                        lazyMoveSingleItemFromItemId.importAndExecute(
                                            res.item.ItemId.Id,
                                            folderId
                                        );
                                    } else if (res.item) {
                                        lazyCreateCopyItem
                                            .importAndExecute(res.item, folderId, mailboxInfo)
                                            .catch(e => {
                                                lazyCreateCopyItemFailedNotification.importAndExecute(
                                                    e.networkError
                                                );
                                            });
                                    }
                                });
                        }
                    }
                    break;
            }
        },
        [folderId, moveFolder, mailboxInfo, isDragMoveInto]
    );

    /**
     * A callback function to check if current drag data is able to drop on current element
     * @param dragData the drag data which will be dropped
     * @param dragEvent the drag event to get the ctrlKey property value
     *
     * @returns the desired drop effect that will be used to update the cursor style
     */
    const canDrop = React.useCallback(
        (dragData: DragData, dragEvent?: React.DragEvent<HTMLElement>): DropEffect => {
            if (
                (folder.distinguishedFolderType === 'notes' ||
                    folder.distinguishedFolderType === 'scheduled') &&
                dragData.itemType !== DraggableItemTypes.MailFolderNode
            ) {
                return 'none';
            }

            const itemType = dragData.itemType;
            switch (itemType) {
                case DraggableItemTypes.MailListItemPart:
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MultiMailListConversationRows: {
                    const tableView = getSelectedTableView();
                    const draggedItemsMailboxInfo = getMailboxInfo(tableView);
                    if (
                        isSameCoprincipalAccountMailboxInfos(
                            draggedItemsMailboxInfo,
                            mailboxInfo
                        ) ||
                        isMoveCopyCrossAccountSupported(draggedItemsMailboxInfo, mailboxInfo)
                    ) {
                        return dragEvent?.ctrlKey ? 'copy' : 'move';
                    }
                    return 'none';
                }

                case DraggableItemTypes.FavoriteNode: {
                    if (dragData.itemData) {
                        const draggedItemData = dragData.itemData as {
                            mailboxInfo: MailboxInfo;
                        };
                        const favoriteMailboxInfo = draggedItemData?.mailboxInfo;
                        return isSameCoprincipalAccountMailboxInfos(
                            favoriteMailboxInfo,
                            mailboxInfo
                        )
                            ? 'move'
                            : 'none';
                    } else {
                        return 'none';
                    }
                }
                case DraggableItemTypes.MailFolderNode:
                    if (dragData.itemData) {
                        const draggedItemData = dragData.itemData as {
                            mailboxInfo: MailboxInfo;
                            draggedFolderId: string;
                            draggedParentId: string;
                        };

                        // disable moving distinguished folders into other folders
                        if (
                            folderStore.folderTable.get(draggedItemData.draggedFolderId)
                                ?.distinguishedFolderType &&
                            (isDragMoveInto ||
                                (!isDragMoveInto &&
                                    draggedItemData.draggedParentId &&
                                    folder.parentFolderId &&
                                    draggedItemData.draggedParentId !== folder.parentFolderId))
                        ) {
                            return 'none';
                        }

                        if (
                            getIsCustomSortEnabled(mailboxInfo) &&
                            !isDragMoveInto &&
                            (isArchiveFolder || isSharedFolder)
                        ) {
                            return 'none';
                        }
                        return isSameCoprincipalAccountMailboxInfos(
                            draggedItemData.mailboxInfo,
                            mailboxInfo
                        )
                            ? 'move'
                            : 'none';
                    } else {
                        return 'none';
                    }
                case DraggableItemTypes.LocalFile:
                    return isFeatureEnabled('mon-file-eml-msg') ? 'copy' : 'none';
                default:
                    return 'none';
            }
        },
        [folder, mailboxInfo, isDragMoveInto]
    );

    const onDragOver = React.useCallback(
        (dragData: DragData) => {
            if (getIsCustomSortEnabled(mailboxInfo)) {
                if (dragData.itemType !== DraggableItemTypes.MailFolderNode) {
                    setisDragMoveInto(true);
                }
            } else {
                setisDragMoveInto(true);
            }
            setIsDragOver(true);
        },
        [mailboxInfo]
    );

    const onDragEnter = React.useCallback(() => {
        if (getIsCustomSortEnabled(mailboxInfo)) {
            setisDragMoveInto(false);
        }
    }, [mailboxInfo]);

    const onDragLeave = React.useCallback(() => {
        setIsDragOver(false);
    }, []);

    const canDrag = React.useCallback(() => {
        // we can only drag if it's not a shared folder, or the user has modify rights to a shared folder
        return treeType !== SHARED_FOLDERS_TREE_TYPE || !!folder.EffectiveRights?.Modify;
    }, [treeType, folder]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            if (isDragOver && isFolderExpandable && !folderViewState.isExpanded) {
                toggleFolderNodeExpansion(folderId);
            }
            if (getIsCustomSortEnabled(mailboxInfo)) {
                setTimeout(() => setisDragMoveInto(isDragOver), 500);
            }
        }, timeIntervalFolderExpand);
        return () => clearTimeout(timer);
    }, [isDragOver, mailboxInfo]);

    const getDragData = React.useCallback(() => {
        const folderNodeDragData: MailFolderDragData = {
            itemType: DraggableItemTypes.MailFolderNode,
            folderId,
            displayName: effectiveFolderDisplayName,
            treeType,
            mailboxInfo,
            parentFolderId: folder.parentFolderId,
            itemData: {
                treeType,
                mailboxInfo,
                draggedFolderId: folderId,
            },
        };
        return folderNodeDragData;
    }, [folderId, effectiveFolderDisplayName, mailboxInfo, folder, treeType]);

    const isSearchFolder =
        folder.distinguishedFolderType === SEARCH_FOLDER_ROOT_DISTINGUISHED_ID ||
        folder.type === SEARCH_FOLDER_TYPE_NAME ||
        (distinguishedFolderParentIds &&
            distinguishedFolderParentIds.indexOf(SEARCH_FOLDER_ROOT_DISTINGUISHED_ID) > -1);

    const isArchiveFolder = treeType === ARCHIVE_FOLDERS_TREE_TYPE;

    const isSharedFolder = treeType === SHARED_FOLDERS_TREE_TYPE;

    const isNewAndUnloadedSearchFolder =
        isSearchFolder && folder.totalMessageCount == 0 && !isFolderLoaded(folderId);

    const isReadOnlyMailbox = !isCapabilityEnabled(mailboxModificationCapability, mailboxInfo);

    const supportsDraggingOrDropping =
        !isSearchFolder /*Search folder nodes cannot be moved or have items dropped into them*/ &&
        (!isReadOnlyMailbox ||
            isFeatureEnabled(
                'acct-pstFileImportExport'
            )); /*Read-only mailbox folders cannot be moved or have items dropped into them. Only allow if pst FileImportExport is ON */

    const deleteFolder = React.useCallback(() => {
        // Only delete if it's not a shared folder, or the user has delete rights to the shared folder, and the mailbox is not read-only
        if (
            (treeType != SHARED_FOLDERS_TREE_TYPE || folder.EffectiveRights?.Delete) &&
            !isReadOnlyMailbox
        ) {
            props.deleteFolder(folderId, isSearchFolder, distinguishedFolderParentIds);
        }
    }, [
        treeType,
        folder,
        props.deleteFolder,
        folderId,
        isSearchFolder,
        distinguishedFolderParentIds,
        isReadOnlyMailbox,
    ]);

    const customFolderColor = getCustomFolderColorHexValue(folderViewState.color);

    const folderNodeElement = (
        <FolderNode
            chevronProps={chevronProps}
            customIconComponent={getCustomBundledIcon(folderId, false)}
            depth={depth}
            displayName={effectiveFolderDisplayName}
            folderId={folderId}
            isDroppedOver={folderViewState.drop?.isDragOver && isDragOver}
            isBeingDragged={isBeingDragged}
            onContextMenu={onContextMenu_0}
            treeType={treeType}
            shouldHideToggleFavorite={shouldHideToggleFavorite}
            showHoverStateOnDroppedOver={isDragMoveInto}
            totalCount={folder.totalMessageCount}
            unreadCount={folder.UnreadCount}
            distinguishedFolderId={folder.distinguishedFolderType ?? ''}
            isLoading={isNewAndUnloadedSearchFolder}
            deleteFolder={deleteFolder}
            ellipsesOnHover={ellipsesOnHover}
            customIconColor={customFolderColor}
        />
    );

    return (
        <>
            {
                /**
                 * Render edit node if renaming
                 */
                renderInEditMode &&
                    renderAddNewOrEditNode(
                        folderId,
                        treeType,
                        depth,
                        'rename',
                        effectiveFolderDisplayName
                    )
            }
            {
                /**
                 * Render node if not editing
                 */
                !renderInEditMode &&
                    (!supportsDraggingOrDropping
                        ? folderNodeElement
                        : folderViewState.drop && (
                              <DragAndDroppable
                                  dragViewState={folderViewState.drag}
                                  getDragData={getDragData}
                                  getDragPreview={getDragPreview}
                                  xOffset={DRAG_X_OFFSET}
                                  yOffset={DRAG_Y_OFFSET}
                                  dropViewState={folderViewState.drop}
                                  onDrop={onDrop}
                                  canDrop={canDrop}
                                  onDragOver={onDragOver}
                                  onDragEnter={onDragEnter}
                                  onDragLeave={onDragLeave}
                                  canDrag={canDrag}
                              >
                                  {folderNodeElement}
                              </DragAndDroppable>
                          ))
            }
            {
                /**
                 * Render add new sub node
                 */
                renderAddNewNode && renderAddNewOrEditNode(folderId, treeType, depth + 1, 'new')
            }
        </>
    );
}, 'MailFolderNode');

function getDragPreview(folderNodeDragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragHelperStyle;
    elem.innerText = (folderNodeDragData as MailFolderDragData).displayName;
    return elem;
}
