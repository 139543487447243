import { M365AcquisitionsDocument } from 'owa-m365-acquisitions/lib/graphql/query/__generated__/M365Acquisitions.interface';
import {
    getBootM365Acquisitions,
    toBootM365AcquisitionsQuery,
} from 'owa-m365-acquisitions/lib/getBootM365Acquisitions';
import { errorThatWillCauseAlert } from 'owa-trace';
import { writeQuery } from 'owa-apollo';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { updateServiceConfig } from 'owa-service/lib/config';
import { setLocalPinnedApps } from 'owa-m365-acquisitions/lib/pinnedApps/getAppBarPinnedAppIdsFromUserSettings';
import getModuleContextMailboxInfo from 'owa-module-context-mailboxinfo/lib/selectors/getModuleContextMailboxInfo';
import { writeM365AppsQuery } from 'owa-nova-cache/lib/transform/writeM365AppsQuery';
import { lazyWriteToAcquisitionsCacheFromIndexedDb } from 'owa-m365-acquisitions-database/lib/lazyFunctions';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import checkFeatureFlagLoadStateForMailboxInfo from 'owa-feature-flags/lib/utils/checkFeatureFlagLoadStateForMailboxInfo';
import { lazyQueueAcquisitionsNetworkCall } from 'owa-m365-acquisitions/lib/lazyFunction';

/**
 * This function should only be called ONCE on OWA bootstrap. Do NOT call this outside of bootstrap
 * and do not put anything that is not boot-path-critical here.
 */
export async function bootstrapApolloCaches(mailboxInfo?: MailboxInfo) {
    updateServiceConfig({
        onPinnedAppsChanged: (mboxInfo, newSetting) => {
            setLocalPinnedApps(mboxInfo, newSetting);
        },
        getModuleContextMailboxInfo: () => {
            return Promise.resolve(getModuleContextMailboxInfo());
        },
    });

    const dp = new PerformanceDatapoint('M365AcquisitionsCachePriming');
    try {
        // write main acquisitions cache containing static apps/modules
        const m365Acquisitions = await getBootM365Acquisitions(mailboxInfo);

        // check if feature flags are loaded for the account associated with the mailboxInfo
        checkFeatureFlagLoadStateForMailboxInfo(mailboxInfo);
        writeQuery(M365AcquisitionsDocument, {
            data: toBootM365AcquisitionsQuery(m365Acquisitions),
            variables: {
                mailboxInfo,
            },
        });

        // write static apps to app bar for initial render
        void writeM365AppsQuery(m365Acquisitions, mailboxInfo);

        if (isFeatureEnabled('mos-ribbonSurfaceAppCache')) {
            // read cached acquisitions from local IDB cache and write them to apollo
            void lazyWriteToAcquisitionsCacheFromIndexedDb
                .importAndExecute(mailboxInfo)
                .then(async acquisitionsFromIndexedDb => {
                    if (acquisitionsFromIndexedDb.length !== 0) {
                        await lazyQueueAcquisitionsNetworkCall.importAndExecute(mailboxInfo);
                    }
                });
        }

        dp.end();
    } catch (e) {
        errorThatWillCauseAlert('M365Acquisitions_CachePriming_Error', e);
        dp.endWithError(DatapointStatus.ClientError, e);
    }
}
