import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function isGeneric1PAddinSupported(
    mailboxInfo: MailboxInfo,
    dontThrowErrorIfNotInitialized?: boolean
): boolean {
    return isFeatureEnabled(
        'mon-copilot-ext-enableGeneric1PAddinSupport',
        mailboxInfo,
        dontThrowErrorIfNotInitialized
    );
}
