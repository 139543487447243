import { mutatorAction } from 'satcheljs';
import type { FavoriteTreeData } from '../store/schema/FavoritesStore';
import favoritesStore from '../store/store';

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
export const setFavoriteTreeData = mutatorAction(
    'setFavoriteTreeData',
    (mailboxInfoKey: string, favoriteTreeData: FavoriteTreeData) => {
        favoritesStore.favoriteTreeData.set(mailboxInfoKey, favoriteTreeData);
    }
);
