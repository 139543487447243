import type { MailboxInfo, MailboxType } from 'owa-client-types';
import getFolderTable from '../selectors/getFolderTable';
import {
    ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { generateMailboxSmtpAddress } from './mailboxUtility';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    getGlobalSettingsAccountMailboxInfo,
    getArchiveMailboxInfo,
    getSharedMailboxInfo,
} from 'owa-account-source-list-store';
import compareMailboxInfos from 'owa-mailbox-info/lib/compareMailboxInfos';
import {
    default as isMailboxPackageFlightEnabled,
    isMailboxCompareFlightEnabled,
} from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import { getMailboxInfoForArchive } from 'owa-mailbox-info/lib/getMailboxInfoForArchive';
import { getMailboxInfoForAuxArchive } from 'owa-mailbox-info/lib/getMailboxInfoForAuxArchive';
import { getMailboxInfoForCoprincipalAccount } from 'owa-mailbox-info/lib/getMailboxInfoForCoprincipalAccount';
import { getMailboxInfoForShared } from 'owa-mailbox-info/lib/getMailboxInfoForShared';
import type { MailFolder } from 'owa-graph-schema';

function getMailboxInfoForFolder(
    folderId: string,
    folder: MailFolder | undefined,
    mailboxInfo: MailboxInfo,
    routeToAuxIfAuxArchive: boolean
): MailboxInfo {
    let auxArchiveMailboxGuid;

    if (folder?.mailboxInfo.type === 'SharedMailbox') {
        return getMailboxInfoForShared(mailboxInfo, folder.mailboxInfo.mailboxSmtpAddress);
    } else if (
        folderId == ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID ||
        folderId == ARCHIVE_DUMPSTER_DISTINGUISHED_ID ||
        folder?.mailboxInfo.type == 'ArchiveMailbox'
    ) {
        // folderId check is required because when fetching the folder list for the archive, the root folder is not
        // yet present in the folder table
        auxArchiveMailboxGuid = folder?.replicaList?.[0];

        // Most requests should be routed to the mailbox the folder is in.
        // However, for folder operations on auxilliary archive folders,
        // the request always needs to go to the main archive mailbox.
        if (auxArchiveMailboxGuid && routeToAuxIfAuxArchive) {
            return getMailboxInfoForAuxArchive(mailboxInfo, auxArchiveMailboxGuid);
        }

        return getMailboxInfoForArchive(mailboxInfo);
    } else {
        return getMailboxInfoForCoprincipalAccount(mailboxInfo);
    }
}

/**
 * Fetches mailbox info for a given folder
 * @param folderId Id of the folder
 * @param routeToAuxIfAuxArchive whether to route the request to the the aux archive if the folder
 * belongs to aux archive mailbox (should only be false for some folder operations).
 */
export function getMailboxInfoForFolderId(
    folderId: string,
    routeToAuxIfAuxArchive: boolean
): MailboxInfo {
    const folderTable = getFolderTable();
    const folder = folderTable?.get(folderId);

    let mailboxSmtpAddress: string;
    let mailboxGuid: string | null | undefined; // set to archive mailbox for archive folders, null otherwise
    let auxArchiveMailboxGuid;
    let mailboxType: MailboxType | undefined;
    let sourceId: string | null | undefined;

    const isMailboxPacakgeFlightEnabled = isMailboxPackageFlightEnabled();
    const mailboxInfo: MailboxInfo = folder?.mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const mailboxInfoFromFlight = isMailboxPacakgeFlightEnabled
        ? getMailboxInfoForFolder(folderId, folder, mailboxInfo, routeToAuxIfAuxArchive)
        : mailboxInfo;

    if (isMailboxPacakgeFlightEnabled && !isMailboxCompareFlightEnabled()) {
        // We are not comparing just return the value
        return mailboxInfoFromFlight;
    }

    if (folder?.mailboxInfo.type === 'SharedMailbox') {
        mailboxSmtpAddress = folder.mailboxInfo.mailboxSmtpAddress;
        mailboxType = 'SharedMailbox';
        sourceId = getSharedMailboxInfo(mailboxInfo, mailboxSmtpAddress)?.sourceId;
    } else if (
        folderId == ARCHIVE_FOLDER_ROOT_DISTINGUISHED_ID ||
        folderId == ARCHIVE_DUMPSTER_DISTINGUISHED_ID ||
        folder?.mailboxInfo.type == 'ArchiveMailbox'
    ) {
        // folderId check is required because when fetching the folder list for the archive, the root folder is not
        // yet present in the folder table
        auxArchiveMailboxGuid = folder?.replicaList?.[0];

        // Most requests should be routed to the mailbox the folder is in.
        // However, for folder operations on auxilliary archive folders,
        // the request always needs to go to the main archive mailbox.
        if (auxArchiveMailboxGuid && routeToAuxIfAuxArchive) {
            mailboxGuid = auxArchiveMailboxGuid;
        } else {
            mailboxGuid = getAccountScopeUserSettings(
                folder?.mailboxInfo ?? getModuleContextMailboxInfo()
            ).SessionSettings?.ArchiveMailboxGuid;
            auxArchiveMailboxGuid = undefined;
        }

        mailboxType = 'ArchiveMailbox';
        mailboxSmtpAddress = generateMailboxSmtpAddress(
            mailboxGuid,
            getAccountScopeUserSettings(folder?.mailboxInfo ?? getModuleContextMailboxInfo())
                .SessionSettings?.OrganizationDomain
        );

        sourceId = getArchiveMailboxInfo(mailboxInfo, mailboxGuid || '')?.sourceId;
    } else {
        // oneMailView - folder cleanup
        mailboxSmtpAddress = mailboxInfo.mailboxSmtpAddress;
        sourceId = mailboxInfo.sourceId;
    }

    /* eslint-disable-next-line owa-custom-rules/require-mailbox-info-package -- (https://aka.ms/OWALintWiki)
     * To create a MailboxInfo object deep import and call the getMailboxInfoFor* function for the type of MailboxInfo you are creating.
     *	> MailboxInfo must be obtained from the owa-mailbox-info package. */
    const result: MailboxInfo = {
        type: mailboxType ?? mailboxInfo.type,
        sourceId,
        userIdentity: mailboxInfo.userIdentity,
        mailboxSmtpAddress,
        auxiliaryMailboxGuid: auxArchiveMailboxGuid,
    };

    if (isMailboxPacakgeFlightEnabled) {
        compareMailboxInfos(result, mailboxInfoFromFlight);
        return mailboxInfoFromFlight;
    }

    return result;
}
