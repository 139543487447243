import { observer } from 'owa-mobx-react';
import FavoriteCategoryNode from '../FavoriteCategoryNode';
import FavoriteFolderNode from '../FavoriteFolderNode';
import FavoriteGroupNode from '../FavoriteGroupNode';
import FavoritePersonaNode from '../FavoritePersonaNode';
import type { FavoriteNodeCommonProps } from '../FavoriteNode';
import { getStore as getSharedFavoritesStore } from 'owa-favorites';
import folderStore from 'owa-folders';
import {
    getFavoriteNodeViewStateFromId,
    lazyInitializeFavoriteNodeViewState,
} from 'owa-mail-favorites-store';
import React from 'react';
import type {
    FavoriteFolderData,
    FavoriteGroupData,
    FavoriteCategoryData,
    FavoritePersonaData,
    FavoritePublicFolderData,
} from 'owa-favorites-types';
import { getAccountAuthStateForMailboxInfo, StateTypeEnum } from 'owa-account-authstate-store';

export default observer(function FavoriteNodeV2(props: FavoriteNodeCommonProps) {
    const createFolderFavorite = (favoriteFolderData: FavoriteFolderData): JSX.Element | null => {
        const folderId = favoriteFolderData.folderId;
        const folder = folderStore.folderTable.get(folderId);
        if (folder) {
            return (
                <FavoriteFolderNode
                    key={props.favoriteId}
                    folderId={folderId}
                    ellipsesOnHover={props.ellipsesOnHover}
                    {...props}
                />
            );
        }
        return null;
    };
    const createGroupFavorite = (favoriteGroupData: FavoriteGroupData) => {
        return (
            <FavoriteGroupNode
                key={props.favoriteId}
                displayName={favoriteGroupData.displayName}
                groupId={favoriteGroupData.groupId}
                mailboxInfo={favoriteGroupData.mailboxInfo}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const createCategoryFavorite = (favoriteCategoryData: FavoriteCategoryData) => {
        const favoriteViewState = getFavoriteNodeViewStateFromId(props.favoriteId);
        if (favoriteViewState?.drop) {
            return (
                <FavoriteCategoryNode
                    key={props.favoriteId}
                    categoryId={favoriteCategoryData.categoryId}
                    dropViewState={favoriteViewState.drop}
                    mailboxInfo={favoriteCategoryData.mailboxInfo}
                    ellipsesOnHover={props.ellipsesOnHover}
                    {...props}
                />
            );
        } else {
            return null;
        }
    };
    const createPersonaFavorite = (favoritePersonaData: FavoritePersonaData) => {
        if (favoritePersonaData.mainEmailAddress) {
            const favoriteViewState = getFavoriteNodeViewStateFromId(props.favoriteId);
            if (favoriteViewState?.drop) {
                return (
                    <FavoritePersonaNode
                        key={props.favoriteId}
                        displayName={favoritePersonaData.displayName}
                        emailAddress={favoritePersonaData.mainEmailAddress}
                        personaId={favoritePersonaData.personaId ?? ''}
                        searchFolderId={favoritePersonaData.searchFolderId ?? ''}
                        isSearchFolderPopulated={favoritePersonaData.isSearchFolderPopulated}
                        mailboxInfo={favoritePersonaData.mailboxInfo}
                        dropViewState={favoriteViewState.drop}
                        isJustAdded={false}
                        ellipsesOnHover={props.ellipsesOnHover}
                        {...props}
                    />
                );
            } else {
                return null;
            }
        }
        return null;
    };
    const createPublicFolderFavorite = (favoritePublicFolderData: FavoritePublicFolderData) => {
        return (
            <FavoriteFolderNode
                key={props.favoriteId}
                folderId={favoritePublicFolderData.publicFolderId}
                isPublicFolder={true}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const createLocalFolderFavorite = (localFolderData: FavoriteFolderData) => {
        return (
            <FavoriteFolderNode
                key={props.favoriteId}
                folderId={localFolderData.folderId}
                ellipsesOnHover={props.ellipsesOnHover}
                {...props}
            />
        );
    };
    const favoritesStore = getSharedFavoritesStore();
    let favoriteData = undefined;
    if (favoritesStore.outlookFavorites.has(props.favoriteId)) {
        favoriteData = favoritesStore.outlookFavorites.get(props.favoriteId);
    }

    React.useEffect(() => {
        if (favoriteData?.type == 'category' || favoriteData?.type == 'persona') {
            lazyInitializeFavoriteNodeViewState.importAndExecute(props.favoriteId);
        }
    }, [favoriteData?.type]);

    if (
        favoriteData &&
        getAccountAuthStateForMailboxInfo(favoriteData.mailboxInfo).stateType ===
            StateTypeEnum.NoError
    ) {
        switch (favoriteData?.type) {
            case 'folder':
                return createFolderFavorite(favoriteData as FavoriteFolderData);
            case 'group':
                return createGroupFavorite(favoriteData as FavoriteGroupData);
            case 'category':
                return createCategoryFavorite(favoriteData as FavoriteCategoryData);
            case 'persona':
                return createPersonaFavorite(favoriteData as FavoritePersonaData);
            case 'publicFolder':
                return createPublicFolderFavorite(favoriteData as FavoritePublicFolderData);
            case 'localFolder':
                return createLocalFolderFavorite(favoriteData as FavoriteFolderData);
            default:
                throw new Error('FavoriteNodeV2 - favorite kind not supported');
        }
    }

    // Invalid unknown data
    return null;
}, 'FavoriteNodeV2');
