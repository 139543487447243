import { mutatorAction } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import getPublicFolderMailboxInfoForSmtpAddress from 'owa-public-folder-favorite/lib/services/utils/getPublicFolderMailboxInfoForSmtpAddress';
import getStore from '../store/store';
import { getUserConfiguration } from 'owa-session-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

export default function getPublicFolderMailboxInfo(
    folderId: string | null | undefined
): MailboxInfo {
    if (!folderId) {
        return getGlobalSettingsAccountMailboxInfo();
    }

    if (!getStore().has(folderId)) {
        const publicFolderMailboxInfoForSmtpAddress =
            publicFolderFavoriteStore.folderTable.get(folderId)?.replicaList?.[0] ??
            getUserConfiguration().SessionSettings?.DefaultPublicFolderMailbox;
        if (!publicFolderMailboxInfoForSmtpAddress) {
            return getGlobalSettingsAccountMailboxInfo();
        }

        // HACK
        // We shouldn't be adding to stores within gettors as this breaks the principles
        // of one directional data flow. However, this is a temporary solution to avoid
        // a mutator calling another mutator by pussing the adding to the store in a
        // microtask.
        Promise.resolve().then(() => {
            const publicFolderMailboxInfo = getPublicFolderMailboxInfoForSmtpAddress(
                publicFolderMailboxInfoForSmtpAddress
            );
            addToStore(folderId, publicFolderMailboxInfo);
        });
    }

    return getStore().get(folderId) ?? getGlobalSettingsAccountMailboxInfo();
}

/* eslint-disable-next-line owa-custom-rules/require-add-identifier-to-mutator-action-variables -- (https://aka.ms/OWALintWiki)
 * Mutator action variables should end with 'Mutator' so that we can more easily identify potential misuses of it.
 *	> Please add 'Mutator' substring add the end of the mutator action variable name. */
const addToStore = mutatorAction(
    'addPublicFolderMailboxInfoToStore',
    (folderId: string, publicFolderMailboxInfo: MailboxInfo) => {
        getStore().set(folderId, publicFolderMailboxInfo);
    }
);
