import React from 'react';
import { observer } from 'owa-mobx-react';
import { AppBar } from '@office-start/appbar-control';
import { AccountSwitcher } from 'owa-account-switcher';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isCloudCacheScenario } from 'owa-left-rail-utils/lib/isCloudCacheScenario';
import { isEasiIdUser } from 'owa-cloud-cache-accounts-option';
import { FluentProvider } from '@fluentui/react-components';
import { AppStoreModal, AppStoreDetailsDialog } from 'owa-app-host-components';
import { getPalette } from 'owa-theme';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { createFluentTheme } from '../utils/createFluentTheme';
import { RegisterForAccountStateChange } from 'owa-account-source-list';
import { bootstrapM365AcquisitionsCache } from 'owa-m365-acquisitions-cache';
import type { AccountSource } from 'owa-account-source-list-store';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { monarchAwareLeftRail, leftRail, multiAccountContainer, bleedThrough } from './AppBar.scss';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { trySetBposNavBarData } from 'owa-bpos-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyInitializeAppBarPinnedAppsUserSetting } from 'owa-appbar-pinnedapps';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import className from 'owa-classnames';
import { isCopilotStoreReady } from 'owa-copilot-settings-store';
import { checkThatMailboxInfoIsForStartedAccount } from 'owa-mailbox-info-checks';
import { lazyWriteToAcquisitionsCacheFromIndexedDb } from 'owa-m365-acquisitions-database/lib/lazyFunctions';
import { refreshAppBarAcquisitions } from '../utils/refreshAppBarAcquisitions';

const FluentProviderStyle = { backgroundColor: 'transparent', height: '100%' };

export default observer(function WrappedAppBar() {
    const isCloudCacheMultiAccount = isCloudCacheScenario() || isEasiIdUser();
    const mailboxInfo = isAddinMultiAccountEnabled() ? getModuleContextMailboxInfo() : undefined;
    const ocsMailboxInfo = mailboxInfo ?? getGlobalSettingsAccountMailboxInfo();
    const copilotStoreReady = isCopilotStoreReady(ocsMailboxInfo);

    React.useEffect(() => {
        (async () => {
            if (mailboxInfo) {
                checkThatMailboxInfoIsForStartedAccount(mailboxInfo);
            }

            if (isFeatureEnabled('mos-BizChatOCS') && copilotStoreReady) {
                // refresh hardcoded apps once store is ready for BizChat license check
                await bootstrapM365AcquisitionsCache(mailboxInfo);
            }

            // on account switch, redraw the app bar with the cached acquisitions
            await refreshAppBarAcquisitions(mailboxInfo);
        })();
    }, [mailboxInfo?.userIdentity, copilotStoreReady]);

    React.useEffect(() => {
        if (isAddinMultiAccountEnabled()) {
            // After initial render, initialze app bar data for each connected account
            // 1. Initialize pinned apps settings
            // 2. Populate bpos store with data from user settings
            // 3. Prime acquisitions cache with static data
            RegisterForAccountStateChange(
                (accountSource: AccountSource) => {
                    const accountMailboxInfo = accountSource.mailboxInfo;
                    lazyInitializeAppBarPinnedAppsUserSetting.importAndExecute(accountMailboxInfo);
                    // The global settings account's static acquisitions data is already in cache. It's done pre-render
                    if (
                        getIndexerValueForMailboxInfo(accountMailboxInfo) !==
                        getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo())
                    ) {
                        const userSettings = getAccountScopeUserSettings(accountMailboxInfo);
                        if (userSettings?.NavBarData) {
                            trySetBposNavBarData(userSettings.NavBarData, accountMailboxInfo);
                        }

                        if (isFeatureEnabled('mos-ribbonSurfaceAppCache')) {
                            // Prime acquisitions cache with  data from indexedDB
                            lazyWriteToAcquisitionsCacheFromIndexedDb
                                .importAndExecute(accountMailboxInfo)
                                .then(acquisitionsFromIndexedDb => {
                                    if (acquisitionsFromIndexedDb.length === 0) {
                                        // If no data was written from indexedDB, we should boostrap with static apps
                                        bootstrapM365AcquisitionsCache(accountMailboxInfo);
                                    }
                                });
                        } else {
                            // Prime acquisitions cache with static data for each connected account
                            bootstrapM365AcquisitionsCache(accountMailboxInfo);
                        }
                    }
                },
                (_accountSource: AccountSource) => {} // no-op the pre-account removed callback
            );
        }
    }, []);

    const useMonarchAwareStyles = isFeatureEnabled('mon-transparency-usingSystemBackdrop');
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    return (
        <>
            <AppStoreModal mailboxInfo={mailboxInfo} />
            <AppStoreDetailsDialog mailboxInfo={mailboxInfo} />
            <div
                className={className(
                    useMonarchAwareStyles ? monarchAwareLeftRail : leftRail,
                    isBleedThroughEnabled && bleedThrough
                )}
                id="LeftRail"
            >
                {isCloudCacheMultiAccount && (
                    <div className={multiAccountContainer}>
                        {isFeatureEnabled('auth-cloudCache') && <AccountSwitcher />}
                    </div>
                )}
                <FluentProvider
                    theme={createFluentTheme(getPalette())}
                    style={FluentProviderStyle}
                    dir={isCurrentCultureRightToLeft() ? 'rtl' : 'ltr'}
                >
                    <AppBar />
                </FluentProvider>
            </div>
        </>
    );
}, 'WrappedAppBar');
