import { owaComputedFn } from 'owa-computed-fn';
import type {
    AppButtonProps,
    AppCompoundButtonProps,
    AppFlyoutAnchorProps,
} from '@1js/acui-button';
import type { AppFlyoutAnchorProps as NextAppFlyoutAnchorProps } from '@1js/acui-button/lib/components/AppFlyoutAnchor/next/AppFlyoutAnchor.types';
import type { AppCheckboxProps, AppCheckboxOnExecuteParameter } from '@1js/acui-checkbox';
import type { MenuDefinition } from '@1js/acui-menu';
import type {
    MenuItemControlProps,
    RibbonIconSet,
    RibbonSplitButtonProps,
    RibbonToggleButtonProps,
} from '@1js/acui-ribbon-like';
import type { RibbonSplitButtonProps as NextRibbonSplitButtonProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonSplitButton/next';
import type { AppSearchBoxProps } from '@1js/acui-search-box';
import type { IButtonStyles, IKeytipProps } from '@fluentui/react';
import { getRibbonViewMode } from 'owa-command-ribbon-store';
import { getIsMsHighContrast } from 'owa-high-contrast';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import {
    AppButton,
    AppCheckBox,
    AppCompoundButton,
    AppFlyoutAnchor,
    AppSearchBox,
    AppSplitButton,
    AppToggleButton,
    getRibbonIconColor,
} from '../index';
import { logUsage } from 'owa-analytics';
import {
    lazyIsLightIconFontEnabled,
    lazyRegisterLightIcon,
} from 'owa-command-ribbon-light-icon-font';
import { isFeatureEnabled } from 'owa-feature-flags';
import { heroButton } from './heroButton.scss';
import { isSingleLineRibbon } from 'owa-command-ribbon-store/lib/selectors/isSingleLineRibbon';

type OptionalIconSet = {
    iconSet?: RibbonIconSet;
};

/**
 * In order to make High Contrast more-or-less work, we need to remove all styling from our ribbon buttons.
 * If we are NOT in HC, we'll use any styling passed in, or fall back to default styles for the current
 * ribbon mode.
 */
function getAppButtonStyles(styles: IButtonStyles | undefined): IButtonStyles | undefined {
    return getIsMsHighContrast() ? undefined : styles;
}

const getComputedIconSet = owaComputedFn(function getComputedIconSet(
    icon: string | undefined
): RibbonIconSet | undefined {
    if (!icon) {
        return undefined;
    }

    let isLightIconFontEnabled;
    let registerLightIcon;
    let icon32;

    if (isFeatureEnabled('mon-ribbon-light-icon-font')) {
        isLightIconFontEnabled = lazyIsLightIconFontEnabled.tryImportForRender();

        if (isLightIconFontEnabled?.(icon)) {
            registerLightIcon = lazyRegisterLightIcon.tryImportForRender();
            icon32 = registerLightIcon?.(icon);
        }
    }

    return {
        default: icon,
        ...(icon32 && { icon32 }),
    };
});

/**
 * Returns the color that should be used for a given control's
 * icon. In order of priority:
 * 0. Outside the purview of this method, `overrides` parameter provided to createAppButton/createAppMenu/etc.
 * will be applied over the value returned from this method.
 * 1. If we're in high contrast mode, appropriate high contrast colors will be applied. This functionality is
 * provided by default by @1js/acui-ribbon-like when iconColor and style arguments are undefined.
 * 2. An explicit `iconColor` parameter provided to createAppButton/createAppMenuItem (or similar). Note this
 * value is used for all interactive states, so consider contrast requirements for rest, hover, pressed, etc.
 * across all themes (high contrast black, high contrast white, dark mode, light mode).
 * 3. Create and register an iconColorMap with initializeIconColorMap containing an entry for your control's `id`
 * @param id
 * @param iconColor
 */
function getIconColor(id: number | undefined, iconColor: string | undefined): string | undefined {
    if (getIsMsHighContrast()) {
        return undefined;
    }

    if (iconColor !== undefined) {
        return iconColor;
    }

    return id ? getRibbonIconColor(id) : undefined;
}

/**
 * Returns the string and parsed number representation of an id.
 * This is especially important for telemetry purposes, as many ids have appended data to them for uniqueness.
 * e.g.
 *  formatId('35_<editorId>') will return '35' here, which is just the base id.
 */
function formatId(id: string | number): [string, number] {
    if (typeof id === 'number') {
        return [id.toString(), id];
    }

    const idNumber: number = parseInt(id);
    if (!isNaN(idNumber)) {
        return [id, idNumber];
    }
    return [id, -1];
}

/**
 * creates RibbonButtonProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for button
 * @param icon Optional id of an icon to be displayed. Typically ControlIcons. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object.
 * @param overrides Optional object of other RibbonButtonProps props to override.
 * @param isHero Indicates a "Hero" style button in SLR
 */
export const createAppButton = (
    id: string | number,
    label: string,
    onExecute: NonNullable<AppButtonProps['onExecute']>,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    overrides?: Partial<AppButtonProps>,
    isHero?: boolean
): AppButtonProps & OptionalIconSet => {
    const [idString, idNumber] = formatId(id);

    const isFluentV9Enabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');
    const usingHeroStyle = isHero && isFluentV9Enabled && isSingleLineRibbon();

    return {
        type: AppButton,
        id: idString,
        label,
        icon, // Used for buttons within a Flyout menu
        iconSet: getComputedIconSet(icon), // Used for standard buttons on the ribbon (not in a flyout menu)
        iconColor: usingHeroStyle ? 'inherit' : getIconColor(idNumber, iconColor),
        styles: isFluentV9Enabled ? undefined : getAppButtonStyles(styles),
        className: usingHeroStyle ? heroButton : undefined,
        keytipProps,
        onExecute: parameter => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute(parameter);
        },
        ...overrides,
    };
};

/**
 * creates RibbonButtonProps object for use with the WAC Ribbon package. Button handles selected state.
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param toggled Whether the button should be selected
 * @param keytipProps Optional keytip for button
 * @param icon Optional id of an icon to be displayed. Typically ControlIcons. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional style object.
 * @param overrides Optional object/array of other RibbonButtonProps props to override.
 */
export const createAppToggleButton = (
    id: number | string,
    label: string,
    onExecute: NonNullable<RibbonToggleButtonProps['onExecute']>,
    toggled: boolean,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    overrides?: Partial<RibbonToggleButtonProps>
): RibbonToggleButtonProps & {
    icon?: string;
} => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppToggleButton,
        id: idString,
        label,
        icon, // Used for buttons within a Flyout menu
        iconSet: getComputedIconSet(icon), // Used for standard buttons on the ribbon (not in a flyout menu)
        iconColor: getIconColor(idNumber, iconColor),
        styles: getAppButtonStyles(styles),
        onExecute: parameter => {
            logUsage('RibbonToggleButtonInvoked', {
                ribbonId: idNumber,
                viewMode: getRibbonViewMode(),
                toggledState: toggled,
            });
            onExecute(parameter);
        },
        toggled,
        keytipProps,
        ...overrides,
    };
};

/**
 * creates MenuItemControlProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional style object.
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createAppMenuItem = (
    id: string | number,
    label: string,
    onExecute: () => void,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    overrides?: any
): MenuItemControlProps => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppButton,
        id: idString,
        label,
        icon, // Used for buttons within a Flyout menu
        iconSet: getComputedIconSet(icon), // Used for standard buttons on the ribbon (not in a flyout menu)
        iconColor: getIconColor(idNumber, iconColor),
        styles: getAppButtonStyles(styles),
        onExecute: () => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute();
        },
        ...overrides,
    };
};

/**
 * creates MenuItemControlProps object for use with the WAC Ribbon package. Used for generated menu items (i.e. from a custom list of categories)
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for menu item
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object.
 * @param customTooltip Optional hover tooltip
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createGeneratedMenuItem = (
    id: number | string,
    label: string,
    onExecute: () => void,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    customTooltip?: string,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    overrides?: any
): MenuItemControlProps => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppButton,
        id: idString,
        label,
        icon, // Used for buttons within a Flyout menu
        iconColor: getIconColor(undefined /* id */, iconColor),
        styles: getAppButtonStyles(styles),
        customTooltip,
        onExecute: () => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute();
        },
        keytipProps,
        ...overrides,
    };
};

/**
 * creates MenuItemControlProps object for use with the WAC Ribbon package. Used for generated menu items (i.e. from a custom list of categories). Button handles selected state
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param toggled Whether the button should be selected
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional style object.
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createGeneratedToggleMenuItem = (
    id: string,
    label: string,
    onExecute: NonNullable<RibbonToggleButtonProps['onExecute']>,
    toggled: boolean,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    overrides?: any
): MenuItemControlProps => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppToggleButton,
        id: idString,
        label,
        icon, // Used for buttons within a Flyout menu
        iconColor,
        styles: getAppButtonStyles(styles),
        onExecute: parameter => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute(parameter);
        },
        toggled,
        ...overrides,
    };
};

/**
 * Creates RibbonFlyoutAnchorProps object for use with the WAC Ribbon package.
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param menuDefinition Object defining the menu shown when the flyout is invoked
 * @param toggled Whether the button should be selected
 * @param keytipProps Optional keytip for menu item
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object.
 * @param overrides Optional object/array of other MenuItemControlProps props to override.
 */
export const createAppFlyoutAnchor = (
    id: string | number,
    label: string,
    menuDefinition: MenuDefinition,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    overrides: Partial<AppFlyoutAnchorProps> & Partial<NextAppFlyoutAnchorProps> = {}
): AppFlyoutAnchorProps & OptionalIconSet => {
    const { onExecute, ...remainingOverrides } = overrides;
    const [idString, idNumber] = formatId(id);
    const isFluentV9Enabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');

    return {
        type: AppFlyoutAnchor,
        id: idString,
        label,
        keytipProps,
        icon,
        iconSet: getComputedIconSet(icon),
        iconColor: getIconColor(idNumber, iconColor),
        styles: isFluentV9Enabled ? undefined : getAppButtonStyles(styles),
        menuDefinition,
        shadowRenderMenu: false,
        onExecute: parameter => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute?.(parameter);
        },
        ...remainingOverrides,
    };
};

/**
 * creates RibbonSplitButtonProps object for use with the WAC Ribbon package.
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param menuDefinition Object defining the menu shown when the flyout is invoked
 * @param onExecute Function invoked when the button is executed
 * @param keytipProps Optional keytip for menu item
 * @param icon Optional id of an icon to be displayed. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object.
 * @param overrides Optional object of other RibbonSplitButtonProps props to override.
 * @param isHero Indicates a "Hero" style button in SLR
 */
export const createAppSplitButton = (
    id: number | string,
    label: string,
    menuDefinition: MenuDefinition,
    onExecute: NonNullable<RibbonSplitButtonProps['onExecute']>,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    overrides: Partial<RibbonSplitButtonProps> = {},
    isHero?: boolean
): RibbonSplitButtonProps &
    Partial<NextRibbonSplitButtonProps> & {
        icon?: string;
    } => {
    const { onMenuButtonExecute, ...remainingOverrides } = overrides;
    const [idString, idNumber] = formatId(id);

    const isFluentV9Enabled = isFeatureEnabled('mon-ribbon-fluent-v9-ribbon');
    const usingHeroStyle = isHero && isFluentV9Enabled && isSingleLineRibbon();

    return {
        type: AppSplitButton,
        id: idString,
        label,
        icon,
        iconSet: getComputedIconSet(icon),
        iconColor: usingHeroStyle ? 'inherit' : getIconColor(idNumber, iconColor),
        onExecute: parameter => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute(parameter);
        },
        keytipProps,
        styles: getAppButtonStyles(styles),
        ...(usingHeroStyle && {
            menuButton: { className: heroButton },
            primaryActionButton: { className: heroButton },
        }),
        menuDefinition,
        shadowRenderMenu: false,
        onMenuButtonExecute: parameter => {
            logUsage('RibbonSplitButtonMenuInvoked', {
                ribbonId: idNumber,
                label,
                viewMode: getRibbonViewMode(),
            });
            onMenuButtonExecute?.(parameter);
        },
        ...remainingOverrides,
    };
};

/**
 * creates AppCompoundButtonProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param description Secondary information displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param icon Optional id of an icon to be displayed. Typically ControlIcons. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param styles Optional styles object.
 * @param customTooltip Optional hover tooltip
 * @param overrides Optional object/array of other AppCompoundButtonProps props to override.
 */
export const createAppCompoundButton = (
    id: number | string,
    label: string,
    description: string,
    onExecute: NonNullable<AppCompoundButtonProps['onExecute']>,
    icon?: string,
    iconColor?: string,
    styles?: IButtonStyles,
    customTooltip?: string,
    overrides?: Partial<AppCompoundButtonProps>
): AppCompoundButtonProps & OptionalIconSet => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppCompoundButton,
        id: idString,
        label,
        description,
        icon, // Used for buttons within a Flyout menu
        iconSet: getComputedIconSet(icon), // Used for standard buttons on the ribbon (not in a flyout menu)
        iconColor: getIconColor(idNumber, iconColor),
        onExecute: parameter => {
            logUsage('RibbonButtonInvoked', { ribbonId: idNumber, viewMode: getRibbonViewMode() });
            onExecute(parameter);
        },
        styles,
        customTooltip,
        ...overrides,
    };
};

/**
 * creates AppSearchBoxProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onChangeCallback Callback when the searchbox contents change. Empty box returns "undefined" newValue
 * @param keytipProps Optional keytip for search box
 * @param icon Optional id of an icon to be displayed. Typically ControlIcons. It is the client's responsibility to ensure icon has been registered and is available at run-time.
 * @param iconColor Optional icon foreground color override
 * @param typeProp Optional search box type override
 * @param overrides Optional object/array of other AppSearchBoxProps props to override.
 */
export const createAppSearchBox = (
    id: number | string,
    label: string,
    onChangeCallback: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => void,
    keytipProps?: IKeytipProps,
    icon?: string,
    iconColor?: string,
    typeProp?: string, // This is added to fix issue Japanese input method. WI: 219802
    /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
     * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
     *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
    overrides?: any
): AppSearchBoxProps => {
    const [idString, idNumber] = formatId(id);
    let onSearch: (newValue?: string) => void;
    if (overrides?.onSearch) {
        /* eslint-disable-next-line owa-custom-rules/no-optional-any-parameter -- (https://aka.ms/OWALintWiki)
         * DO NOT COPY-PASTE! This code should be fixed by any developer touching this code
         *	> Optional function parameters should not have type "any". This can hide undefined/null references otherwise detectable by the transpiler. */
        onSearch = (newValue?: any) => {
            logUsage('RibbonSearchboxInvoked', {
                ribbonId: idNumber,
                viewMode: getRibbonViewMode(),
            });
            overrides.onSearch(newValue);
        };
    } else {
        onSearch = () => {
            logUsage('RibbonSearchboxInvoked', {
                ribbonId: idNumber,
                viewMode: getRibbonViewMode(),
            });
        };
    }
    return {
        type: AppSearchBox,
        id: idString,
        label,
        onChange: (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
            logUsage('RibbonSearchboxOnChange', {
                ribbonId: idNumber,
                viewMode: getRibbonViewMode(),
            });
            onChangeCallback(event, newValue);
        },
        keytipProps,
        icon,
        iconColor: getIconColor(idNumber, iconColor),
        typeProp,
        ...overrides,
        onSearch, // After overrides, to ensure we use this onSearch function
    };
};

/**
 * creates AppCheckboxProps object for use with the WAC Ribbon package
 * @param id Unique id. To ensure uniqueness while keeping package size small, it is recommended that each ribbon maintain their own integer-based enum of unique entries, calling toString at runtime to generate string ids.
 * @param label Label displayed to the user
 * @param onExecute Function invoked when the button is executed
 * @param isChecked indicates if the checkbox is checked or not
 * @param keytipProps Optional keytip for the checkbox
 * @param styles Optional styles object.
 * @param overrides Optional object/array of other AppCheckboxProps props to override.
 */
export const createAppCheckBox = (
    id: number | string,
    label: string,
    onExecute: NonNullable<AppCheckboxProps['onExecute']>,
    isChecked: boolean,
    keytipProps?: IKeytipProps,
    styles?: AppCheckboxProps['styles'],
    overrides?: Partial<AppCheckboxProps>
): AppCheckboxProps => {
    const [idString, idNumber] = formatId(id);
    return {
        type: AppCheckBox,
        id: idString,
        label,
        onExecute: (parameter: AppCheckboxOnExecuteParameter) => {
            logUsage('RibbonCheckboxButtonInvoked', {
                ribbonId: idNumber,
                viewMode: getRibbonViewMode(),
                checked: isChecked,
            });
            onExecute(parameter);
        },
        checked: isChecked,
        keytipProps,
        styles,
        ...overrides,
    };
};
