import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';
import { isEasidResponse } from './isEasidResponse';
import { getEasidStatusForCloudCacheAccount } from './getEasidStatusForCloudCacheAccount';

export async function isEasidAccount(
    emailAddress: string,
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    try {
        const easidResponse = await getEasidStatusForCloudCacheAccount(emailAddress, mailboxInfo);

        return isEasidResponse(easidResponse) && !!easidResponse.isEasid;
    } catch (err) {
        logUsage('IsEasIdCallFailure', { error: err });
        return false;
    }
}
